/* @font-face {
  font-family: "Janna LT";
  src: url("../fonts/janna/JannaLT-Regular.eot");
  src: url("../fonts/janna/JannaLT-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/janna/JannaLT-Regular.woff2") format("woff2"),
    url("../fonts/janna/JannaLT-Regular.woff") format("woff"),
    url("../fonts/janna/JannaLT-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Janna LT";
  src: url("../fonts/janna/JannaLT-Bold.eot");
  src: url("../fonts/janna/JannaLT-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/janna/JannaLT-Bold.woff2") format("woff2"), url("../fonts/janna/JannaLT-Bold.woff") format("woff"),
    url("../fonts/janna/JannaLT-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
} */

:root {
  --light-bg: #eef2f6;
  --light-hover-bg: #f5f7fa;
  --silvder-bg: #e1e4e9;
  --primary: #ebcd94;
  --secondary: #E7E5E4;
  --text-primary: #ebcd94;
  --text-blue-500: #0fa4ec;
  --text-blue-600: #0e95d7;
  --default-border: #cccdd6;
  --light-border: #d7d8e0;
  --gray-base: #171a1d;
  --gray: #343a40;
  --gray-light: #51555a;
  --gray-lighter: #74787d;
  --red: #F14437;
  --font-main: var(--font-family);
  --dark-bg-base: #151d24;
  --dark-bg-light: #26303e;
  --dark-bg-lighter: #344152;
  --dark-bg-lighter-light: #455564;
  --dark-bg-lighter-light-lighter: #4d5d6d;
  --dark-light-border: #75808a;
  --dark-text-base: #a7b4bb;
  --dark-text-light: #d1d9de;
  --dark-text-lighter: #f5f7f9;
  --dark-primary: #37cddc;
  --dark-text-lighter-light: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

[dir="rtl"] .ltr {
  direction: ltr;
  text-align: right;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  /* font-size: 14px;
    font-family: var(--font-main);
    font-weight: 400;
    line-height: 1.5;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    color: var(--gray-dark);
    overflow-y: scroll;
    overflow-x: hidden; */
}

body.dark-mode {
  background: var(--dark-bg-base) !important;
  color: #fff;
}

[dir="ltr"] body {
  font-family: Roboto, "Janna LT";
  text-align: left;
}

body.light-bg {
  background: var(--light-bg);
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: Janna LT, Roboto, Arial !important;
}

p {
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 1.8;
  word-wrap: break-word;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 15px;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 15px;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 15px;
}

dfn {
  font-style: italic;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--text-primary);
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  cursor: pointer;
  transition: all 0.15s ease;
}

a:hover {
  color: #037480;
  text-decoration: underline;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 15px;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 15px;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: right;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {

  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

/* legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
} */

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  line-height: 1.8;
  color: var(--bs-heading-color);
}

/* .table-responsive>.table-bordered {
  border: 0;
} */

span.form-control {
  background: #f4f7f9;
}

.form-control,
.selectize-input {
  display: block;
  width: 100%;
  padding: 8px 12px;
  font-family: tahoma;
  min-height: 38px;
  font-size: 14px;
  line-height: 1.6;
  color: var(--gray-base);
  background-color: var(--select-input);
  background-clip: padding-box;
  border: 1px solid var(--default-border);
  border-radius: 4px;
  font-family: var(--font-family) !important;

}

.interactive-map .form-control {
  border: none !important;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border-bottom: 1px solid #3F3C39 !important;
  border-radius: 0px;
  font-family: var(--font-family) !important;
  color: #fff !important;

}

.interactive-map .form-control:focus,
.interactive-map .form-control.active,
.interactive-map.selectize-input:focus {
  color: #fff !important;
  ;
  background-color: var(--bs-body-bg);
  border-bottom: 1px solid #ebcd94 !important;
  outline: 0;
  box-shadow: unset;

}

.form-control.default-font {
  font-family: var(--font-main);
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus,
.form-control.active,
.selectize-input:focus {
  color: var(--gray-base);
  background-color: var(--select-input);

  border-color: #ebcd94;
  outline: 0;
  box-shadow: unset;
}

.form-control::-webkit-input-placeholder {
  color: #a2abb3;
  opacity: 1;
  font-family: var(--font-family) !important;

}

.form-control::-moz-placeholder {
  color: #a2abb3;
  opacity: 1;
  font-family: var(--font-family) !important;

}

.form-control:-ms-input-placeholder {
  color: #a2abb3;
  opacity: 1;
  font-family: var(--font-family) !important;

}

.form-control::-ms-input-placeholder {
  color: #a2abb3;
  opacity: 1;
  font-family: var(--font-family) !important;

}

.form-control::placeholder {
  color: #a2abb3;
  opacity: 1;
  font-family: var(--font-family) !important;

}

.form-control.dropdown-toggle {
  cursor: pointer;
  font-family: var(--font-family) !important;

}

.dropdown.open>.form-control.dropdown-toggle {
  border-color: var(--primary);
  font-family: var(--font-family) !important;

}

.form-xs-width {
  max-width: 150px;
}

.form-control:disabled,
.form-control[readonly] {
  color: var(--text-typography) !important;
  /* background: var(--bs-body-secondry) !important; */
  opacity: 1;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
} */

select.form-control:focus::-ms-value {
  color: var(--text-typography) !important;
  background-color: var(--bs-body-secondry) !important;

}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.has-error .form-control,
.invalid-form .ng-invalid,
.invalid-form .ui-select-container.ng-invalid .ui-select-match>.btn,
.invalid-form .ui-select-container.ng-invalid .ui-select-search,
.invalid-form .ui-select-container.ng-invalid .selectize-dropdown,
.invalid-form .summernote.ng-invalid+.note-editor .note-editable,
.invalid-form .ui-checkbox .ng-invalid+label::before {
  border-color: #ce4a29 !important;
}

.col-form-label {
  padding-bottom: 7px;
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: normal;
}

.col-form-label-lg {
  padding-top: calc(10px + 1px);
  padding-bottom: calc(10px + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(5px + 1px);
  padding-bottom: calc(5px + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}


.form-group {
  margin-bottom: 15px;
}

.form-text {
  display: block;
  margin-top: 10px;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: flex;
  padding-right: 20px;
}


.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}


.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.15px;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
  display: block;
}




.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid~.custom-file-label::before,
.custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

/* .invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 13px;
    color: #dc3545;
} */

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.15px;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.invalid-form .ng-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #c51e2e;
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
  display: block;
}



.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid~.custom-file-label::before,
.custom-file-input.is-invalid~.custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.input-icon {
  position: relative;
}

.input-icon>i {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  font-size: 17px;
  color: var(--gray-light);
  right: 15px;
}

.input-icon input,
.input-icon .dropdown-toggle {
  padding-right: 45px;
}

/* Form Lined */
.form-group-lined .form-control {
  border: none;
  border-bottom: 2px solid var(--light-border);
  border-radius: 0px;
  padding-left: 0;
  transition: border 0.2s ease;
}

.form-group-lined.form-group-lg .form-control {
  padding-top: 15px;
  font-size: 15px;
  padding-bottom: 15px;
  font-family: var(--font-main);
}

[dir="ltr"] .form-group-lined.form-group-lg .form-control {
  padding-left: 35px;
  padding-right: 12px !important;
}

.form-group-lined .input-icon>i {
  right: 2px;
}

[dir="ltr"] .form-group-lined .input-icon>i {
  left: 2px;
  right: auto;
}

.form-group-lined.form-group-lg .input-icon>i {
  font-size: 20px;
  margin-top: -12px;
}

.form-group-lined .input-icon input,
.form-group-lined input-icon .dropdown-toggle {
  padding-right: 28px;
}

.form-group-lined.form-group-lg .input-icon input,
.form-group-lg input-icon .dropdown-toggle {
  padding-right: 35px;
}

.form-group-lg .input-icon select {
  padding-right: 30px;
}

[dir="ltr"] .form-group-lg .input-icon select {
  padding-left: 30px !important;
}

/* Form group light */
.form-group-light .form-control {
  border: none;
  transition: all 0.15s ease;
}

.form-group-light .form-control:hover,
.form-group-light .form-control:focus {
  background-color: var(--light-bg);
}

.form-group-lined .iti__selected-flag {
  padding: 0;
  background: none !important;
  outline: none !important;
}

.form-group-lined .iti--allow-dropdown input,
.form-group-lined .iti--allow-dropdown input[type="text"],
.form-group-lined .iti--allow-dropdown input[type="tel"],
.form-group-lined .iti--separate-dial-code input,
.form-group-lined .iti--separate-dial-code input[type="text"],
.form-group-lined .iti--separate-dial-code input[type="tel"] {
  padding-right: 40px;
}

[dir="ltr"] .form-group-lined .iti--allow-dropdown input,
[dir="ltr"] .form-group-lined .iti--allow-dropdown input[type="text"],
[dir="ltr"] .form-group-lined .iti--allow-dropdown input[type="tel"],
[dir="ltr"] .form-group-lined .iti--separate-dial-code input,
[dir="ltr"] .form-group-lined .iti--separate-dial-code input[type="text"],
[dir="ltr"] .form-group-lined .iti--separate-dial-code input[type="tel"] {
  padding-left: 40px;
  text-align: left;
}

/* End Form Lined */
.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  transition: all 0.1s ease, text-indent 1ms;
  font-weight: bold;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--dark);
  border: 1px solid transparent;
  padding: 6px 30px;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-rounded {
  border-radius: 25px;
}

.btn:hover,
.btn:focus {
  text-decoration: none !important;
}

.btn:focus,
.btn.focus {
  outline: 0;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled):not([disabled]) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-icon {
  width: 40px;
  height: 40px;
  padding: 0;
  text-align: center;
  border-radius: 50%;
}

.btn-icon.btn-loading {
  opacity: 1;
  overflow: initial;
}

.btn-icon.btn-lg {
  width: 50px;
  height: 50px;
  line-height: 60px;
}

.btn-icon.btn-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.btn-icon .circle-loader {
  display: inline-block;
  border-width: 2px;
}

.btn-icon.btn-sm .circle-loader {
  width: 30px;
  height: 30px;
  margin-top: -1px;
  top: 0;
}

.btn-icon.btn-lg .circle-loader {
  width: 50px;
  height: 50px;
  border-width: 3px;
}

.btn-icon.btn-loading i {
  display: none;
}

.btn-white {
  background: #fff;
  border-color: var(--default-border);
}

.btn-white:not(:disabled):not(.disabled):not([disabled]):hover {
  border-color: var(--primary);
}

.btn-white:not(:disabled):not(.disabled):not([disabled]):active {
  box-shadow: 0 0 0 1px var(--primary);
  border-color: var(--primary);
}


.btn-loading {
  position: relative;
}

.btn-loading:not(.btn-icon) .circle-loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 15px;
  border: 3px solid #fff;
  border-top-color: var(--primary);
  margin-top: -10px;
}

.btn:not(.btn-loading) .circle-loader {
  display: none;
}

.btn-loading:not(.btn-icon) {
  padding-left: 50px !important;
}

.btn-loading.btn-lg:not(.btn-icon) {
  padding-left: 60px !important;
}

.btn-outline-primary {
  color: var(--primary);
  background-color: transparent;
  background-image: none;
  border-color: var(--primary);
  border-width: 2px;
}

.btn-outline-primary:hover {
  color: var(--text-primary);
  border-color: var(--text-primary);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--primary);
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  border-color: var(--text-primary);
  color: var(--text-primary);
  background: #f1fafb;
}

.btn-outline-secondary {
  color: var(--secondary);
  background-color: transparent;
  background-image: none;
  border-color: var(--secondary);
}

.btn-outline-secondary:hover {
  color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-light {
  color: #9fa6af;
  background-color: transparent;
  background-image: none;
  border-color: var(--light-border);
}

.btn-outline-light:hover,
.btn-outline-light:active {
  border-color: #b6bec5;
  color: var(--gray-light);
}

.dropdown.open .btn-outline-light,
.btn-outline-light:active {
  background: var(--light-bg);
  color: var(--gray-light);
  border-color: #b6bec5;
}

.btn-link {
  font-weight: 400;
  color: var(--text-primary);
  background-color: transparent;
}

.btn-link:hover {
  color: #004e21;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg:not(.btn-icon),
.btn-group-lg>.btn {
  padding: 8px 45px;
  font-size: 18px;
  border-radius: 5px;
}

.btn-sm:not(.btn-icon),
.btn-group-sm>.btn {
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

/* .fade {
    opacity: 0;
    transition: opacity 0.15s linear;
} */
/*
.fade.show,
.fade.in {
    opacity: 1
} */


.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
  z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:first-child {
  margin-left: 0;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 32px;
  cursor: pointer;
  line-height: 27px;
}

.custom-control label {
  display: block;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 2rem;
  margin-top: 15px;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  background-color: var(--primary);
}

.custom-control-input:active~.custom-control-label::before {
  color: #fff;
  background-color: var(--light-bg);
}

.custom-control-input:hover~.custom-control-label::before {
  border-color: var(--primary);
}

.custom-control-input:disabled~.custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
  cursor: pointer;
  padding-right: 28px;
  font-size: 15px;
}

[dir="ltr"] .custom-control-label {
  padding-left: 28px;
  padding-right: auto;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  right: 0;
  display: block;
  width: 18px;
  height: 18px;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: 1px solid var(--default-border);
  user-select: none;
}

[dir="ltr"] .custom-control-label::before {
  left: 0;
  right: auto;
}

.custom-control-label::after {
  position: absolute;
  top: 1px;
  right: -2px;
  display: block;
  width: 22px;
  height: 24px;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

[dir="ltr"] .custom-control-label::after {
  left: -2px;
  right: auto;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: var(--primary);
  border-color: var(--primary);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus~.custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus~.custom-file-control::before {
  border-color: #80bdff;
}



.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 2px) - 1px * 2);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 15px;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid var(--light-border);
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef var(--light-border);
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: var(--light-border) var(--light-border) #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}



button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: left;
  font-size: 19px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  opacity: 0.5;
  filter: alpha(opacity=20);
}

.close:hover,
.close:focus {
  color: #000000;
  text-decoration: none;
  background: var(--light-bg);
  cursor: pointer;
  opacity: 0.8;
  filter: alpha(opacity=50);
}

.close:active {
  background: #dae2ea;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}


.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.flex-1 {
  flex: 1;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 5px !important;
}

.mt-1,
.my-1 {
  margin-top: 5px !important;
}

.mr-1,
.mx-1 {
  margin-right: 5px !important;
}

.mb-1,
.my-1 {
  margin-bottom: 5px !important;
}

.ml-1,
.mx-1 {
  margin-left: 5px !important;
}

.m-2 {
  margin: 10px !important;
}

.mt-2,
.my-2 {
  margin-top: 10px !important;
}

.mr-2,
.mx-2 {
  margin-right: 10px !important;
}

.mb-2,
.my-2 {
  margin-bottom: 10px !important;
}

.ml-2,
.mx-2 {
  margin-left: 10px !important;
}

.m-3 {
  margin: 15px !important;
}

.mt-3,
.my-3 {
  margin-top: 15px !important;
}

.mr-3,
.mx-3 {
  margin-right: 15px !important;
}

.mb-3,
.my-3 {
  margin-bottom: 15px !important;
}

.ml-3,
.mx-3 {
  margin-left: 15px !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 20px !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 20px !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 25px !important;
}

.mt-5,
.my-5 {
  margin-top: 25px !important;
}

.mr-5,
.mx-5 {
  margin-right: 25px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 25px !important;
}

.ml-5,
.mx-5 {
  margin-left: 25px !important;
}

.m-6 {
  margin: 30px !important;
}

.mt-6,
.my-6 {
  margin-top: 30px !important;
}

.mr-6,
.mx-6 {
  margin-right: 30px !important;
}

.mb-6,
.my-6 {
  margin-bottom: 35px !important;
}

.ml-6,
.mx-6 {
  margin-left: 30px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.pt-1,
.py-1 {
  padding-top: 5px !important;
}

.pr-1,
.px-1 {
  padding-right: 5px !important;
}

.pb-1,
.py-1 {
  padding-bottom: 5px !important;
}

.pl-1,
.px-1 {
  padding-left: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.pt-2,
.py-2 {
  padding-top: 10px !important;
}

.pr-2,
.px-2 {
  padding-right: 10px !important;
}

.pb-2,
.py-2 {
  padding-bottom: 10px !important;
}

.pl-2,
.px-2 {
  padding-left: 10px !important;
}

.p-3 {
  padding: 15px !important;
}

.pt-3,
.py-3 {
  padding-top: 15px !important;
}

.pr-3,
.px-3 {
  padding-right: 15px !important;
}

.pb-3,
.py-3 {
  padding-bottom: 15px !important;
}

.pl-3,
.px-3 {
  padding-left: 15px !important;
}

.p-4 {
  padding: 20px !important;
}

.pt-4,
.py-4 {
  padding-top: 20px !important;
}

.pr-4,
.px-4 {
  padding-right: 20px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 20px !important;
}

.pl-4,
.px-4 {
  padding-left: 20px !important;
}

.p-5 {
  padding: 25px !important;
}

.pt-5,
.py-5 {
  padding-top: 25px !important;
}

.pr-5,
.px-5 {
  padding-right: 25px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 25px !important;
}

.pl-5,
.px-5 {
  padding-left: 25px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 5px !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 5px !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 5px !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 5px !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 5px !important;
  }

  .m-sm-2 {
    margin: 10px !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 10px !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 10px !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 10px !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 10px !important;
  }

  .m-sm-3 {
    margin: 15px !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 15px !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 15px !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 15px !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 15px !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 25px !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 25px !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 25px !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 25px !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 25px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 5px !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 5px !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 5px !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 5px !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 5px !important;
  }

  .p-sm-2 {
    padding: 10px !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 10px !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 10px !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 10px !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 10px !important;
  }

  .p-sm-3 {
    padding: 15px !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 15px !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 15px !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 15px !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 15px !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 25px !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 25px !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 25px !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 25px !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 25px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 5px !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 5px !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 5px !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 5px !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 5px !important;
  }

  .m-md-2 {
    margin: 10px !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 10px !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 10px !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 10px !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 10px !important;
  }

  .m-md-3 {
    margin: 15px !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 15px !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 15px !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 15px !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 15px !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 25px !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 25px !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 25px !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 25px !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 25px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 5px !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 5px !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 5px !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 5px !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 5px !important;
  }

  .p-md-2 {
    padding: 10px !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 10px !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 10px !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 10px !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 10px !important;
  }

  .p-md-3 {
    padding: 15px !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 15px !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 15px !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 15px !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 15px !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 20px !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 25px !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 25px !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 25px !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 25px !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 25px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 5px !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 5px !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 5px !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 5px !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 5px !important;
  }

  .m-lg-2 {
    margin: 10px !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 10px !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 10px !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 10px !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 10px !important;
  }

  .m-lg-3 {
    margin: 15px !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 15px !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 15px !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 15px !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 15px !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 25px !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 25px !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 25px !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 25px !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 25px !important;
  }

  .m-lg-6 {
    margin: 35px !important;
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 35px !important;
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 35px !important;
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 35px !important;
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 35px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 5px !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 5px !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 5px !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 5px !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 5px !important;
  }

  .p-lg-2 {
    padding: 10px !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 10px !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 10px !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 10px !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 10px !important;
  }

  .p-lg-3 {
    padding: 15px !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 15px !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 15px !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 15px !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 15px !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 25px !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 25px !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 25px !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 25px !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 25px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 5px !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 5px !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 5px !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 5px !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 5px !important;
  }

  .m-xl-2 {
    margin: 10px !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 10px !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 10px !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 10px !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 10px !important;
  }

  .m-xl-3 {
    margin: 15px !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 15px !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 15px !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 15px !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 15px !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 25px !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 25px !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 25px !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 25px !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 25px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 5px !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 5px !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 5px !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 5px !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 5px !important;
  }

  .p-xl-2 {
    padding: 10px !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 10px !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 10px !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 10px !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 10px !important;
  }

  .p-xl-3 {
    padding: 15px !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 15px !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 15px !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 15px !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 15px !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 25px !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 25px !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 25px !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 25px !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 25px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-initial {
    text-align: initial !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-initial {
    text-align: initial !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-lg-initial {
    text-align: initial !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .text-xl-initial {
    text-align: initial !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: var(--primary) !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #d2740f !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #03a262 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #d39e00;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: var(--gray-light) !important;
}

.dark-mode .text-light {
  color: var(--dark-text-lighter-light) !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}

.text-muted {
  color: var(--light-border) !important;
}

[data-bs-theme="dark"] .text-muted {
  color: var(--dark-text-base) !important;
}

a.text-muted:hover {
  color: var(--gray-light);
}

.primary-bg {
  background-color: var(--primary);
}

.secondary-bg {
  background: #27d28b !important;
}

.yellow-bg {
  background: #eabe38 !important;
}

.dark-bg {
  background: #607d8b !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-seperator {
  position: relative;
}

.text-seperator span {
  background: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.text-seperator:after {
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -0.5px;
  background-color: var(--light-border);
  right: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {

  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  /* .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  } */
}

/* Start Progress Circle */
.progress-circle {
  background-color: #e2eaef;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  min-width: 65px;
  min-height: 65px;
}

.progress-circle.clickable {
  cursor: pointer;
}

.progress-circle:before {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  content: attr(data-string) "%";
  display: flex;
  font-size: 14px;
  justify-content: center;
  font-weight: bold;
  z-index: 1;
  position: absolute;
  transition: all 0.3s ease;
  left: 6px;
  right: 6px;
  color: var(--gray-base);
  top: 6px;
  bottom: 6px;
}

.progress-circle[data-string="0"]:before {
  color: #b9bbbd;
}

.progress-circle.active:before {
  /* color: var(--primary); */
}

.progress-circle:after {
  background-color: var(--primary);
  border-radius: 50%;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}

.progress-circle[data-progress="0"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(90deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="1"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(93.6deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="2"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(97.2deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="3"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(100.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="4"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(104.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="5"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(108deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="6"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(111.6deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="7"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(115.2deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="8"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(118.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="9"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(122.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="10"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(126deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="11"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(129.6deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="12"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(133.2deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="13"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(136.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="14"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(140.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="15"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(144deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="16"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(147.6deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="17"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(151.2deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="18"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(154.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="19"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(158.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="20"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(162deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="21"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(165.60000000000002deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="22"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(169.2deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="23"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(172.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="24"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(176.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="25"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(180deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="26"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(183.60000000000002deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="27"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(187.2deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="28"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(190.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="29"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(194.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="30"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(198deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="31"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(201.60000000000002deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="32"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(205.2deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="33"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(208.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="34"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(212.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="35"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(216deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="36"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(219.6deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="37"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(223.20000000000002deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="38"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(226.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="39"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(230.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="40"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(234deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="41"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(237.6deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="42"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(241.20000000000002deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="43"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(244.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="44"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(248.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="45"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(252deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="46"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(255.6deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="47"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(259.20000000000005deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="48"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(262.8deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="49"]:after {
  background-image: linear-gradient(90deg, #e2eaef 50%, transparent 50%, transparent),
    linear-gradient(266.4deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="50"]:after {
  background-image: linear-gradient(-90deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="51"]:after {
  background-image: linear-gradient(-86.4deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="52"]:after {
  background-image: linear-gradient(-82.8deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="53"]:after {
  background-image: linear-gradient(-79.2deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="54"]:after {
  background-image: linear-gradient(-75.6deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="55"]:after {
  background-image: linear-gradient(-72deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="56"]:after {
  background-image: linear-gradient(-68.4deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="57"]:after {
  background-image: linear-gradient(-64.8deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="58"]:after {
  background-image: linear-gradient(-61.2deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="59"]:after {
  background-image: linear-gradient(-57.6deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="60"]:after {
  background-image: linear-gradient(-54deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="61"]:after {
  background-image: linear-gradient(-50.4deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="62"]:after {
  background-image: linear-gradient(-46.8deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="63"]:after {
  background-image: linear-gradient(-43.199999999999996deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="64"]:after {
  background-image: linear-gradient(-39.6deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="65"]:after {
  background-image: linear-gradient(-36deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="66"]:after {
  background-image: linear-gradient(-32.4deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="67"]:after {
  background-image: linear-gradient(-28.799999999999997deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="68"]:after {
  background-image: linear-gradient(-25.200000000000003deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="69"]:after {
  background-image: linear-gradient(-21.599999999999994deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="70"]:after {
  background-image: linear-gradient(-18deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="71"]:after {
  background-image: linear-gradient(-14.399999999999991deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="72"]:after {
  background-image: linear-gradient(-10.799999999999997deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="73"]:after {
  background-image: linear-gradient(-7.200000000000003deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="74"]:after {
  background-image: linear-gradient(-3.599999999999994deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="75"]:after {
  background-image: linear-gradient(0deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="76"]:after {
  background-image: linear-gradient(3.600000000000009deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="77"]:after {
  background-image: linear-gradient(7.200000000000003deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="78"]:after {
  background-image: linear-gradient(10.799999999999997deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="79"]:after {
  background-image: linear-gradient(14.400000000000006deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="80"]:after {
  background-image: linear-gradient(18deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="81"]:after {
  background-image: linear-gradient(21.60000000000001deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="82"]:after {
  background-image: linear-gradient(25.200000000000003deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="83"]:after {
  background-image: linear-gradient(28.799999999999997deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="84"]:after {
  background-image: linear-gradient(32.400000000000006deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="85"]:after {
  background-image: linear-gradient(36deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="86"]:after {
  background-image: linear-gradient(39.599999999999994deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="87"]:after {
  background-image: linear-gradient(43.20000000000002deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="88"]:after {
  background-image: linear-gradient(46.80000000000001deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="89"]:after {
  background-image: linear-gradient(50.400000000000006deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="90"]:after {
  background-image: linear-gradient(54deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="91"]:after {
  background-image: linear-gradient(57.599999999999994deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="92"]:after {
  background-image: linear-gradient(61.20000000000002deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="93"]:after {
  background-image: linear-gradient(64.80000000000001deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="94"]:after {
  background-image: linear-gradient(68.4deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="95"]:after {
  background-image: linear-gradient(72deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="96"]:after {
  background-image: linear-gradient(75.6deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="97"]:after {
  background-image: linear-gradient(79.20000000000002deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="98"]:after {
  background-image: linear-gradient(82.80000000000001deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="99"]:after {
  background-image: linear-gradient(86.4deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, #e2eaef 50%, #e2eaef);
}

.progress-circle[data-progress="100"]:after {
  background-image: linear-gradient(90deg, var(--primary) 50%, transparent 50%, transparent),
    linear-gradient(270deg, var(--primary) 50%, var(--primary) 50%, var(--primary));
}

/* End Progress Circle */
/* Progress Vertical */
.progress-bar-vertical {
  width: 15px;
  min-height: 68px;
  display: flex;
  align-items: flex-end;
  float: left;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
  border-radius: 15px;
}

/* End Progress Vertical */
