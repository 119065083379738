.page-layout {
  height: 90vh;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 20px;
  overflow: hidden;
}

.container-fluid {
  max-width: 1500px;
  height: 100vh;
}

.invalid-feedback-custom {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 13px;
  color: #dc3545;
}

input {
  border-radius: 8px !important;
}

.modal-title {
  font-weight: bold !important;
  font-family: var(--font-family) !important;
  margin: 0 !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 32px !important;
  letter-spacing: -0.24px !important;
}

.modal-body .form-control:not(:disabled) {
  color: var(--text-typography) !important;
  background-color: var(--select-input) !important;
  border-radius: 8px !important;
}
.modal-body .ng-select .ng-select-container {
  background-color: var(--select-input) !important;
  border-radius: 8px !important;
}
.modal-body .ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  background-color: var(--select-input) !important;
}
.modal-body .form-control {
  border: none !important;
}
.modal-body .ng-select .ng-select-container {
  border: none !important;
  background-color: var(--select-input) !important;
  border-radius: 8px !important;
}
.modal-body .btn {
  font-size: 14px !important;
}

::placeholder {
  color: var(--text-typography) !important;
  opacity: 1;
}

::-ms-input-placeholder {
  color: var(--text-typography) !important;
}

.mat-checkbox-layout {
  display: flex !important;
  gap: 10px !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ebcd94 !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ebcd94 !important;
}

::ng-deep .mat-ink-bar {
  background-color: #ebcd94 !important;
}

/* Styles for the active tab label */
/* Styles for the ink bar */
.mat-ink-bar {
  background-color: #ebcd94 !important;
}

.ng-select.custom-class .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  display: none !important;
}

@media print {
  .topbar-layout {
    display: none;
  }

  body {
    overflow: hidden !important;
  }
}
.text-with-line h2 {
  width: 100% !important;
  text-align: center !important;
  border-bottom: 1px solid #000 !important;
  line-height: 0.1em !important;
  margin: 10px 0 20px !important;
}
.text-with-line h2 span {
  background: #fff !important;
  padding: 0 10px !important;
}

.btn:hover,
.btn:focus {
  text-decoration: none !important;
  background-color: none;
}

.btn-custom {
  width: 120px;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 100px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.07px;
  cursor: pointer;
  border: 1px solid var(--gray-warm-300, #D6D3D1);
  color: var(--text-typography) !important;
}

button {
  border-radius: 100px;
  box-shadow: none !important;
  text-decoration: none !important;
}

.btn {
  box-shadow: none !important;
  outline: 0 !important;
  color: var(--text-typography) !important;
  border-radius: 100px !important;
}

.btn-primary,
.btn-info {
  color: var(--text-typography) !important;
  background-color: #ebcd94 !important;
  border-color: #ebcd94 !important;
}

.btn-primary:hover,
.btn-info:hover,
.a-access:hover {
  color: var(--text-typography) !important;
  background-color: #d6bb87 !important;
  border-color: #d6bb87 !important;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-info.disabled,
.btn-info:disabled {
  opacity: 0.6 !important;
  background-color: #ebcd94 !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.btn-primary.btn-loading,
.btn-info.btn-loading,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #d6bb87 !important;
  border-color: #d6bb87 !important;
}

.btn-secondary {
  border-radius: 100px;
  border: 1px solid #D6BB87;
  background: #FDFAF4;
  padding: 8px 16px;
  color: #63563E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 150% */
  letter-spacing: -0.16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}

.btn-secondary:hover {
  color: #63563E;
  border: 1px solid #A79269;
  background: #F9F0DE;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  border: 1px solid #EFD7A9;
  background: #FDFAF4;
  color: #EFD7A9;
}

.btn-success {
  color: var(--text-typography) !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-success:hover {
  color: var(--text-typography) !important;
  background-color: #218838 !important;
  border-color: #1e7e34 !important;
}

.btn-success.disabled,
.btn-success:disabled {
  color: var(--text-typography) !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #1e7e34 !important;
  border-color: #1c7430 !important;
}

.btn-sub {
  color: var(--text-typography) !important;
  background-color: #F5F5F4 !important;
  border-color: #F5F5F4 !important;
}

.btn-sub:hover {
  color: var(--text-typography) !important;
  background-color: #f1f1f1 !important;
  border-color: #f1f1f1 !important;
}

.btn-sub.disabled,
.btn-sub:disabled {
  color: var(--text-typography) !important;
  background-color: #bfbfbf !important;
  border-color: #bfbfbf !important;
}

.btn-sub:not(:disabled):not(.disabled):active,
.btn-sub:not(:disabled):not(.disabled).active,
.show > .btn-sub.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #F5F5F4 !important;
  border-color: #F5F5F4 !important;
}

.btn-warning {
  color: var(--text-typography) !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.btn-warning:hover {
  color: var(--text-typography) !important;
  background-color: #e0a800 !important;
  border-color: #d39e00 !important;
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: var(--text-typography) !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #d39e00 !important;
  border-color: #c69500 !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-danger:hover {
  color: var(--text-typography) !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: var(--text-typography) !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #bd2130 !important;
  border-color: #b21f2d !important;
}

.btn-light-dark {
  background: var(--light-bg) !important;
}

.btn-light {
  background-color: transparent !important;
}

.btn-light,
.btn-light-dark {
  color: var(--gray) !important;
  border-color: transparent !important;
}

.btn-light:not(:disabled):hover,
.btn-light-dark:not(:disabled):hover,
.btn-light:not(.disabled):hover,
.btn-light-dark:not(.disabled):hover {
  color: var(--text-typography) !important;
  background-color: #e5ecf3 !important;
  border-color: #e5ecf3 !important;
}

.btn-light:active,
.btn-light.active,
.btn-light-dark:active,
.btn-light-dark.active,
.dropdown.open .btn-light-dark {
  color: var(--text-typography) !important;
  background-color: #dae2ea !important;
  border-color: #dae2ea !important;
}

.btn-light.disabled,
.btn-light:disabled {
  color: var(--text-typography) !important;
  background-color: #d5dbde !important;
  border-color: #d5dbde !important;
}

.btn-custom-project {
  width: 148px;
  height: 40px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  text-align: center;
  line-height: 24px;
  letter-spacing: -0.07px;
  font-size: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 860px) {
  .btn-custom-project {
    width: 125px;
    padding: 6px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 600px) {
  .btn-custom-project {
    width: 90px;
    font-size: 10px;
    padding: 0px 6px;
  }
}
.btn-link {
  color: #D6BB87;
}

.btn-link:hover {
  color: #D6BB87;
  text-decoration: underline;
}

.btn .mat-icon {
  font-size: 20px;
  height: 20px;
  width: 20px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  overflow: visible !important;
}

.ng-select .ng-select-container {
  color: var(--text-typography) !important;
  background-color: var(--bs-body-bg) !important;
}

.ng-select .ng-select-opened .ng-select-bottom {
  background: var(--bs-body-bg) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background: var(--bs-body-bg) !important;
  color: var(--bs-heading-color) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background-color: var(--primary) !important;
  color: var(--bs-heading-color) !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--text-typography) !important;
  margin-right: 10px !important;
}

.text-info {
  color: #B49164 !important;
}

a {
  font-family: var(--font-family) !important;
  text-decoration: none !important;
}

label {
  font-family: var(--font-family) !important;
  color: var(--text-typography);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

p {
  text-align: right;
  font-family: var(--font-family) !important;
  /* Paragraph/p1/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  /* 150% */
  margin: 0 !important;
  color: var(--text-typography);
}

h4 {
  font-family: var(--font-family) !important;
  /* Heading/h4/Medium */
  font-size: 20px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 28px;
  /* 140% */
  margin: 0 !important;
  color: var(--text-typography);
}

h3 {
  font-family: var(--font-family) !important;
  text-align: right;
  /* Heading/h3/Medium */
  font-size: 15px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  /* 133.333% */
  margin: 0 !important;
  color: var(--text-typography);
}

h2 {
  /* Heading/h2/Medium */
  font-family: var(--font-family) !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 27px;
  /* 126.667% */
  margin: 0 !important;
  color: var(--text-typography);
}

h1 {
  font-family: var(--font-family) !important;
  text-align: right;
  /* Heading/h1/Medium */
  font-size: 36px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 46px;
  /* 127.778% */
  margin: 0 !important;
  color: var(--text-typography);
}

h5 {
  text-align: right;
  /* Heading/h1/Medium */
  font-size: 18px;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 26px;
  font-family: var(--font-family) !important;
  margin: 0 !important;
  color: var(--text-typography);
}

span {
  color: var(--text-typography);
  font-family: var(--font-family) !important;
}

@media only screen and (max-width: 1250px) {
  p {
    font-size: 13px !important;
  }

  h2 {
    font-size: 24px;
  }
}
.swal2-popup {
  width: 520px !important;
}

.swal2-icon {
  font-size: 10px !important;
}

.swal2-html-container {
  font-size: 14px !important;
  margin: 0 !important;
  padding: 0 40px !important;
}

.swal2-popup,
.swal2-title,
.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel,
.swal2-styled.swal2-deny {
  font-family: var(--font-family) !important;
  color: #1C1917 !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel,
.swal2-styled.swal2-deny {
  border-radius: 100px !important;
  height: 48px !important;
}

.swal2-styled.swal2-cancel,
.swal2-styled.swal2-deny {
  border-radius: 100px !important;
  border: 1px solid var(--Button-Tertiary-Default-Tertiary_Default_Stroke, #E7E5E4) !important;
}

.swal2-styled.swal2-deny {
  color: white !important;
}

.list-assign-offices-to-camp-alert button {
  width: 110px !important;
}

.w-70-alert button {
  width: 70px !important;
}

.w-140-alert button {
  width: 140px !important;
}

.w-170-alert button {
  width: 170px !important;
  height: auto !important;
}

.w-80-alert button {
  width: 80px !important;
}

.swal2-textarea {
  box-shadow: none !important;
}

.otp-input {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  border: solid 1px #c5c5c5;
  text-align: center;
  font-size: 32px;
}

.ng-otp-input-wrapper .otp-input:last-child {
  margin-right: 8px !important;
}

@media screen and (max-width: 767px) {
  .otp-input {
    width: 40px;
    font-size: 24px;
    height: 40px;
  }
}
@media screen and (max-width: 420px) {
  .otp-input {
    width: 30px;
    font-size: 18px;
    height: 30px;
  }
}
::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #ebcd94 !important;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #ebcd94 !important;
  /*inner circle color change*/
}

::ng-deep.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ebcd94 !important;
  /*outer ring color change*/
}

::ng-deep.mat-radio-label-content {
  margin-right: 8px !important;
  font-family: "Janna LT" !important;
}

::ng-deep.mat-checkbox-label {
  font-family: "Janna LT" !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #ebcd94 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #ebcd94 !important;
  /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ebcd94 !important;
  /*outer ring color change*/
}

.mat-radio-label-content {
  margin-right: 8px !important;
  font-family: "Janna LT" !important;
}

table .mat-sort-header-arrow[style] {
  transform: none !important;
  opacity: 1 !important;
}
table .mat-sort-header-arrow[style] .mat-sort-header-stem {
  display: none !important;
}
table .mat-sort-header-arrow[style] .mat-sort-header-indicator {
  opacity: 1 !important;
  color: var(--text-typography) !important;
  font-weight: bold !important;
  font-size: 10px !important;
  transform: none !important;
}
table .mat-sort-header-arrow[style] .mat-sort-header-indicator .mat-sort-header-pointer-left,
table .mat-sort-header-arrow[style] .mat-sort-header-indicator .mat-sort-header-pointer-right,
table .mat-sort-header-arrow[style] .mat-sort-header-indicator .mat-sort-header-pointer-middle {
  display: none !important;
}
table .mat-sort-header-arrow[style] .mat-sort-header-indicator::before {
  transform: none !important;
  font: normal normal normal 0.8rem/1 FontAwesome !important;
  content: "\f0dc" !important;
  position: absolute !important;
  top: 0px !important;
}
table [aria-sort=ascending] .mat-sort-header-arrow .mat-sort-header-indicator {
  transform: none !important;
}
table [aria-sort=ascending] .mat-sort-header-arrow .mat-sort-header-indicator::before {
  font: normal normal normal 0.8rem/1 FontAwesome !important;
  content: "\f0d7" !important;
  position: absolute !important;
  top: 0 !important;
}
table [aria-sort=descending] .mat-sort-header-arrow .sort-icon {
  display: none !important;
}
table [aria-sort=descending] .mat-sort-header-arrow .mat-sort-header-indicator::before {
  font: normal normal normal 0.8rem/1 FontAwesome !important;
  content: "\f0d8" !important;
  position: absolute !important;
  top: 0 !important;
}

.mat-paginator {
  background: var(--bs-body-secondry) !important;
  color: var(--text-typography) !important;
  position: sticky;
  bottom: 0px;
  right: 0;
  left: 0;
  font-weight: bold !important;
  margin-top: 5px !important;
}

.content-nav {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-16-px, 16px);
}
.content-nav .navbar-menu {
  position: relative;
  padding: 0 calc(1.5rem * 0.5);
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  bottom: auto;
}
@media (max-width: 575.98px) {
  .content-nav .navbar-menu .container-fluid {
    padding: 0;
  }
}
.content-nav .navbar-menu .navbar-nav {
  flex-direction: row;
}
.content-nav .navbar-menu .navbar-nav .nav-sm {
  padding-left: 0;
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-link:before {
  opacity: 0 !important;
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-link:hover, .content-nav .navbar-menu .navbar-nav .nav-sm .nav-link.active {
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-link {
  color: #fff;
  padding: 0.75rem 1.5rem;
}
.content-nav .navbar-menu .navbar-nav .nav-link i {
  line-height: 1;
}
.content-nav .navbar-menu .navbar-nav .nav-link.active {
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-link.active:after {
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-link.active .icon-dual {
  color: var(#ebcd94);
  fill: rgba(235, 205, 148, 0.1);
}
.content-nav .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true] {
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true] .icon-dual {
  color: var(#ebcd94);
  fill: rgba(235, 205, 148, 0.1);
}
.content-nav .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-link:hover .icon-dual {
  color: var(#ebcd94);
  fill: rgba(235, 205, 148, 0.1);
}
.content-nav .navbar-menu .navbar-nav > .nav-item > .nav-link[data-bs-toggle=collapse]:after {
  left: 0px;
  transform: rotate(90deg) !important;
}
.content-nav .navbar-menu .navbar-brand-box {
  display: none;
}
.content-nav .main-content {
  margin-left: 0;
}
@media (min-width: 1250px) {
  .content-nav .layout-width,
.content-nav .container-fluid {
    max-width: 90%;
    margin: 0 auto;
  }
  .content-nav .topnav-hamburger {
    visibility: hidden;
  }
}
.content-nav .horizontal-logo {
  padding-left: calc(1.5rem * 0.5);
}
@media (max-width: 1024.98px) {
  .content-nav .horizontal-logo {
    padding-left: 1.5rem;
  }
}
.content-nav .navbar-menu {
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  padding: 0 calc(1.5rem * 0.5);
  transition: all 0.1s ease-out;
}
.content-nav .navbar-menu .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  color: #d6d3d1;
  font-size: 0.9375rem;
}
.content-nav .navbar-menu .navbar-nav .nav-link.active {
  color: #d6d3d1;
}
.content-nav .navbar-menu .navbar-nav .nav-link:hover {
  color: #d6d3d1;
}
.content-nav .navbar-menu .navbar-nav .nav-link i {
  display: inline-block;
  min-width: 1.75rem;
  font-size: 18px !important;
  line-height: inherit;
}
.content-nav .navbar-menu .navbar-nav .nav-link svg {
  width: 18px;
  margin-right: 0.665rem;
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-link .badge {
  margin-left: auto;
  margin-right: -2px;
  z-index: 1;
}
.content-nav .navbar-menu .navbar-nav .nav-link:hover {
  color: #ebcd94;
}
.content-nav .navbar-menu .navbar-nav .nav-link:hover .icon-dual {
  color: #ebcd94;
  fill: rgba(235, 205, 148, 0.16);
}
.content-nav .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  display: block;
  content: "\f0142";
  font-family: "Material Design Icons";
  margin-left: auto;
  transition: transform 0.2s;
  font-size: 1.05rem;
  position: relative;
  left: 18px;
  right: 5px;
  color: inherit;
}
.content-nav .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true] {
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true] .icon-dual {
  color: var(#ebcd94);
  fill: rgba(235, 205, 148, 0.16);
}
.content-nav .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
  transform: rotate(90deg);
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:before {
  opacity: 1;
  background-color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-link.collapsed.active::after {
  transform: rotate(90deg);
}
.content-nav .navbar-menu .navbar-nav .nav-sm {
  padding-left: 0;
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-link {
  padding: 0.55rem 1.5rem !important;
  color: var(#ebcd94);
  white-space: none;
  position: relative;
  font-size: 0.875rem;
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-link:before {
  content: "";
  width: 6px;
  height: 1.5px;
  background-color: var(#696969);
  position: absolute;
  left: 2px;
  top: 16.5px;
  transition: all 0.4s ease-in-out;
  opacity: 0.5;
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-link:hover {
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-link:hover:before {
  background-color: var(#ebcd94) !important;
  opacity: 1;
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-link.active {
  color: var(#ebcd94);
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-link.active:before {
  background-color: var(#ebcd94) !important;
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-sm {
  padding-left: 15px;
}
.content-nav .navbar-menu .navbar-nav .nav-sm .nav-sm .nav-link:before {
  height: 5px;
  width: 5px;
  left: 5px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid;
  top: 16px;
}
.content-nav .navbar-menu .btn-vertical-sm-hover {
  color: var(#e7e5e4);
  display: none;
}
.content-nav .navbar-nav .nav-item {
  position: relative;
}
.content-nav .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse]:after {
  left: 10px;
  transform: rotate(0deg) !important;
}
.content-nav .navbar-nav .nav-item > .nav-link > .badge {
  display: none;
}
.content-nav .navbar-nav .nav-item:hover > .nav-link {
  color: var(#ebcd94);
}
.content-nav .navbar-nav .nav-item:hover > .nav-link .icon-dual {
  color: var(#ebcd94);
  fill: rgba(235, 205, 148, 0.1);
}
.content-nav .navbar-nav .nav-item:hover > .nav-link:after {
  color: var(#ebcd94);
}
@media (min-width: 1250px) {
  .content-nav .navbar-nav .nav-item:hover > .menu-dropdown {
    display: block;
    height: auto !important;
  }
}
.content-nav .navbar-nav .nav-item.active {
  color: var(#ebcd94);
}
.content-nav .menu-dropdown {
  position: absolute;
  min-width: 15rem;
  padding: 0.5rem 0;
  box-shadow: 0 0px 5px rgba(15, 34, 58, 0.15);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  z-index: 1000;
  background-color: #1f1f1f;
  background-clip: padding-box;
  border: 0 solid #e9ebec;
  border-radius: 0.3rem;
  display: none;
}
.content-nav .menu-dropdown .menu-dropdown {
  top: 0;
  left: 100%;
}
.content-nav .mega-dropdown-menu {
  width: 40rem;
}
.content-nav .menu-title {
  display: none;
}
.content-nav .dropdown-custom-right {
  left: -100% !important;
  right: 100%;
}
@media (max-width: 1250px) {
  .content-nav .navbar-menu {
    display: none;
  }
}
.content-nav .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse]:after {
  transform: rotate(-180deg) !important;
}

@media (max-width: 1250px) {
  .content-nav {
    display: none;
  }
}
.header-office-hajj {
  position: relative;
  z-index: 900;
  display: flex;
  width: 100%;
  height: 7vh;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  align-items: center;
  gap: var(--number-0-px, 0px);
  border-radius: var(--number-0-px, 0px);
  background: var(--background-background, #1f1f1f);
  position: relative;
  padding: 5px 0;
}
.header-office-hajj .name {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #74787d !important;
}
.header-office-hajj .header-container {
  display: flex;
  height: 100%;
  padding: var(--number-0-px, 0px) 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.header-office-hajj .header-container .logo {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-16-px, 16px);
}
.header-office-hajj .header-container .content {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-16-px, 16px);
}
.header-office-hajj .header-container .content .name {
  color: #e7e5e4 !important;
  /* Heading/h3/Medium */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  /* 133.333% */
  letter-spacing: -0.24px;
  margin: 0;
}
.header-office-hajj .header-container .content .image-content {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: 6px;
  width: 36px;
  height: 36px;
  border-radius: 200px;
  background: lightgray 50%/cover no-repeat;
}
.header-office-hajj .header-container .content .icon {
  display: none;
}

@media (max-width: 1250px) {
  .header-office-hajj .header-container .content .name {
    display: none;
  }
  .header-office-hajj .header-container .content .image-content {
    display: none;
  }
  .header-office-hajj .header-container .content .icon {
    display: initial;
    color: #e7e5e4 !important;
    /* Heading/h3/Medium */
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    /* 133.333% */
    letter-spacing: -0.24px;
    margin: 0;
  }
}
@media (min-width: 1251px) {
  app-mobile-nav {
    display: none;
  }
}
.form-check-input:checked {
  background-color: #ebcd94;
  border-color: #ebcd94;
}

.contriner-header-sidebar-information {
  width: 100%;
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
}

.my-labels1 {
  font-size: 12px !important;
  width: 40px !important;
}

.label-flag {
  font-size: 12px !important;
  width: 68px !important;
}

.camp-label {
  font-size: 12px !important;
  width: 50px !important;
  color: #002673;
  text-shadow: 0 0 3px #002673;
}

.plot-label {
  font-size: 12px !important;
  width: 50px !important;
  color: #ff0000;
  text-shadow: 0 0 3px #ff0000;
}

.plot-label-all {
  font-size: 18px !important;
  width: 90px !important;
  font-weight: bolder;
  color: #ff0000;
  text-shadow: 0 0 3px #ff0000;
}

.my-label-arafat {
  font-size: 17px !important;
  width: 40px !important;
  font-weight: bold;
}

@media only screen and (max-width: 860px) {
  .contriner-header-sidebar-information {
    flex-direction: column;
    gap: 0px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .contriner-header-sidebar-information {
    flex-direction: row;
    gap: 15px;
  }
}
:root {
  /* fonts */
  /* font sizes */
  --paragraph-p2-regular1-size: 14px;
  --paragraph-p1-regular-size: 16px;
  --paragraph-p3-regular-size: 13px;
  --heading-h2-medium1-size: 30px;
  /* Colors */
  --primary-50: #fdfaf4;
  --white: #fff;
  --gray-warm-gray-500-base: #78716c;
  --gray-warm-600: #57534e;
  --primary-600: #d6bb87;
  --primary-500-base: #ebcd94;
  --gray-warm-gray-900: #1c1917;
  --gray-warm-700: #44403c;
  --neutrals-6: #e6e8ec;
  --stroke-gray-stroke: #e7e5e4;
  /* Spacing */
  --number-8: 8px;
  /* Gaps */
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-7xs: 6px;
  /* Paddings */
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-mini: 15px;
  /* Border radiuses */
  --br-xs: 12px;
  --br-81xl: 100px;
  --br-7xs: 6px;
  --border-radus-8: 8px;
  --br-9xs: 4px;
  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-xs1: 0px 3px 4px -5px rgba(24, 24, 28, 0.03),
    0px 1px 2px rgba(24, 24, 28, 0.04);
}

.ph1 {
  width: 100%;
  height: 100vh;
  background-color: var(--bs-body-bg);
  box-shadow: 0 67px 30px rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.ph1 .header-sidebar {
  display: flex;
  align-items: flex-start;
  gap: var(--number-0-px, 0px);
  align-self: stretch;
}
.ph1 .header-sidebar .text-header {
  padding: 18px 24px;
  /* Heading/h3/Medium */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  /* 133.333% */
  letter-spacing: -0.24px;
}
.ph1 .line-hr {
  border-bottom: 1px solid #e7e5e4;
}
.ph1 .action-popups-section {
  display: flex;
  padding: 10px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0 0 8px 0;
  background: #fff;
}
.ph1 .action-popups-section .section-button {
  display: flex;
  align-items: flex-start;
}
.ph1 .action-popups-section .action-popup-section-booking {
  width: 148px;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 100px;
  text-align: center;
  font-weight: bold;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: -0.07px;
  cursor: pointer;
  background: var(--primary-500-base, #ebcd94);
  color: var(--gray-warm-gray-900, #1c1917) !important;
}
.ph1 .tab-wrap {
  transition: 0.3s box-shadow ease;
  border-radius: 6px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  list-style: none;
  background-color: #fff;
  padding: 6px 8px;
}
.ph1 .tab-wrap h1,
.ph1 .tab-wrap h2 {
  margin: 0;
  color: #444;
  text-align: center;
  font-weight: 400;
}
.ph1 .tab-wrap h2 {
  font-size: 1em;
  margin-bottom: 30px;
}
.ph1 .tab-wrap h3 {
  font-weight: 400;
}
.ph1 .tab-wrap p {
  line-height: 1.6;
  margin-bottom: 20px;
}
.ph1 .tab_button_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ph1 .tab {
  display: none;
}
.ph1 .tab:first-of-type:not(:last-of-type) + label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ph1 .tab:not(:first-of-type):not(:last-of-type) + label {
  border-radius: 0;
}
.ph1 .tab:last-of-type:not(:first-of-type) + label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ph1 .tab + label {
  box-shadow: 0 -1px 0 #eee inset;
  border-radius: 6px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #333;
  flex-grow: 3;
  text-align: center;
  user-select: none;
  text-align: center;
  transition: 0.3s background-color ease, 0.3s box-shadow ease;
  height: 50px;
  box-sizing: border-box;
  padding: 10px;
}
.ph1 .tab + label:hover {
  background-color: #ebcd94;
  box-shadow: 0 -1px 0 #f4f4f4 inset;
}
.ph1 .selected {
  background-color: #ebcd94;
  box-shadow: 0 -1px 0 #fff inset;
  cursor: default;
}
.ph1 .tab__content {
  opacity: 1;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  text-shadow: 0 0 0;
  height: 23vh;
  padding: 10px 2px;
  width: 100%;
  border-radius: 6px;
  overflow-y: auto;
}
.ph1 .details-map-popup-section {
  display: flex;
  padding: 16px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px;
  background: #fff;
  zoom: 0.8;
  padding: 14px;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--number-16-px, 14px);
  align-self: stretch;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name {
  display: flex;
  padding: 0px;
  align-items: center;
  gap: 12px;
  text-align: right;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-name {
  display: flex;
  padding: 0px;
  align-items: center;
  gap: 10px;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-number {
  color: #44403c;
  /* Heading/h5/Medium */
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .avalaible {
  background: var(--secondary-50, #e6f4ee);
  color: var(--accent-success-500-base, #02b37d);
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .unavalaible {
  background: var(--secondary-50, #fbc5c1);
  color: var(--accent-success-500-base, #f14437);
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-status {
  display: flex;
  height: 24px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  mix-blend-mode: multiply;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-status .plot-status-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.06px;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information {
  display: flex;
  padding: 0px;
  align-items: center;
  align-self: stretch;
  padding-right: 5px;
  flex-wrap: wrap;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col {
  display: flex;
  padding: 2px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .info-col-up {
  color: #78716c !important;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .info-col-down {
  color: #000;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .d2 {
  border: 1px solid #0e95d7;
  background: #e7f6fd;
  color: #0e95d7;
  cursor: pointer;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .d3 {
  border: 1px solid var(--secondary-500-base, #039154);
  background: var(--secondary-50, #e6f4ee);
  color: #039154;
  cursor: pointer;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info {
  border-radius: 8px;
  border: 1px solid #e7e5e4;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-selected {
  border-radius: 8px;
  border: 1px solid #ebcd94;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-selected-booked {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--accent-blue-50, #0e95d7);
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-unselected-unbooked {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--accent-blue-50, #f14437);
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-header-selected {
  border-radius: 0;
  border: 1px solid #ebcd94;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .mat-panel-title-camp {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-8-px, 8px);
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .mat-panel-title-camp .text {
  color: var(--text-body-text-2, #78716c);
  leading-trim: both;
  text-edge: cap;
  /* Paragraph/p2/Table */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .mat-panel-title-camp .camp {
  display: inline;
  /* Paragraph/p2/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  align-items: center;
  align-self: stretch;
  margin-top: 15px;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information .plot-camp-information-col {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  margin-left: 5%;
  margin-bottom: 2%;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information .plot-camp-information-col .info-col-up {
  color: #78716c !important;
}
.ph1 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information .plot-camp-information-col .info-col-down {
  color: #000;
}
.ph1 .details-map-popup-section .access_path_v2 {
  display: flex;
  padding: var(--number-16-px, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--number-20-px, 20px);
  align-self: stretch;
}
.ph1 .details-map-popup-section .access_path_v2 .container {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--number-4-px, 4px);
  align-self: stretch;
}
.ph1 .details-map-popup-section .access_path_v2 .container .col-access {
  display: flex;
  padding: var(--number-0-px, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.ph1 .details-map-popup-section .access_path_v2 .container .col-access .col-access-left {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-12-px, 12px);
}
.ph1 .details-map-popup-section .access_path_v2 .container .col-access .col-access-left .text {
  color: var(--text-body-text-2, #78716c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.ph1 .details-map-popup-section .access_path_v2 .container .col-access .col-access-right {
  color: var(--text-heading-text, #1c1917);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.ph1 .details-map-popup-section .access_path_v2 .container .dot {
  display: flex;
  width: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.color-meaning-ph1 {
  position: relative;
  top: 10px;
  z-index: 800;
  left: 0;
  transition: all 0.3s ease-out;
  display: flex;
  gap: 8px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.color-meaning-ph1 .color-meaning-right {
  display: flex;
}
.color-meaning-ph1 .color-meaning-right .map-filters {
  display: flex;
  width: 200px;
  padding: var(--number-0-px, 0px);
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--number-0-px, 0px);
  border-radius: var(--number-8-px, 8px);
  opacity: var(--background-map, 1);
  cursor: pointer;
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph1 .color-meaning-right .map-filters .close-filter {
  display: flex;
  padding: var(--number-8-px, 8px) var(--number-16-px, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--number-8-px, 8px);
  flex: 1 0 0;
  border-radius: var(--number-8-px, 8px);
  opacity: var(--background-map, 1);
  background: var(--default-white, #fff);
  border: var(--Number-0-px, 1px) solid var(--Gray-Warm-300, #d6d3d1);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph1 .color-meaning-right .map-filters .close-filter:hover {
  box-shadow: 0px 0px 0px 4px #f9f0de, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-top: 1px solid var(--stroke-primary-stroke, #d6bb87);
  border-right: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
  border-bottom: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
  border-left: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
}
.color-meaning-ph1 .color-meaning-right .map-filters .forms-filter {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.color-meaning-ph1 .color-meaning-right .map-filters .forms-filter .col-forms-filter {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0;
  text-align: right;
}
.color-meaning-ph1 .color-meaning-right .map-filters .forms-filter .col-forms-filter label {
  color: var(--text-body-text-2, #78716c);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}
.color-meaning-ph1 .color-meaning-right .map-filters .forms-filter .col-forms-filter .slider-ranger {
  margin-top: 40px;
}
.color-meaning-ph1 .color-meaning-right .map-filters .dropdown-menu {
  border-radius: var(--number-8-px, 8px);
  border-top: 1px solid var(--stroke-gray-stroke, #e7e5e4);
  border-right: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-bottom: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-left: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
  opacity: var(--background-map, 1);
  background: var(--White, #fff);
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph1 .color-meaning-left {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 40px;
}
.color-meaning-ph1 .color-meaning-left .container-color-meaning {
  display: flex;
  flex-direction: row;
  height: var(--number-32-px, 32px);
  padding: 6px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: var(--number-8-px, 8px);
  border-radius: 6px;
  background: var(--background-background, #fff);
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph1 .color-meaning-left .container-color-meaning .dot {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: flex-start;
  gap: 10px;
}
.color-meaning-ph1 .color-meaning-left .container-color-meaning .text-color-meaning {
  color: var(--text-body-text, #44403c);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.color-meaning-ph1 .non-selected {
  border: 3px solid var(--gray-true-300, #d4d4d4);
}
.color-meaning-ph1 .selected {
  border: 3px solid var(--gray-true-300, #ebcd94);
}

.popup-mouse-in {
  position: relative;
  background-color: white;
  top: 30px;
  right: 10px;
  z-index: 600;
  left: 0;
  transition: all 0.3s ease-out;
  display: flex;
  gap: 8px;
  height: auto;
  width: 250px;
  display: flex;
  padding: 15px 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}

.table-price {
  table-layout: inherit;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  zoom: 0.8;
}
.table-price td {
  height: 56px;
  width: 30px !important;
  padding: var(--number-0-px, 0px) var(--number-16-px, 16px) var(--number-0-px, 0px) 16px;
  color: var(--text-heading-text, #1c1917);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.18px;
}
.table-price td i {
  font-weight: 100;
}
.table-price .main-td {
  width: 287px !important;
  text-align: right !important;
}
.table-price .th-col {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  align-items: center;
  gap: var(--number-12-px, 12px);
  margin-bottom: 24px;
}
.table-price .th-col .title {
  color: var(--text-heading-text, #1c1917);
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: -0.18px;
}
.table-price .th-col .price {
  width: 146px;
  height: 58px;
  display: flex;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--default-white, #fff);
}
.table-price .th-col .price .price-title {
  display: flex;
  padding: 3.5px var(--number-0-px, 0px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--secondary-500-base, #039154);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}
.table-price .category-info {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-16-px, 16px);
  justify-content: right;
}
.table-price .category-info .title {
  color: var(--text-heading-text, #1c1917);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.2px;
}
.table-price .category-info {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-16-px, 16px);
  justify-content: right;
}
.table-price .category-info .title {
  color: var(--text-heading-text, #1c1917);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.2px;
}

.dropdown-map-filter {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--number-0-px, 0px);
  width: 590px;
}
.dropdown-map-filter .dropdown-map-filter-header {
  display: flex;
  padding: 10px var(--number-16-px, 16px);
  justify-content: space-between;
  align-items: center;
  gap: var(--number-8-px, 8px);
  align-self: stretch;
  border-radius: var(--number-8-px, 8px) var(--number-8-px, 8px) var(--number-0-px, 0px) var(--number-0-px, 0px);
  border-right: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-bottom: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-left: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  opacity: var(--background-map, 1);
  background: var(--default-white, #fff);
}
.dropdown-map-filter .dropdown-map-filter-header .text {
  color: var(--text-body-text, #44403c);
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 144.444% */
  letter-spacing: -0.18px;
}
.dropdown-map-filter .line {
  height: var(--background-map, 1px);
  align-self: stretch;
  border-radius: var(--number-0-px, 0px);
  opacity: var(--background-map, 1);
  background: var(--stroke-gray-stroke, #e7e5e4);
}
.dropdown-map-filter .labels-fomrs {
  display: flex;
  padding: var(--number-0-px, 0px);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 5px;
}
.dropdown-map-filter .labels-fomrs .text {
  color: var(--text-body-text-2, #78716c);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}
.dropdown-map-filter .labels-fomrs .reset {
  color: var(--text-body-text-4, #d6d3d1);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
  cursor: pointer;
}

.bindTooltip {
  font-size: 11px !important;
}

.leaflet-popup-content {
  width: 300px !important;
}

.leaflet-popup-pane {
  z-index: 1450 !important;
}

.side-color {
  position: absolute;
  background-color: white;
  top: 77%;
  right: 10px;
  z-index: 700;
  left: 0;
  display: flex;
  gap: 8px;
  height: 180px;
  width: 94%;
  display: flex;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}

@media (max-width: 1340px) {
  .color-meaning-ph1 {
    padding: 0 30px;
  }
  .color-meaning-ph1 .color-meaning-left {
    display: none;
  }
  .color-meaning-ph1 .color-meaning-right .map-filters .forms-filter .col-forms-filter label {
    font-size: 12px;
  }

  .ph1 .header-sidebar .text-header {
    color: #1c1917;
    padding: 8px 24px;
    /* Heading/h3/Medium */
    font-size: 16px;
  }

  .dropdown-map-filter {
    width: 225px;
  }
  .dropdown-map-filter .dropdown-map-filter-header .text {
    font-size: 16px;
  }
  .dropdown-map-filter .labels-fomrs .text {
    font-size: 12px;
  }
  .dropdown-map-filter .labels-fomrs .reset {
    font-size: 12px;
  }
}
@media (max-width: 860px) {
  .ph1 .action-popups-section {
    display: flex;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
  }
  .ph1 .action-popups-section .action-popup-section-booking {
    width: 125px;
    display: flex;
    padding: 11px 7px;
    line-height: 24px;
    letter-spacing: -0.07px;
    font-size: 13px;
  }
}
@media (max-width: 600px) {
  .color-meaning-ph1 {
    width: 40px;
    padding: 0 10px;
  }
  .color-meaning-ph1 .color-meaning-right .map-filters {
    width: 100px;
  }
  .color-meaning-ph1 .color-meaning-right .map-filters .close-filter span {
    display: none;
  }
  .color-meaning-ph1 .color-meaning-right .map-filters .close-filter img {
    display: none;
  }

  .ph1 .action-popups-section {
    padding: 10px 20px;
    justify-content: flex-start;
  }
  .ph1 .tab-wrap {
    height: 45vh;
  }
}
@media (min-height: 1000px) {
  .ph1 .tab__content {
    height: 31vh;
  }
}
@media (min-height: 1030px) {
  .ph1 .tab__content {
    height: 32vh;
  }
}
@media (min-height: 1060px) {
  .ph1 .tab__content {
    height: 33vh;
  }
}
@media (min-height: 1090px) {
  .ph1 .tab__content {
    height: 34vh;
  }
}
@media (min-height: 1120px) {
  .ph1 .tab__content {
    height: 38vh;
  }
}
@media (min-height: 1150px) {
  .ph1 .tab__content {
    height: 41vh;
  }
}
@media (max-height: 830px) {
  .ph1 {
    height: 93vh;
  }
}
@media (max-height: 650px) {
  .ph1 {
    height: 93vh;
  }
  .ph1 .action-popups-section {
    padding: 16px 20px 30px 20px;
  }
}
:root {
  /* fonts */
  /* font sizes */
  --paragraph-p2-regular1-size: 14px;
  --paragraph-p1-regular-size: 16px;
  --paragraph-p3-regular-size: 13px;
  --heading-h2-medium1-size: 30px;
  /* Colors */
  --primary-50: #fdfaf4;
  --white: #fff;
  --gray-warm-gray-500-base: #78716c;
  --gray-warm-600: #57534e;
  --primary-600: #d6bb87;
  --primary-500-base: #ebcd94;
  --gray-warm-gray-900: #1c1917;
  --gray-warm-700: #44403c;
  --neutrals-6: #e6e8ec;
  --stroke-gray-stroke: #e7e5e4;
  /* Spacing */
  --number-8: 8px;
  /* Gaps */
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-7xs: 6px;
  /* Paddings */
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-mini: 15px;
  /* Border radiuses */
  --br-xs: 12px;
  --br-81xl: 100px;
  --br-7xs: 6px;
  --border-radus-8: 8px;
  --br-9xs: 4px;
  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-xs1: 0px 3px 4px -5px rgba(24, 24, 28, 0.03),
    0px 1px 2px rgba(24, 24, 28, 0.04);
}

.ph2 {
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 67px 30px rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  zoom: 0.8;
}
.ph2 .header-map-section {
  display: flex;
  padding: 20px 20px 10px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 8px 0 0;
  border-bottom: 1px solid #e7e5e4;
  background: #fff;
}
.ph2 .header-map-section .header-map-section-left {
  position: relative;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  gap: 12px;
}
.ph2 .header-map-section .header-map-section-left .header-map-section-icon {
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e7e5e4;
  background: #fafaf9;
  width: 40px;
  height: 40px;
}
.ph2 .header-map-section .header-map-section-left .non-active {
  border: 1px solid #f14437;
  background: #fafaf9;
  color: #ab3027;
}
.ph2 .header-map-section .header-map-section-left .active {
  border: 1px solid var(--secondary-500-base, #039154);
  color: #039154;
  background: var(--secondary-50, #e6f4ee);
}
.ph2 .header-map-section .header-map-section-right-stop {
  display: flex;
  padding: 4px 12px 4px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
}
.ph2 .header-map-section .header-map-section-right-start {
  display: flex;
  padding: 4px 12px 4px 10px;
  align-items: center;
  gap: 4px;
  border-radius: var(--number-8-px, 8px);
  border: 1px solid var(--secondary-500-base, #039154);
  background: var(--secondary-50, #e6f4ee);
}
.ph2 .header-sidbar {
  display: flex;
  align-items: flex-start;
  gap: var(--number-0-px, 0px);
  align-self: stretch;
}
.ph2 .header-sidbar .text-header {
  color: #1c1917;
  padding: 11px 24px;
  /* Heading/h3/Medium */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
}
.ph2 .header-sidbar .text-no-length {
  color: #a8a29e;
  text-align: center;
  margin-top: 40px;
  /* Paragraph/p1/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}
.ph2 .table-booked {
  width: 100%;
  height: 70vh;
  overflow: auto;
  padding: 0 25px;
}
.ph2 .table-booked .mat-header-cell {
  border-top: 1px solid var(--stroke-gray-stroke, #e7e5e4);
  border-right: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-bottom: var(--background-map, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-left: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  background: var(--gray-true-50, #fafafa);
}
.ph2 .table-booked td,
.ph2 .table-booked th {
  text-align: center !important;
}
.ph2 .action-popups-section {
  display: flex;
  padding: 16px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0 0 8px 0;
  background: #fff;
}
.ph2 .action-popups-section .section-button {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.ph2 .action-popups-section .action-popup-section-booking {
  width: 160px;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 100px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.07px;
  cursor: pointer;
  background: var(--primary-500-base, #ebcd94);
  color: var(--gray-warm-gray-900, #1c1917) !important;
}
.ph2 .camps-booked {
  border-radius: 8px;
}
.ph2 .camps-booked .camp-container {
  margin-top: 10px;
  border: 1px solid var(--Stroke-Primary-Stroke, #d6bb87);
  border-radius: 8px;
}
.ph2 .camps-booked .camp-container .header-booked {
  background: var(--Background-Gray, #f5f5f4);
}
.ph2 .camps-booked .camp-container .title-booked {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-8-px, 8px);
}
.ph2 .camps-booked .camp-container .title-booked .text {
  color: var(--text-body-text-2, #78716c);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.ph2 .camps-booked .camp-container .title-booked .plot {
  color: var(--text-body-text, #44403c);
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.16px;
}
.ph2 .camps-booked .camp-container .camps-list {
  display: flex;
  height: 40px;
  align-items: center;
  gap: var(--Number-0-px, 35px);
  align-self: stretch;
  border-radius: var(--Number-0-px, 0px);
  border-top: var(--Number-0-px, 0px) solid var(--Stroke-Gray-Stroke, #e7e5e4);
  border-right: var(--Number-0-px, 0px) solid var(--Stroke-Gray-Stroke, #e7e5e4);
  border-bottom: var(--Background-MAP, 1px) solid var(--Stroke-Gray-Stroke, #e7e5e4);
  border-left: var(--Number-0-px, 0px) solid var(--Stroke-Gray-Stroke, #e7e5e4);
  opacity: var(--Background-MAP, 1);
  background: var(--Background-Background, #fff);
}
.ph2 .camps-booked .camp-container .camps-list .details {
  width: 50%;
  display: flex;
  padding: var(--Number-0-px, 0px);
  align-items: center;
  gap: 6px;
  justify-content: center;
}
.ph2 .camps-booked .camp-container .camps-list .details .title {
  color: var(--Text-Body-Text-2, #78716c);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}
.ph2 .camps-booked .camp-container .camps-list .details .value {
  opacity: var(--Background-MAP, 1);
  color: var(--Text-Body-Text, #44403c);
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.color-meaning-ph2 {
  position: relative;
  top: 10px;
  z-index: 800;
  left: 0;
  transition: all 0.3s ease-out;
  display: flex;
  gap: 8px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.color-meaning-ph2 .color-meaning-right {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.color-meaning-ph2 .color-meaning-right .map-filters {
  display: flex;
  width: 255px;
  padding: var(--number-0-px, 0px);
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--number-0-px, 0px);
  border-radius: var(--number-8-px, 8px);
  opacity: var(--background-map, 1);
  cursor: pointer;
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph2 .color-meaning-right .map-filters .close-filter {
  display: flex;
  padding: var(--number-8-px, 8px) var(--number-16-px, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--number-8-px, 8px);
  flex: 1 0 0;
  border-radius: var(--number-8-px, 8px);
  opacity: var(--background-map, 1);
  background: var(--default-white, #fff);
  border: var(--Number-0-px, 1px) solid var(--Gray-Warm-300, #d6d3d1);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph2 .color-meaning-right .map-filters .close-filter:hover {
  box-shadow: 0px 0px 0px 4px #f9f0de, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-top: 1px solid var(--stroke-primary-stroke, #d6bb87);
  border-right: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
  border-bottom: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
  border-left: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
}
.color-meaning-ph2 .color-meaning-right .map-filters .forms-filter {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.color-meaning-ph2 .color-meaning-right .map-filters .forms-filter .col-forms-filter {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0;
  text-align: right;
}
.color-meaning-ph2 .color-meaning-right .map-filters .forms-filter .col-forms-filter label {
  color: var(--text-body-text-2, #78716c);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}
.color-meaning-ph2 .color-meaning-right .map-filters .forms-filter .col-forms-filter .slider-ranger {
  margin-top: 40px;
}
.color-meaning-ph2 .color-meaning-right .map-filters .dropdown-menu {
  border-radius: var(--number-8-px, 8px);
  border-top: 1px solid var(--stroke-gray-stroke, #e7e5e4);
  border-right: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-bottom: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-left: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
  opacity: var(--background-map, 1);
  background: var(--White, #fff);
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph2 .color-meaning-left {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.color-meaning-ph2 .color-meaning-left .container-color-meaning {
  display: flex;
  flex-direction: row;
  height: var(--number-32-px, 32px);
  padding: 6px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: var(--number-8-px, 8px);
  border-radius: 6px;
  background: var(--background-background, #fff);
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph2 .color-meaning-left .container-color-meaning .dot {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: flex-start;
  gap: 10px;
}
.color-meaning-ph2 .color-meaning-left .container-color-meaning .text-color-meaning {
  color: var(--text-body-text, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.color-meaning-ph2 .non-selected {
  border: 3px solid var(--gray-true-300, #d4d4d4);
}
.color-meaning-ph2 .selected {
  border: 3px solid var(--gray-true-300, #ebcd94);
}

.dropdown-map-filterph2 {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--number-0-px, 0px);
  width: 370px;
}
.dropdown-map-filterph2 .dropdown-map-filter-header {
  display: flex;
  padding: 10px var(--number-16-px, 16px);
  justify-content: space-between;
  align-items: center;
  gap: var(--number-8-px, 8px);
  align-self: stretch;
  border-radius: var(--number-8-px, 8px) var(--number-8-px, 8px) var(--number-0-px, 0px) var(--number-0-px, 0px);
  border-right: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-bottom: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-left: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  opacity: var(--background-map, 1);
  background: var(--default-white, #fff);
}
.dropdown-map-filterph2 .dropdown-map-filter-header .text {
  color: var(--text-body-text, #44403c);
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 144.444% */
  letter-spacing: -0.18px;
}
.dropdown-map-filterph2 .line {
  height: var(--background-map, 1px);
  align-self: stretch;
  border-radius: var(--number-0-px, 0px);
  opacity: var(--background-map, 1);
  background: var(--stroke-gray-stroke, #e7e5e4);
}
.dropdown-map-filterph2 .labels-fomrs {
  display: flex;
  padding: var(--number-0-px, 0px);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 5px;
}
.dropdown-map-filterph2 .labels-fomrs .text {
  color: var(--text-body-text-2, #78716c);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}
.dropdown-map-filterph2 .labels-fomrs .reset {
  color: var(--text-body-text-4, #d6d3d1);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
  cursor: pointer;
}

.mat-elevation-z8-ph2 {
  box-shadow: none !important;
}
.mat-elevation-z8-ph2 th {
  padding: 0 !important;
}
.mat-elevation-z8-ph2 td {
  padding: 0 !important;
}

@media (max-width: 860px) {
  .ph2 .action-popups-section {
    display: flex;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 860px) {
  .color-meaning-ph2 {
    width: 55%;
  }
}
:root {
  /* fonts */
  /* font sizes */
  --paragraph-p2-regular1-size: 14px;
  --paragraph-p1-regular-size: 16px;
  --paragraph-p3-regular-size: 13px;
  --heading-h2-medium1-size: 30px;
  /* Colors */
  --primary-50: #fdfaf4;
  --white: #fff;
  --gray-warm-gray-500-base: #78716c;
  --gray-warm-600: #57534e;
  --primary-600: #d6bb87;
  --primary-500-base: #ebcd94;
  --gray-warm-gray-900: #1c1917;
  --gray-warm-700: #44403c;
  --neutrals-6: #e6e8ec;
  --stroke-gray-stroke: #e7e5e4;
  /* Spacing */
  --number-8: 8px;
  /* Gaps */
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-7xs: 6px;
  /* Paddings */
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-mini: 15px;
  /* Border radiuses */
  --br-xs: 12px;
  --br-81xl: 100px;
  --br-7xs: 6px;
  --border-radus-8: 8px;
  --br-9xs: 4px;
  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-xs1: 0px 3px 4px -5px rgba(24, 24, 28, 0.03),
    0px 1px 2px rgba(24, 24, 28, 0.04);
}

.ph3 {
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 67px 30px rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  zoom: 0.8;
}
.ph3 .header-map-popup-section {
  display: flex;
  padding: 20px 20px 10px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 8px 0 0;
  border-bottom: 1px solid #E7E5E4;
  background: #FFF;
}
.ph3 .header-map-popup-section i {
  font-size: 25px !important;
}
.ph3 .header-map-popup-section .header-map-popup-section-left {
  position: relative;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  gap: 12px;
}
.ph3 .header-map-popup-section .header-map-popup-section-left .header-map-popup-section-icon {
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #E7E5E4;
  background: #FAFAF9;
}
.ph3 .header-map-popup-section .header-map-popup-section-left .non-active {
  border: 1px solid #E7E5E4;
  background: #FAFAF9;
  color: #78716C;
}
.ph3 .header-map-popup-section .header-map-popup-section-left .active {
  border: 1px solid var(--secondary-500-base, #039154);
  color: #039154;
  background: var(--secondary-50, #E6F4EE);
}
.ph3 .header-map-popup-section .header-map-popup-section-right-stop {
  display: flex;
  padding: 4px 12px 4px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
}
.ph3 .header-map-popup-section .header-map-popup-section-right-start {
  display: flex;
  padding: 4px 12px 4px 10px;
  align-items: center;
  gap: 4px;
  border-radius: var(--number-8-px, 8px);
  border: 1px solid var(--secondary-500-base, #039154);
  background: var(--secondary-50, #E6F4EE);
}
.ph3 .details-map-popup-section {
  display: flex;
  padding: 16px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px;
  background: #FFF;
}
.ph3 .details-map-popup-section .details-map-popup-section-alert {
  display: flex;
  width: 98%;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 6px;
  border-radius: 5px;
  border: 1px solid var(--accent-warning-200, #FBCC8D);
  background: var(--accent-warning-50, #FEF4E6);
}
.ph3 .details-map-popup-section .details-map-popup-section-alert .alert-icon {
  display: flex;
  padding-top: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px;
}
.ph3 .details-map-popup-section .details-map-popup-section-alert .alert-text {
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  border-radius: 0px;
  text-align: right;
  color: #AF6606;
  word-wrap: break-word;
  /* Paragraph/p3/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 138.462% */
  letter-spacing: -0.13px;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--number-16-px, 14px);
  align-self: stretch;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name {
  display: flex;
  padding: 0px;
  align-items: center;
  gap: 12px;
  text-align: right;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-name {
  display: flex;
  padding: 0px;
  align-items: center;
  gap: 10px;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-number {
  display: inline;
  /* Heading/h5/Medium */
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .avalaible {
  background: var(--secondary-50, #E6F4EE);
  color: var(--accent-success-500-base, #02B37D);
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .unavalaible {
  background: var(--secondary-50, #FBC5C1);
  color: var(--accent-success-500-base, #F14437);
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-status {
  display: flex;
  height: 24px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  mix-blend-mode: multiply;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-status .plot-status-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.06px;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information {
  display: flex;
  padding: 0px;
  align-items: center;
  align-self: stretch;
  padding-right: 5px;
  flex-wrap: wrap;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col {
  display: flex;
  padding: 2px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .info-col-up {
  color: #78716C !important;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .info-col-down {
  color: #000;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .d2 {
  border: 1px solid #0E95D7;
  background: #E7F6FD;
  color: #0E95D7;
  cursor: pointer;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .d3 {
  border: 1px solid var(--secondary-500-base, #039154);
  background: var(--secondary-50, #E6F4EE);
  color: #039154;
  cursor: pointer;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
  width: 100%;
  height: 51vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info {
  border-radius: 8px;
  border: 1px solid #E7E5E4;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-selected {
  border-radius: 8px;
  border: 1px solid #EBCD94;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-selected-booked {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--accent-blue-50, #0E95D7);
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-unselected-unbooked {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--accent-blue-50, #F14437);
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-header-selected {
  border-radius: 0;
  border: 1px solid #EBCD94;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .mat-panel-title-camp {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-8-px, 8px);
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .mat-panel-title-camp .text {
  color: var(--text-body-text-2, #78716C);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .mat-panel-title-camp .camp {
  display: inline;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  align-items: center;
  align-self: stretch;
  margin-top: 15px;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information .plot-camp-information-col {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  margin-right: 8%;
  margin-bottom: 2%;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information .plot-camp-information-col .info-col-up {
  color: #78716C !important;
}
.ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information .plot-camp-information-col .info-col-down {
  color: #000;
}
.ph3 .details-map-popup-section .access_path_v2 {
  display: flex;
  padding: var(--number-16-px, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--number-20-px, 20px);
  align-self: stretch;
}
.ph3 .details-map-popup-section .access_path_v2 .container {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--number-4-px, 4px);
  align-self: stretch;
}
.ph3 .details-map-popup-section .access_path_v2 .container .col-access {
  display: flex;
  padding: var(--number-0-px, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.ph3 .details-map-popup-section .access_path_v2 .container .col-access .col-access-left {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-12-px, 12px);
}
.ph3 .details-map-popup-section .access_path_v2 .container .col-access .col-access-left .text {
  color: var(--text-body-text-2, #78716C);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.ph3 .details-map-popup-section .access_path_v2 .container .col-access .col-access-right {
  color: var(--text-heading-text, #1C1917);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.ph3 .details-map-popup-section .access_path_v2 .container .dot {
  display: flex;
  width: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.ph3 .action-popups-section {
  display: flex;
  padding: 16px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0 0 8px 0;
  border-top: 1px solid #E7E5E4;
  background: #FFF;
}
.ph3 .action-popups-section .section-button {
  display: flex;
  align-items: flex-start;
}
.ph3 .action-popups-section .action-popup-section-canacel {
  width: 88px;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 100px;
  border: 1px solid #D6D3D1;
  background: #FFF;
  cursor: pointer;
}
.ph3 .action-popups-section .action-popup-section-booking {
  width: 160px;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 100px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: -0.07px;
  cursor: pointer;
}
.ph3 .action-popups-section .error-selected {
  border: 1px solid #D6D3D1;
  background: #FFF;
}
.ph3 .action-popups-section .non-selected {
  background: #F9F0DE;
  color: #A79269;
}
.ph3 .action-popups-section .selected {
  background: var(--primary-500-base, #EBCD94);
  color: var(--gray-warm-gray-900, #1C1917);
}
.ph3 .action-popups-section .selected-booked {
  background: #F9F0DE;
  color: #A79269;
}
.ph3 .action-popups-section .action-popup-section-start {
  width: 148px;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 100px;
  background: #EBCD94;
  color: #1C1917;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: -0.07px;
  cursor: pointer;
}
.ph3 .col-details-add {
  width: 33%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}
.ph3 .successfully-section {
  display: flex;
  height: 470px;
  padding: var(--number-16-px, 16px) var(--number-20-px, 20px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--number-12-px, 12px);
  flex-shrink: 0;
  align-self: stretch;
}
.ph3 .successfully-section .successfully-icon {
  display: flex;
  padding: var(--number-12-px, 12px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: var(--secondary-50, #E6F4EE);
}
.ph3 .successfully-section .successfully-icon img {
  width: 61px;
  height: 60px;
}
.ph3 .successfully-section .error-icon {
  display: flex;
  padding: var(--number-12-px, 12px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: var(--secondary-50, #FEECEB);
}
.ph3 .successfully-section .error-icon img {
  width: 61px;
  height: 60px;
}
.ph3 .successfully-section .successfully-message {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--number-8-px, 8px);
}
.ph3 .successfully-section .successfully-message .header-message {
  color: var(--gray-warm-gray-900, #1C1917);
  leading-trim: both;
  text-edge: cap;
  /* Heading/h5/Medium */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 144.444% */
}
.ph3 .successfully-section .successfully-message .content-message {
  color: var(--gray-warm-600, #57534E);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /* Paragraph/p2/Table */
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 43px;
  /* 142.857% */
  letter-spacing: -0.14px;
}

.color-meaning-ph3 {
  position: fixed;
  top: 70px;
  z-index: 800;
  left: 0;
  transition: all 0.3s ease-out;
  display: flex;
  gap: 8px;
  height: 30px;
  width: 76%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 20px;
}
.color-meaning-ph3 .color-meaning-right .map-filters {
  background: #fff;
  -webkit-transition: all 0.2s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.19), 0 3px 6px rgba(0, 0, 0, 0);
  transition: all 0.2s ease;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: auto;
}
.color-meaning-ph3 .color-meaning-right .map-filters .close-filter {
  width: 145px;
  height: 30px;
  font-size: 12px !important;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.19), 0 3px 6px rgba(0, 0, 0, 0);
}
.color-meaning-ph3 .color-meaning-right .map-filters .close-filter .header-flex {
  display: flex;
  padding: 2px 13px;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 11px;
}
.color-meaning-ph3 .color-meaning-right .map-filters .close-filter i {
  font-size: 13px !important;
}
.color-meaning-ph3 .color-meaning-right .map-filters .forms-filter {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.color-meaning-ph3 .color-meaning-right .map-filters .forms-filter .col-forms-filter {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0;
  text-align: right;
}
.color-meaning-ph3 .color-meaning-right .map-filters .forms-filter .col-forms-filter label {
  color: var(--text-body-text-2, #78716C);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}
.color-meaning-ph3 .color-meaning-right .map-filters .forms-filter .col-forms-filter .slider-ranger {
  margin-top: 40px;
}
.color-meaning-ph3 .color-meaning-right .map-filters .dropdown-menu {
  border-radius: var(--number-8-px, 8px);
  border-top: 1px solid var(--stroke-gray-stroke, #E7E5E4);
  border-right: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #E7E5E4);
  border-bottom: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #E7E5E4);
  border-left: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #E7E5E4);
  opacity: var(--background-map, 1);
  background: var(--White, #FFF);
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph3 .color-meaning-left {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.color-meaning-ph3 .color-meaning-left .container-color-meaning {
  display: flex;
  flex-direction: row;
  height: var(--number-32-px, 32px);
  padding: 6px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: var(--number-8-px, 8px);
  border-radius: 6px;
  border: 1px solid var(--gray-true-300, #D4D4D4);
  background: var(--background-background, #FFF);
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph3 .color-meaning-left .container-color-meaning .dot {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: flex-start;
  gap: 10px;
}
.color-meaning-ph3 .color-meaning-left .container-color-meaning .text-color-meaning {
  color: var(--text-body-text, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.color-meaning-ph3 .color-meaning-left-v2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  left: 0;
  gap: 8px;
}
.color-meaning-ph3 .color-meaning-left-v2 .container-color-meaning {
  display: flex;
  flex-direction: row;
  height: var(--number-32-px, 32px);
  padding: 6px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: var(--number-8-px, 8px);
  border-radius: 6px;
  border: 1px solid var(--gray-true-300, #D4D4D4);
  background: var(--background-background, #FFF);
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.color-meaning-ph3 .color-meaning-left-v2 .container-color-meaning .dot {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: flex-start;
  gap: 10px;
}
.color-meaning-ph3 .color-meaning-left-v2 .container-color-meaning .text-color-meaning {
  color: var(--text-body-text, #44403C);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.color-meaning-ph3 .non-selected {
  border: 3px solid var(--gray-true-300, #D4D4D4);
}
.color-meaning-ph3 .selected {
  border: 3px solid var(--gray-true-300, #EBCD94);
}

@media (min-height: 1000px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 60vh;
  }
}
@media (min-height: 1030px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 62vh;
  }
}
@media (min-height: 1060px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 64vh;
  }
}
@media (min-height: 1090px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 66vh;
  }
}
@media (min-height: 1120px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 68vh;
  }
}
@media (min-height: 1150px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 70vh;
  }
}
:root {
  /* fonts */
  /* font sizes */
  --paragraph-p2-regular1-size: 14px;
  --paragraph-p1-regular-size: 16px;
  --paragraph-p3-regular-size: 13px;
  --heading-h2-medium1-size: 30px;
  /* Colors */
  --primary-50: #fdfaf4;
  --white: #fff;
  --gray-warm-gray-500-base: #78716c;
  --gray-warm-600: #57534e;
  --primary-600: #d6bb87;
  --primary-500-base: #ebcd94;
  --gray-warm-gray-900: #1c1917;
  --gray-warm-700: #44403c;
  --neutrals-6: #e6e8ec;
  --stroke-gray-stroke: #e7e5e4;
  /* Spacing */
  --number-8: 8px;
  /* Gaps */
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-7xs: 6px;
  /* Paddings */
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-mini: 15px;
  /* Border radiuses */
  --br-xs: 12px;
  --br-81xl: 100px;
  --br-7xs: 6px;
  --border-radus-8: 8px;
  --br-9xs: 4px;
  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-xs1: 0px 3px 4px -5px rgba(24, 24, 28, 0.03),
    0px 1px 2px rgba(24, 24, 28, 0.04);
}

.ph4 {
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 67px 30px rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  zoom: 0.8;
}
.ph4 .header-map-popup-section {
  display: flex;
  padding: 20px 20px 10px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 8px 0 0;
  border-bottom: 1px solid #e7e5e4;
  background: #fff;
}
.ph4 .header-map-popup-section i {
  font-size: 25px !important;
}
.ph4 .header-map-popup-section .header-map-popup-section-left {
  position: relative;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  gap: 12px;
}
.ph4 .header-map-popup-section .header-map-popup-section-left .header-map-popup-section-icon {
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e7e5e4;
  background: #fafaf9;
}
.ph4 .header-map-popup-section .header-map-popup-section-left .non-active {
  border: 1px solid #e7e5e4;
  background: #fafaf9;
  color: #78716c;
}
.ph4 .header-map-popup-section .header-map-popup-section-left .active {
  border: 1px solid var(--secondary-500-base, #039154);
  color: #039154;
  background: var(--secondary-50, #e6f4ee);
}
.ph4 .header-map-popup-section .header-map-popup-section-right-stop {
  display: flex;
  padding: 4px 12px 4px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
}
.ph4 .header-map-popup-section .header-map-popup-section-right-start {
  display: flex;
  padding: 4px 12px 4px 10px;
  align-items: center;
  gap: 4px;
  border-radius: var(--number-8-px, 8px);
  border: 1px solid var(--secondary-500-base, #039154);
  background: var(--secondary-50, #e6f4ee);
}
.ph4 .details-map-popup-section {
  display: flex;
  padding: 16px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px;
  background: #fff;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--number-16-px, 14px);
  align-self: stretch;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name {
  display: flex;
  padding: 0px;
  align-items: center;
  gap: 12px;
  text-align: right;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-name {
  display: flex;
  padding: 0px;
  align-items: center;
  gap: 10px;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-number {
  color: #44403c;
  /* Heading/h5/Medium */
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .avalaible {
  background: var(--secondary-50, #e6f4ee);
  color: var(--accent-success-500-base, #02b37d);
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .unavalaible {
  background: var(--secondary-50, #fbc5c1);
  color: var(--accent-success-500-base, #f14437);
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-status {
  display: flex;
  height: 24px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  mix-blend-mode: multiply;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-name .plot-status .plot-status-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.06px;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information {
  display: flex;
  padding: 0px;
  align-items: center;
  align-self: stretch;
  padding-right: 5px;
  flex-wrap: wrap;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col {
  display: flex;
  padding: 2px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .info-col-up {
  color: #78716c !important;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .info-col-down {
  color: #000;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .d2 {
  border: 1px solid #0e95d7;
  background: #e7f6fd;
  color: #0e95d7;
  cursor: pointer;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-plot .details-plot-information .plot-information-col .d3 {
  border: 1px solid var(--secondary-500-base, #039154);
  background: var(--secondary-50, #e6f4ee);
  color: #039154;
  cursor: pointer;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info {
  border-radius: 8px;
  border: 1px solid #e7e5e4;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-selected {
  border-radius: 8px;
  border: 1px solid #ebcd94;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-selected-booked {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--accent-blue-50, #0e95d7);
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-unselected-unbooked {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--accent-blue-50, #f14437);
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .info-header-selected {
  border-radius: 0;
  border: 1px solid #ebcd94;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .mat-panel-title-camp {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-8-px, 8px);
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .mat-panel-title-camp .text {
  color: var(--text-body-text-2, #78716c);
  leading-trim: both;
  text-edge: cap;
  /* Paragraph/p2/Table */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .mat-panel-title-camp .camp {
  display: inline;
  /* Paragraph/p2/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  align-items: center;
  align-self: stretch;
  margin-top: 15px;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information .plot-camp-information-col {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  margin-right: 8%;
  margin-bottom: 2%;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information .plot-camp-information-col .info-col-up {
  color: #78716c !important;
}
.ph4 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp .details-camp-plot-information .plot-camp-information-col .info-col-down {
  color: #000;
}
.ph4 .details-map-popup-section .access_path_v2 {
  display: flex;
  padding: var(--number-16-px, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--number-20-px, 20px);
  align-self: stretch;
}
.ph4 .details-map-popup-section .access_path_v2 .container {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--number-4-px, 4px);
  align-self: stretch;
}
.ph4 .details-map-popup-section .access_path_v2 .container .col-access {
  display: flex;
  padding: var(--number-0-px, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.ph4 .details-map-popup-section .access_path_v2 .container .col-access .col-access-left {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-12-px, 12px);
}
.ph4 .details-map-popup-section .access_path_v2 .container .col-access .col-access-left .text {
  color: var(--text-body-text-2, #78716c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.ph4 .details-map-popup-section .access_path_v2 .container .col-access .col-access-right {
  color: var(--text-heading-text, #1c1917);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.ph4 .details-map-popup-section .access_path_v2 .container .dot {
  display: flex;
  width: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.contriner-header-sidebar-information-sub {
  display: flex;
  padding: var(--Number-16-px, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}
.contriner-header-sidebar-information-sub .icon-continer {
  display: flex;
  padding: 0px;
  align-items: flex-start;
  gap: 0px;
}
.contriner-header-sidebar-information-sub .title-continer {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;
}

.background {
  background: #F5F5F4;
}

@media only screen and (max-width: 1250px) {
  .contriner-header-sidebar-information-sub {
    align-items: center;
    padding: 10px;
  }

  .contriner-header-sidebar-information-sub {
    margin-top: 4px;
    flex-direction: row;
  }
}
.icon-table {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: flex-start;
  gap: 6px;
  border-radius: var(--number-0-px, 0px);
  opacity: var(--background-map, 1);
  cursor: pointer;
}
.icon-table .button-table {
  display: flex;
  width: var(--number-24-px, 24px);
  height: var(--number-24-px, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border-top: 1px solid var(--stroke-gray-stroke);
  border-right: var(--number-0-px, 1px) solid var(--stroke-gray-stroke);
  border-bottom: var(--number-0-px, 1px) solid var(--stroke-gray-stroke);
  border-left: var(--number-0-px, 1px) solid var(--stroke-gray-stroke);
  opacity: var(--background-map, 1);
  background: var(--bs-body-secondry) !important;
  color: var(--text-typography) !important;
}
.icon-table .button-table img {
  color: var(--text-typography) !important;
  fill: var(--text-typography) !important;
  filter: var(--icon-filtter);
}

.widget-datatable .mat-header-cell {
  border-right: 0px solid var(--stroke-gray-stroke) !important;
  border-bottom: 1px solid var(--stroke-gray-stroke) !important;
  border-left: 0px solid var(--stroke-gray-stroke) !important;
  background: var(--bs-body-secondry) !important;
  color: var(--text-typography) !important;
}

.search-box-main-data {
  position: relative;
  width: 240px;
}
.search-box-main-data .form-control {
  background: var(--bs-body-secondry) !important;
  color: var(--text-typography) !important;
  padding-inline-start: 40px;
  width: 100%;
}
.search-box-main-data .search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  font-size: 20px;
  color: #a8a29e;
}

@media (max-width: 768px) {
  .mat-table[matTableResponsive] .mat-header-row {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 1rem 0.5rem;
    justify-content: space-around;
  }
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell > .mat-sort-header-container {
    padding-left: 15px;
  }
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell:not([mat-sort-header]), .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell.mat-sort-header-disabled, .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell.mat-column-actions {
    display: none;
  }
  .mat-table[matTableResponsive] .mat-row {
    height: auto;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-cell:not(.mat-column-actions) {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: right;
    word-break: break-word;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-cell:not(.mat-column-actions) > * {
    grid-column: 2;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-cell:not(.mat-column-actions):before {
    content: attr(data-column-name);
    background-color: #F5E5C9;
    text-align: center;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-column-actions {
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-column-actions > button {
    margin: 0.5rem 0;
  }
  .mat-table[matTableResponsive] .mat-row:last-of-type td:last-of-type {
    border: none;
  }
  .mat-table[matTableResponsive] .mat-row:not(:last-of-type) td:last-of-type {
    border-bottom: 0.5rem solid #fafafa;
  }

  .mat-card .mat-table[matTableResponsive],
.mat-expansion-panel .mat-table[matTableResponsive] {
    border: 0.5rem solid #fafafa;
  }
}
@media (min-width: 769px) {
  .mobile-table-100 {
    width: 100% !important;
  }

  .mobile-table-90 {
    width: 90% !important;
  }

  .mobile-table-75 {
    width: 75% !important;
  }

  .mobile-table-50 {
    width: 50% !important;
  }

  .mobile-table-25 {
    width: 25% !important;
  }
}
::ng-deep {
  font-family: "IBM Plex Sans Arabic", sans-serif !important;
}

.interactive-map {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  position: relative;
  display: flex;
  direction: rtl;
}
.interactive-map .section-sidebar {
  position: relative;
  height: 100%;
  z-index: 800;
  background-color: #0f0f0f;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transition: 0.5s;
}
.interactive-map .section-map {
  position: relative;
  width: 100%;
  height: 0vh;
  z-index: 800;
}
.interactive-map .section-map .filter {
  position: relative;
  top: 10px;
  left: 0;
  transition: all 0.3s ease-out;
  display: flex;
  gap: 8px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 800;
}
.interactive-map .section-map .filter .color-meaning-right {
  display: flex;
}
.interactive-map .section-map .filter .color-meaning-right .map-filters {
  display: flex;
  width: 200px;
  padding: var(--number-0-px, 0px);
  align-items: flex-start;
  gap: var(--number-0-px, 0px);
  border-radius: var(--number-8-px, 8px);
  opacity: var(--background-map, 1);
  cursor: pointer;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.interactive-map .section-map .filter .color-meaning-right .map-filters .close-filter {
  display: flex;
  padding: var(--number-8-px, 8px) var(--number-16-px, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--number-8-px, 8px);
  flex: 1 0 0;
  border-radius: var(--number-8-px, 100px);
  opacity: var(--background-map, 1);
  background-color: var(--bs-body-bg-interactive-map);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.interactive-map .section-map .filter .color-meaning-right .map-filters .icon-active {
  transform: rotate(180deg);
  transition: transform 0.3s linear;
}
.interactive-map .section-map .filter .color-meaning-right .map-filters .icon-dis-active {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}
.interactive-map .section-map .filter .color-meaning-right .map-filters .forms-filter {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.interactive-map .section-map .filter .color-meaning-right .map-filters .forms-filter .col-forms-filter {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0;
  text-align: right;
  margin: 0;
}
.interactive-map .section-map .filter .color-meaning-right .map-filters .forms-filter .col-forms-filter .slider-ranger {
  margin-top: 40px;
}
.interactive-map .section-map .filter .color-meaning-left {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 40px;
}
.interactive-map .section-map .filter .color-meaning-left .container-color-meaning {
  display: flex;
  flex-direction: row;
  height: var(--number-32-px, 32px);
  padding: 6px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: var(--number-8-px, 8px);
  border-radius: 6px;
  background: var(--background-background, #fff);
  /* Map */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}
.interactive-map .section-map .filter .color-meaning-left .container-color-meaning .dot {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: flex-start;
  gap: 10px;
}
.interactive-map .section-map .filter .color-meaning-left .container-color-meaning .text-color-meaning {
  color: var(--text-body-text, #44403c);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.interactive-map .section-map .filter .non-selected {
  border: 3px solid var(--gray-true-300, #d4d4d4);
}
.interactive-map .section-map .filter .selected {
  border: 3px solid var(--gray-true-300, #B49164);
}
.interactive-map .map {
  z-index: 700;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}

.dropdown-menu-right-interactive {
  right: 0 !important;
  left: auto !important;
  transform: translate(0, 49px) !important;
  background-color: var(--bs-body-bg-interactive-map);
}

.dropdown-map-filter-interactive-map {
  display: flex;
  padding: var(--number-0-px, 16px);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--number-0-px, 0px);
  width: 270px;
  height: 70vh;
  overflow-y: auto;
}
.dropdown-map-filter-interactive-map .dropdown-map-filter-header {
  display: flex;
  padding: 10px var(--number-16-px, 16px);
  justify-content: space-between;
  align-items: center;
  gap: var(--number-8-px, 8px);
  align-self: stretch;
  border-radius: var(--number-8-px, 8px) var(--number-8-px, 8px) var(--number-0-px, 0px) var(--number-0-px, 0px);
  border-right: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-bottom: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  border-left: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
  opacity: var(--background-map, 1);
}
.dropdown-map-filter-interactive-map .line {
  height: var(--background-map, 1px);
  align-self: stretch;
  border-radius: var(--number-0-px, 0px);
  background: var(--stroke-gray-stroke, #e7e5e4);
}
.dropdown-map-filter-interactive-map .labels-fomrs {
  display: flex;
  padding: var(--number-0-px, 0px);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 5px;
}
.dropdown-map-filter-interactive-map .labels-fomrs .reset {
  color: var(--text-body-text-4, #d6d3d1);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none;
}

.details-map-popup-section {
  display: flex;
  padding: 3px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px;
}
.details-map-popup-section .access_path_v2 {
  display: flex;
  padding: 16p 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--number-20-px, 20px);
  align-self: stretch;
}
.details-map-popup-section .access_path_v2 .container {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--number-4-px, 4px);
  align-self: stretch;
}
.details-map-popup-section .access_path_v2 .container .col-access {
  display: flex;
  padding: var(--number-0-px, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.details-map-popup-section .access_path_v2 .container .col-access .col-access-left {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-12-px, 12px);
}
.details-map-popup-section .access_path_v2 .container .col-access .col-access-left .text {
  color: var(--text-typography) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.details-map-popup-section .access_path_v2 .container .col-access .col-access-right {
  color: var(--text-typography) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}
.details-map-popup-section .access_path_v2 .container .dot {
  display: flex;
  width: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}