@font-face {
  font-family: 'tafweej';
  src:  url('../fonts/tafweej.eot?3rlw27');
  src: url('../fonts/tafweej.eot?3rlw27#iefix') format('embedded-opentype'),
    url('../fonts/tafweej.ttf?3rlw27') format('truetype'),
    url('../fonts/tafweej.woff?3rlw27') format('woff'),
    url('../fonts/tafweej.svg?3rlw27#tafweej') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ic-"], [class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tafweej' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ic-weather:before {
    content: "\e95b";
}

.ic-map-marker-f:before {
    content: "\e956";
}

.ic-path:before {
    content: "\e957";
}

.ic-street:before {
    content: "\e958";
}

.ic-train-f:before {
    content: "\e959";
}

.ic-m-arrow-left:before {
    content: "\e955";
}

.ic-camp:before {
    content: "\e900";
}

.ic-building:before {
    content: "\e901";
}

.ic-users-check:before {
    content: "\e902";
}

.ic-dots:before {
    content: "\e903";
}

.ic-search:before {
    content: "\e904";
}

.ic-meeting:before {
    content: "\e905";
}

.ic-home:before {
    content: "\e906";
}

.ic-table:before {
    content: "\e907";
}

.ic-photo-gallery:before {
    content: "\e908";
}

.ic-calendar-news:before {
    content: "\e909";
}

.ic-settings:before {
    content: "\e90a";
}

.ic-user-2:before {
    content: "\e90b";
}

.ic-envelope:before {
    content: "\e90c";
}

.ic-globe:before {
    content: "\e90d";
}

.ic-calendar:before {
    content: "\e90e";
}

.ic-logout:before {
    content: "\e90f";
}

.ic-add:before {
    content: "\e910";
}

.ic-caret-down:before {
    content: "\e911";
}

.ic-caret-left:before {
    content: "\e912";
}

.ic-caret-right:before {
    content: "\e913";
}

.ic-caret-up:before {
    content: "\e914";
}

.ic-checklist:before {
    content: "\e915";
}

.ic-copy:before {
    content: "\e916";
}

.ic-download:before {
    content: "\e917";
}

.ic-eye:before {
    content: "\e918";
}

.ic-play-f:before {
    content: "\e919";
}

.ic-bar-chart:before {
    content: "\e91a";
}

.ic-wifi:before {
    content: "\e91b";
}

.ic-credit-card:before {
    content: "\e91c";
}

.ic-share:before {
    content: "\e91d";
}

.ic-loading:before {
    content: "\e91e";
}

.ic-link:before {
    content: "\e91f";
}

.ic-play:before {
    content: "\e920";
}

.ic-star:before {
    content: "\e921";
}

.ic-briefcase:before {
    content: "\e922";
}

.ic-close:before {
    content: "\e923";
}

.ic-check:before {
    content: "\e924";
}

.ic-question:before {
    content: "\e925";
}

.ic-camera:before {
    content: "\e926";
}

.ic-folder:before {
    content: "\e927";
}

.ic-minus:before {
    content: "\e928";
}

.ic-file:before {
    content: "\e929";
}

.ic-copy1:before {
    content: "\e92a";
}

.ic-heart:before {
    content: "\e92b";
}

.ic-attachment:before {
    content: "\e92c";
}

.ic-info:before {
    content: "\e92d";
}

.ic-question1:before {
    content: "\e92e";
}

.ic-eye1:before {
    content: "\e92f";
}

.ic-hide:before {
    content: "\e930";
}

.ic-logout1:before {
    content: "\e931";
}

.ic-picture:before {
    content: "\e932";
}

.ic-lock:before {
    content: "\e933";
}

.ic-filter:before {
    content: "\e934";
}

.ic-instagram:before {
    content: "\e935";
}

.ic-youtube:before {
    content: "\e936";
}

.ic-twitter:before {
    content: "\e937";
}

.ic-facebook:before {
    content: "\e938";
}

.ic-menu:before {
    content: "\e939";
}

.ic-grid:before {
    content: "\e93a";
}

.ic-arrow-up:before {
    content: "\e93b";
}

.ic-arrow-left:before {
    content: "\e93c";
}

.ic-arrow-down:before {
    content: "\e93d";
}

.ic-arrow-right:before {
    content: "\e93e";
}

.ic-printer:before {
    content: "\e93f";
}

.ic-dislike:before {
    content: "\e940";
}

.ic-like:before {
    content: "\e941";
}

.ic-edit:before {
    content: "\e942";
}

.ic-delete:before {
    content: "\e943";
}

.ic-phone-call:before {
    content: "\e944";
}

.ic-home1:before {
    content: "\e945";
}

.ic-upload:before {
    content: "\e946";
}

.ic-download1:before {
    content: "\e947";
}

.ic-medal:before {
    content: "\e948";
}

.ic-email:before {
    content: "\e949";
}

.ic-chat:before {
    content: "\e94a";
}

.ic-alarm:before {
    content: "\e94b";
}

.ic-clock:before {
    content: "\e94c";
}

.ic-user:before {
    content: "\e94d";
}

.ic-refresh:before {
    content: "\e94e";
}

.ic-menu2:before {
    content: "\e94f";
}

.ic-presentation:before {
    content: "\e950";
}

.ic-plan:before {
    content: "\e951";
}

.ic-eye-hide:before {
    content: "\e952";
}

.ic-filter-f:before {
    content: "\e953";
}

.ic-print-f:before {
    content: "\e954";
}

.icon-pacman:before {
    content: "\e916";
}
