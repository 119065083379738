// page layout

.page-layout {
  height: 90vh;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 20px;
  overflow: hidden;
}

.container-fluid {
  max-width: 1500px;
  height: 100vh;
}

.invalid-feedback-custom {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 13px;
  color: #dc3545;
}

input {
  border-radius: 8px !important;
}

// modal
.modal-title {
  font-weight: bold !important;
  font-family: var(--font-family) !important;
  margin: 0 !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 32px !important;
  letter-spacing: -0.24px !important;
}

.modal-body {
  .form-control:not(:disabled) {
    color: var(--text-typography) !important;
    background-color: var(--select-input) !important;
    border-radius: 8px !important;
  }

  .ng-select .ng-select-container {
    background-color: var(--select-input) !important;
    border-radius: 8px !important;
  }

  .ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    background-color: var(--select-input) !important;
  }

  .form-control {
    border: none !important;
  }

  .ng-select .ng-select-container {
    border: none !important;
    background-color: var(--select-input) !important;
    border-radius: 8px !important;
  }

  .btn {
    font-size: 14px !important;
  }

}


::placeholder {
  color: var(--text-typography) !important;
  opacity: 1;
}

::-ms-input-placeholder {
  color: var(--text-typography) !important;
}

.mat-checkbox-layout {
  display: flex !important;
  gap: 10px !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ebcd94 !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ebcd94 !important;
}

::ng-deep .mat-ink-bar {
  background-color: #ebcd94 !important;
}

/* Styles for the active tab label */

/* Styles for the ink bar */
.mat-ink-bar {
  background-color: #ebcd94 !important;
}

.ng-select.custom-class .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  display: none !important;
}

@media print {
  .topbar-layout {
    display: none;
  }

  body {
    overflow: hidden !important;
  }
}

.text-with-line {
  h2 {
    width: 100% !important;
    text-align: center !important;
    border-bottom: 1px solid #000 !important;
    line-height: 0.1em !important;
    margin: 10px 0 20px !important;
  }

  h2 span {
    background: #fff !important;
    padding: 0 10px !important;
  }
}














// custom
