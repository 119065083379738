// swal
.swal2-popup {
  width: 520px !important;
}

.swal2-icon {
  font-size: 10px !important;
}

.swal2-html-container {
  font-size: 14px !important;
  margin: 0 !important;
  padding: 0 40px !important;
}

.swal2-popup,
.swal2-title,
.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel,
.swal2-styled.swal2-deny {
  font-family: var(--font-family) !important;
  color: #1C1917 !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel,
.swal2-styled.swal2-deny {
  border-radius: 100px !important;
  // width: 170px !important;
  height: 48px !important;

}

.swal2-styled.swal2-cancel,
.swal2-styled.swal2-deny {
  border-radius: 100px !important;
  border: 1px solid var(--Button-Tertiary-Default-Tertiary_Default_Stroke, #E7E5E4) !important;
}

.swal2-styled.swal2-deny {
  color: white !important;
}



.list-assign-offices-to-camp-alert {
  button {
    width: 110px !important;
  }
}


.w-70-alert {
  button {
    width: 70px !important;
  }
}

.w-140-alert {
  button {
    width: 140px !important;
  }
}

.w-170-alert {
  button {
    width: 170px !important;
    height: auto !important;
  }
}


.w-80-alert {
  button {
    width: 80px !important;
  }
}


.swal2-textarea{
  box-shadow: none !important;
}
