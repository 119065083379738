.text-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.text-decoration-none {
  text-decoration: none !important;
}


.font-size-74 {
  font-size: 74px;
}

.font-size-70 {
  font-size: 70px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-9 {
  font-size: 9px !important;
}

.font-size-8 {
  font-size: 8px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.line-height-normal {
  line-height: normal;
}

.line-height-1 {
  line-height: 1;
}

.line-height-md-1 {
  @media (min-width: 768px) {
    line-height: 1;
  }
}
