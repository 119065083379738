.form-check-input:checked {
  background-color: #ebcd94;
  border-color: #ebcd94;
}

.contriner-header-sidebar-information {
  width: 100%;
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  //  align-self: stretch;
  margin-top: 8px;
}

.my-labels1 {
  font-size: 12px !important;
  width: 40px !important;
}

.label-flag {
  font-size: 12px !important;
  width: 68px !important;
}

.camp-label {
  font-size: 12px !important;
  width: 50px !important;
  color: #002673;
  text-shadow: 0 0 3px #002673;
}

.plot-label {
  font-size: 12px !important;
  width: 50px !important;
  color: #ff0000;
  text-shadow: 0 0 3px #ff0000;
}

.plot-label-all {
  font-size: 18px !important;
  width: 90px !important;
  font-weight: bolder;
  color: #ff0000;
  text-shadow: 0 0 3px #ff0000;
}

.my-label-arafat {
  font-size: 17px !important;
  width: 40px !important;
  font-weight: bold;
}

@media only screen and (max-width: 860px) {
  .contriner-header-sidebar-information {
    flex-direction: column;
    gap: 0px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .contriner-header-sidebar-information {
    flex-direction: row;
    gap: 15px;
  }
}
