.df-abs-center {
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.df-abs-h-center {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%)
}

.df-container {
    min-height: 320px;
    background: no-repeat 50%;
    background-size: cover;
    width: 100%;
    position: relative;
    image-rendering: auto;
    direction: ltr
}

.df-container div {
    box-sizing: border-box !important
}

.df-container.df-transparent:not(.df-fullscreen) {
    background: transparent !important
}

.df-container.df-custom-fullscreen.df-fullscreen {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    z-index: 999999
}

.df-container .loading-info {
    position: absolute;
    top: 100%;
    margin-top: 20px;
    margin-left: 5px;
    padding: 6px 20px;
    background-color: rgba(0, 0, 0, 0.3);
    font-style: italic;
    border-radius: 5px;
    font-size: 11px;
    max-height: 30px;
    color: #fff;
    max-width: 200px;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    left: 50%;
    opacity: .7;
    transition: .3s top ease;
    -webkit-transition: .3s top ease
}

.loading-info.df-old-browser {
    color: gray;
    background-color: white;
    opacity: .9;
    text-align: center;
    max-height: none
}

.loading-info.df-old-browser a {
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    color: #2196f3;
    display: block;
    text-decoration: underline;
    margin: 10px 0 0
}

.df-container.df-init .loading-info {
    top: 50%
}

.df-3dcanvas {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0
}

.df-rtl .df-3dcanvas {
    right: auto;
    right: initial;
    left: 0
}

.df-container a {
    outline: 0;
    text-decoration: none;
    box-shadow: none
}

.df-container.df-zoom-enabled canvas.df-3dcanvas,
.df-container.df-zoom-enabled .df-book-stage {
    cursor: move;
    cursor: -webkit-grab
}

.df-container:before,
.df-container:after {
    content: " ";
    transition: .3s .1s opacity;
    -webkit-transition: .3s .1s opacity;
    opacity: 0;
    height: 50px;
    width: 50px;
    left: 50%;
    top: 50%;
    font-size: 12px;
    margin: -45px -25px -25px;
    box-sizing: border-box;
    position: absolute;
    pointer-events: none
}

.df-container.df-loading:before,
.df-container.df-loading:after {
    content: " ";
    z-index: 100;
    opacity: 1
}

.df-container.df-loading:after {
    content: " ";
    background-image: url("../images/loading.gif");
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: 50%
}

.df-container.df-loading:before {
    background-color: white;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    border: 1px solid rgba(150, 150, 150, 0.2)
}

.df-book-stage {
    position: absolute;
    overflow: hidden;
    right: 0;
    bottom: 0;
    z-index: 1;
    top: 0;
    left: 0;
    filter: blur(0);
    -webkit-filter: blur(0);
    padding: 30px 50px 70px
}

.df-container.df-xs .df-book-stage {
    padding-left: 20px;
    padding-right: 20px
}

.df-container.df-sidemenu-open .df-book-stage {
    padding-left: 230px;
    padding-right: 10px
}

.df-container.df-sidemenu-open>.df-ui-btn.df-ui-prev {
    left: 220px
}

.df-rtl.df-container.df-sidemenu-open>.df-ui-btn.df-ui-prev {
    left: 0
}

.df-rtl.df-container.df-sidemenu-open>.df-ui-btn.df-ui-next {
    right: 220px
}

.df-book-stage.df-zoom-enabled {
    cursor: move
}

.df-page-front:before,
.df-page-back:before {
    content: " "
}

.df-book-page .df-page-front:before {
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.15) 25%, rgba(0, 0, 0, 0) 70%);
    background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.15) 25%, rgba(0, 0, 0, 0) 70%);
    background-image: -ms-linear-gradient(right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.15) 25%, rgba(0, 0, 0, 0) 70%)
}

.df-book-page .df-page-back:before {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.15) 15%, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.0));
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.15) 15%, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.0));
    background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.15) 15%, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.0))
}

.df-book-wrapper,
.df-page-wrapper,
.df-book-page,
.df-page-content,
.df-page-front:before,
.df-page-wrapper:after,
.df-page-back:before,
.df-page-content:after,
.df-page-effects,
.df-page-effects:before,
.df-page-effects {
    position: absolute;
    height: 100%;
    display: block;
    top: 0
}

.df-page-content,
.df-book-page {
    width: 100%
}

.df-book-wrapper {
    position: relative;
    margin: 0 auto;
    transition: .3s left;
    -webkit-transition: .3s left;
    left: 0
}

.df-zoom-enabled .df-book-wrapper {
    transition: none;
    -webkit-transition: none
}

.df-loading.df-container .df-book-wrapper {
    left: 100%
}

.df-book-wrapper.close-right {
    left: 25%
}

.df-book-wrapper.close-left {
    left: -25%
}

.df-book-shadow {
    position: absolute;
    z-index: -1;
    height: 100%;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
}

.df-book-wrapper .df-book-side-buttons {
    position: absolute;
    left: 100%;
    width: 40px;
    opacity: .3;
    height: 100%;
    cursor: pointer;
    z-index: 30;
    -webkit-border-radius: 0 20px 20px 0;
    -moz-border-radius: 0 20px 20px 0;
    border-radius: 0 20px 20px 0
}

.df-book-prev-button.df-book-side-buttons {
    -webkit-border-radius: 20px 0 0 20px;
    -moz-border-radius: 20px 0 0 20px;
    border-radius: 20px 0 0 20px
}

.df-book-wrapper .df-book-side-buttons:hover {
    opacity: .5;
    background-color: rgba(0, 0, 0, 0.18)
}

.df-book-wrapper .df-book-prev-button {
    right: 100%;
    left: auto;
    left: initial
}

.df-book-wrapper .df-book-side-buttons:before {
    position: absolute;
    font-size: 30px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%
}

.df-page-wrapper {
    overflow: hidden
}

.df-hard-page .df-page-wrapper {
    width: 100% !important;
    height: 100% !important;
    overflow: visible;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0 50% 0;
    -moz-transform-origin: 0 50% 0;
    -ms-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0
}

.df-book-page.df-flipping,
.df-book-page.df-folding {
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.0)
}

.df-book-page.df-left-side {
    left: 0;
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right
}

.df-book-page.df-right-side {
    left: 50%;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left
}

.df-book-page.df-right-side+.df-page-wrapper.df-right-side {
    z-index: -1
}

.df-book-page .df-page-front,
.df-book-page .df-page-back {
    width: 100%;
    background-size: 100.5% 100%;
    background-size: 100% 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    box-sizing: border-box
}

.df-page-back>canvas,
.df-page-front>canvas {
    width: 100%;
    position: relative;
    z-index: -1
}

.df-quick-turn:before {
    opacity: 0
}

.df-book-page.df-flipping.df-left-side:before {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
    width: 10px;
    left: -10px
}

.df-book-page {
    width: 50%
}

.df-book-page.df-flipping,
.df-book-page.df-folding {
    z-index: 200 !important
}

.df-page-content {
    background-size: 100.25% !important;
    background-repeat: no-repeat !important;
    transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    width: 100%;
    overflow: hidden
}

.df-book-page.df-left-side .df-page-front {
    z-index: 2
}

.df-book-page.df-left-side .df-page-back {
    z-index: 1
}

.df-book-page.df-left-side.df-folding:not(.df-hard-page) .df-page-back {
    z-index: 3
}

.df-book-page.df-right-side .df-page-front {
    top: 0;
    left: 0;
    z-index: 0
}

.df-book-page,
.df-page-front,
.df-page-back,
.df-page-fold-inner-shadow,
.df-page-fold-outer-shadow,
.df-page-wrapper {
    position: absolute;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0
}

.df-page-front,
.df-page-back {
    overflow: hidden
}

.df-page-fold-outer-shadow {
    z-index: -1;
    width: 100%;
    height: 100%
}

.df-page-fold-inner-shadow {
    position: absolute;
    z-index: 5;
    display: none
}

.df-book-page.df-folding .df-page-fold-inner-shadow {
    display: block
}

.df-book-page.df-right-side .df-page-back {
    left: 0;
    z-index: 1
}

.df-book-page.df-right-side.df-folding .df-page-front {
    z-index: 2
}

.df-page-wrapper.df-right-side .df-page-back {
    left: 100%;
    z-index: 1;
    overflow: hidden
}

.df-hard-page .df-page-front,
.df-hard-page .df-page-back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden
}

.df-hard-page.df-right-side .df-page-front {
    -webkit-transform-origin: right 50%;
    -moz-transform-origin: right 50%;
    -ms-transform-origin: right 50%;
    transform-origin: right 50%;
    z-index: 3 !important
}

.df-hard-page.df-left-side .df-page-front {
    -webkit-transform-origin: right 50%;
    -moz-transform-origin: right 50%;
    -ms-transform-origin: right 50%;
    transform-origin: right 50%
}

.df-hard-page.df-right-side .df-page-back {
    -webkit-transform-origin: left 50%;
    -moz-transform-origin: left 50%;
    -ms-transform-origin: left 50%;
    transform-origin: left 50%;
    z-index: 3
}

.df-hard-page.df-left-side .df-page-back {
    -webkit-transform-origin: left 50%;
    -moz-transform-origin: left 50%;
    -ms-transform-origin: left 50%;
    transform-origin: left 50%
}

.df-page-front:before,
.df-page-back:before {
    width: 20%;
    z-index: 5;
    pointer-events: none
}

.df-page-front:before {
    right: 0
}

.df-page-wrapper.df-right-side.df-flipping {
    z-index: 4
}

.df-ui {
    height: 60px;
    padding: 20px 16px 0
}

.df-ui-wrapper,
.df-ui-btn {
    color: #888;
    vertical-align: top;
    background-color: #f7f7f7;
    box-sizing: border-box;
    text-align: center
}

.df-ui-wrapper {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    background-color: #f7f7f7;
    position: absolute
}

.df-share-wrapper {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
    display: none
}

.df-share-box {
    position: absolute;
    top: 50%;
    width: 280px;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 15px 5px;
    text-align: center
}

span.df-share-title {
    color: #777;
    margin-bottom: 10px;
    display: block
}

.df-share-url {
    background-color: #fff;
    height: 60px;
    color: #666;
    padding: 2px 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 100%;
    font-size: 12px;
    box-sizing: border-box;
    margin-bottom: 0
}

.df-share-button {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    padding: 10px;
    margin: 3px;
    color: #aaa
}

.df-share-button:hover {
    color: #444
}

.df-ui-btn {
    position: relative;
    width: 35px;
    right: 0;
    height: 40px;
    font-size: 17px;
    cursor: pointer;
    z-index: 2;
    display: inline-block;
    padding: 12px 5px;
    line-height: 1
}

.df-floating .df-ui-controls>.df-ui-btn:first-child {
    border-radius: 5px 0 0 5px
}

.df-floating .df-ui-controls>.df-ui-btn:last-child {
    border-radius: 0 5px 5px 0
}

.df-ui-btn:before {
    box-sizing: border-box
}

.df-ui-thumbnail,
.df-ui-share,
.df-ui-outline,
.df-ui-help,
.df-ui-sound {
    float: left
}

.df-ui-btn:hover {
    color: #00acce
}

.df-ui-btn.disabled,
.df-ui-btn.disabled:hover {
    color: #ccc
}

.df-ui-page {
    padding: 8px 5px 2px;
    font-size: 12px;
    width: auto;
    width: 50px;
    margin: 0;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.03)
}

.df-ui-page input {
    width: 100%;
    background-color: transparent;
    height: 25px;
    margin: 0;
    padding: 0 !important;
    border: 1px solid #8f8f8f;
    text-align: center;
    vertical-align: top;
    color: transparent;
    font-size: 14px;
    top: 0;
    box-sizing: border-box;
    z-index: -1;
    display: inline-block;
    opacity: 0
}

.df-ui-page input:focus {
    z-index: 2;
    opacity: 1;
    color: inherit
}

.df-ui-page input:focus+label {
    opacity: 0;
    display: none
}

.df-ui-page label {
    position: absolute;
    height: 30px;
    width: 100%;
    display: block;
    top: 0;
    padding-top: 14px;
    line-height: 1.1em;
    color: #999;
    font-size: 13px;
    font-weight: normal;
    left: 0;
    cursor: pointer;
    background-color: transparent;
    z-index: 1;
    box-sizing: border-box
}

.df-ui-share {
    float: left
}

.df-ui-fullscreen {
    bottom: 0
}

.df-ui-zoom {
    top: 24px;
    height: 60px;
    right: 24px;
    width: 30px;
    display: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.df-ui-sidemenu-close {
    position: absolute;
    top: 10px;
    right: -32px;
    font-size: 14px;
    width: 32px;
    height: 35px;
    cursor: pointer;
    padding: 10px 0;
    background: inherit;
    border-radius: 0 5px 5px 0;
    z-index: -1;
    transition: .5s ease;
    -webkit-transition: .5s ease
}

.df-ui-controls {
    bottom: 0;
    width: 100%;
    height: 40px;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 4;
    display: block
}

.df-floating .df-ui-controls {
    bottom: 10px;
    width: auto;
    width: initial;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
    margin: 0 auto;
    display: table;
    display: flex;
    border-radius: 5px
}

.df-floating.df-controls-top .df-ui-controls {
    bottom: auto;
    bottom: initial;
    top: 10px
}

.df-floating.df-controls-hide .df-ui-controls {
    display: none
}

.df-ui-wrapper.df-ui-controls .df-ui-btn {
    float: none
}

.df-ui-size {
    bottom: 24px;
    height: 60px;
    right: 24px
}

.df-ui-zoom:before,
.df-ui-size:before {
    height: 0;
    border-bottom: 1px solid;
    content: " ";
    right: 5px;
    opacity: .3;
    display: block;
    position: absolute;
    top: 30px;
    left: 5px;
    z-index: 2
}

.df-ui-zoomin {
    top: 0
}

.df-ui-zoomout {
    bottom: 0
}

.df-ui-next {
    right: 0;
    border-radius: 0
}

.df-container.df-floating>.df-ui-next {
    right: 8px
}

.df-container.df-floating>.df-ui-prev {
    left: 2px
}

.df-container>.df-ui-next,
.df-container>.df-ui-prev {
    margin-top: -5px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    opacity: .3;
    font-size: 36px;
    color: #000;
    text-align: center;
    background-color: transparent;
    height: auto;
    height: initial;
    width: auto;
    width: initial
}

.df-container>.df-ui-next:hover,
.df-container>.df-ui-prev:hover {
    opacity: .7
}

.df-container.df-floating>.df-ui-next,
.df-container.df-floating>.df-ui-prev {
    border-radius: 50%;
    width: 40px
}

.df-ui-prev {
    left: 0;
    right: auto;
    border-radius: 0
}

.df-ui-alt {
    position: relative !important;
    right: auto;
    right: initial;
    left: auto;
    left: initial;
    box-shadow: none !important
}

.df-ui-alt.df-ui-next {
    border-right: 1px solid #ddd
}

.df-ui-btn.df-active {
    color: #00acce;
    background-color: #e7e7e7
}

.df-ui-fit {
    display: none
}

.df-ui-zoomin,
.df-ui-zoomout,
.df-ui-fullscreen,
.df-ui-more {
    float: right
}

.df-ui-fit {
    top: 0
}

.df-ui-more .more-container,
.df-ui-search .search-container {
    display: none;
    position: absolute;
    bottom: 100%;
    margin-bottom: 8px !important;
    right: 10px;
    background-color: #f7f7f7;
    z-index: 20;
    border-radius: 5px;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.2)
}

.df-controls-top .df-ui-more .more-container,
.df-controls-top .df-ui-search .search-container {
    bottom: auto;
    bottom: initial;
    margin-top: 8px !important;
    top: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2)
}

.more-container:before,
.search-container:before {
    content: " ";
    position: absolute;
    border: 7px solid transparent;
    border-top-color: #eee;
    height: 0;
    width: 0;
    bottom: -14px;
    right: 13px;
    margin-right: -8px;
    pointer-events: none
}

.df-controls-top .more-container:before,
.df-controls-top .search-container:before {
    border: 7px solid transparent;
    border-bottom-color: #eee;
    top: -14px
}

.more-container .df-ui-btn {
    width: auto;
    width: initial;
    width: 170px;
    text-align: left;
    padding: 8px 10px;
    height: 36px;
    border-top: 1px solid #e4e4e4;
    border-radius: 0
}

.more-container .df-ui-btn:hover {
    background-color: #eee
}

.more-container>.df-ui-btn:first-child {
    border-radius: 5px;
    border-top: 0
}

.more-container>.df-ui-btn:last-child {
    border-radius: 0 0 5px 5px
}

.more-container .df-ui-btn:before {
    position: absolute;
    top: 11px;
    font-size: 14px
}

.df-ui-btn span {
    display: none
}

.more-container .df-ui-btn span {
    font-size: 12px;
    padding-left: 20px;
    vertical-align: middle;
    display: inline-block
}

.df-ui-more.df-active .more-container,
.df-ui-search.df-active .search-container {
    display: block
}

.df-container section {
    padding: 0
}

.annotationLayer section,
.annotationDiv section,
.customLinkAnnotation,
.customHtmlAnnotation,
.customVideoAnnotation,
.linkAnnotation,
.highlightAnnotation,
.popupAnnotation {
    position: absolute;
    z-index: 5;
    cursor: pointer;
    pointer-events: all
}

.annotationDiv .popupWrapper {
    position: absolute;
    width: 20em
}

.annotationDiv .popup {
    position: absolute;
    z-index: 200;
    max-width: 20em;
    background-color: #ff9;
    box-shadow: 0 2px 5px #333;
    border-radius: 2px;
    padding: .6em;
    margin-left: 5px;
    cursor: pointer;
    word-wrap: break-word
}

.annotationDiv .popup h1 {
    font-size: 1em;
    border-bottom: 1px solid #000;
    padding-bottom: .2em
}

.annotationDiv .popup p {
    padding-top: .2em
}

.annotationDiv .highlightAnnotation,
.annotationDiv .underlineAnnotation,
.annotationDiv .squigglyAnnotation,
.annotationDiv .strikeoutAnnotation,
.annotationDiv .fileAttachmentAnnotation {
    cursor: pointer
}

.annotationDiv .textAnnotation img {
    position: absolute;
    cursor: pointer
}

section.linkAnnotation a,
.annotationDiv .buttonWidgetAnnotation.pushButton>a {
    display: block;
    height: 100%
}

section.linkAnnotation a,
a.linkAnnotation,
a.customLinkAnnotation,
.customHtmlAnnotation,
.customVideoAnnotation {
    background-color: transparent;
    background-color: #ff0;
    opacity: .1
}

section.linkAnnotation a:hover,
a.linkAnnotation:hover,
.highlightAnnotation:hover,
a.customLinkAnnotation:hover {
    opacity: .4;
    border-color: transparent;
    background-color: #2196f3
}

.annotationDiv,
.textLayerDiv {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden
}

.textLayerDiv {
    line-height: 1.0
}

.textLayerDiv>div {
    position: absolute;
    white-space: pre;
    cursor: text;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0
}

section.linkAnnotation a .annotationLayer>a {
    display: block;
    position: absolute;
    height: 100%;
    cursor: pointer;
    box-sizing: border-box
}

.df-page-loading:after,
.df-page-loading:before {
    content: " ";
    height: 44px;
    width: 44px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -22px -22px
}

.df-page-loading:before {
    background-color: white;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    box-sizing: border-box
}

.df-page-loading:after {
    background-image: url("../images/loading.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px
}

.df-thumb-container .df-vrow {
    height: 124px;
    left: 0;
    width: 90%;
    margin: 10px auto;
    background-position: 50%;
    cursor: pointer;
    background-repeat: no-repeat;
    right: 0;
    border-radius: 5px
}

.df-thumb-container .df-vrow:before {
    content: "";
    background-image: url("../images/loading.gif");
    background-size: 22px;
    height: 22px;
    width: 22px;
    display: block;
    top: 60px;
    margin: 0 auto;
    position: relative;
    z-index: 0
}

.df-thumb-container .df-vrow:hover {
    background-color: #bbb
}

.df-floating .df-thumb-container .df-vrow:hover,
.df-vrow.df-selected {
    background-color: #ccc;
    opacity: 1
}

.df-thumb-container .df-vrow.df-thumb-loaded {
    background-size: contain
}

.df-thumb-container .df-vrow.df-thumb-loaded:before {
    display: none
}

.df-vrow>div {
    height: 20px;
    background-color: rgba(170, 170, 170, 0.54);
    width: 40px;
    margin: 0 auto
}

.df-sidemenu {
    float: left;
    width: 220px;
    height: auto !important;
    position: absolute !important;
    top: 0;
    z-index: 3;
    transition: .5s transform ease;
    -webkit-transition: .5s -webkit-transform ease;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    left: -50px;
    background-color: rgba(238, 238, 238, 0.9)
}

.df-rtl .df-sidemenu {
    left: auto;
    left: initial;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    right: -50px;
    direction: rtl
}

.df-thumb-container {
    text-align: center
}

.df-outline-container {
    color: #666;
    font-size: 12px
}

.df-outline-wrapper,
.df-thumb-wrapper {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto !important;
    box-shadow: 1px 0 4px rgba(102, 102, 102, 0.38)
}

.df-outline-wrapper {
    overflow: auto;
    padding: 10px 10px 5px 10px
}

.df-floating .df-outline-container,
.df-floating .df-thumb-container {
    bottom: 0
}

.df-sidemenu.df-sidemenu-visible {
    display: block;
    transform: translateX(0);
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    left: 0
}

.df-outline-toggle {
    cursor: pointer;
    float: left;
    width: 18px;
    padding: 5px 0;
    margin-left: -18px;
    text-align: center
}

.df-outline-toggle.df-outlines-hidden~.df-outline-items {
    display: none
}

.df-outline-items>.df-outline-item {
    margin-left: 18px
}

.df-outline-item a:hover,
.df-outline-toggle:hover {
    background-color: #e7e7e7
}

.df-outline-item a {
    color: inherit !important;
    text-decoration: none !important;
    display: block;
    padding: 5px 0 5px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none !important;
    box-shadow: none !important
}

.df-outline-toggle:before {
    content: "\e64b";
    font-size: 10px;
    font-family: 'themify'
}

.df-outline-toggle.df-outlines-hidden:before {
    content: "\e649"
}

.df-lightbox-wrapper {
    position: fixed !important;
    top: 0;
    bottom: 0;
    right: 0;
    height: auto !important;
    left: 0;
    background-color: white;
    z-index: 99999
}

.df-lightbox-controls {
    float: right;
    padding: 5px;
    z-index: 1000;
    position: relative
}

.df-lightbox-close {
    float: right;
    height: 40px;
    text-align: center;
    font-size: 50px;
    cursor: pointer;
    color: #444;
    border-radius: 50%;
    border-color: transparent;
    width: 40px;
    background-color: transparent;
    transition: .2s;
    -webkit-transition: .2s
}

.df-lightbox-close:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.21);
    border-color: black
}

.df-lightbox-close:before {
    top: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    font-size: 24px
}

.df-lightbox-wrapper .df-container {
    position: absolute !important;
    width: 100%;
    top: 0;
    bottom: 0;
    height: auto !important
}

.df-container.df-xs .df-ui-controls .df-ui-btn.df-ui-page {
    display: none !important
}

._df_button,
._df_thumb,
._df_custom {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    cursor: pointer
}

._df_button {
    color: white;
    font-size: 14px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    -webkit-appearance: none;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    white-space: nowrap
}

._df_thumb {
    border: 0;
    /* width: 140px;
    height: 200px; */
    text-align: center
}

._df_thumb {
    cursor: pointer;
    display: inline-block;
    -webkit-transform-style: flat;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    box-sizing: border-box;
    -webkit-transition: .2s;
    -moz-perspective: 1200px;
    perspective: 1200px
}

._df_books ._df_thumb,
._df_books .df-dummy {
    width: 140px;
    height: 200px;
    text-align: center;
    display: inline-block;
    position: relative;
    margin: 20px 10px;
    vertical-align: top
}

._df_thumb[thumbtype="img"] {
    height: auto
}

._df_thumb[thumbtype="img"] img {
    max-width: 100%;
    max-height: 200px;
    margin: 0;
    padding: 0;
    border: 0
}

._df_book-cover {
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    -webkit-transform-origin: left;
    transform-origin: left;
    top: 0;
    background-color: #eee;
    position: relative;
    overflow: hidden;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.22)
}

._df_thumb ._df_book-title {
    bottom: -100%;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 5px;
    font-size: 12px;
    background: rgba(60, 60, 60, 0.8);
    box-sizing: border-box;
    display: block;
    color: white;
    transition: .4s;
    -webkit-transition: .4s;
    opacity: 0
}

._df_thumb ._df_book-tag {
    float: left;
    padding: 0 4px 2px;
    background-color: rgba(0, 0, 0, 0.26);
    text-transform: uppercase;
    font-size: 10px;
    margin: -15px 0 0 2px;
    z-index: -1;
    color: rgba(255, 255, 255, 0.8);
    position: relative
}

._df_thumb:hover ._df_book-title,
._df_thumb-not-found ._df_book-title {
    opacity: 1;
    bottom: 0
}

._df_thumb:hover ._df_book-cover {
    -webkit-transform: rotateY(-20deg) rotateZ(0);
    -moz-transform: rotateY(-20deg) rotateZ(0);
    -ms-transform: rotateY(-20deg) rotateZ(0);
    transform: rotateY(-20deg) rotateZ(0);
    box-shadow: 3px 0 12px rgba(0, 0, 0, 0.60)
}

.df-webkit ._df_thumb:hover ._df_book-cover {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
    box-shadow: 1px 0 12px rgba(0, 0, 0, 0.60)
}

._df_books {
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 20px 5px 150px
}

._df_books-break {
    color: rgba(255, 255, 255, 0.3);
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-top: 25px;
    text-transform: uppercase
}

._df_books-break:after {
    content: " ";
    bottom: 0;
    width: 50px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.24);
    display: block;
    margin: 10px auto
}

.df-page-wrapper {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0)
}

.df-container ::-webkit-scrollbar {
    width: 8px
}

.df-container ::-webkit-scrollbar-button {
    height: 8px
}

.df-container ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border: 0 none #fff;
    border-radius: 100px
}

.df-container ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5)
}

.df-container ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.5)
}

.df-container ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.20);
    border: 0 none #fff;
    border-radius: 100px
}

.df-container ::-webkit-scrollbar-track:hover {
    background: rgba(0, 0, 0, 0.2)
}

.df-container ::-webkit-scrollbar-track:active {
    background: rgba(0, 0, 0, 0.15)
}

.df-container ::-webkit-scrollbar-corner {
    background: transparent
}

.df-skipTransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    transition: none !important
}

.df-container.df-fullscreen {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important
}

.df-container.df-double-internal .df-page-front {
    background-position: left;
    background-size: cover
}

.df-container.df-double-internal .df-page-back {
    background-position: right;
    background-size: cover
}

.customHtmlAnnotation iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important
}

.df-rtl .more-container .df-ui-btn {
    text-align: right;
    direction: rtl
}

.df-rtl .more-container .df-ui-btn:before {
    right: 10px
}

.df-rtl .more-container .df-ui-btn span {
    padding: 0 20px 0 0
}

.df-rtl .df-outline-items>.df-outline-item {
    margin: 0 18px 0 0;
    text-align: right;
    direction: rtl
}

.df-rtl .df-outline-toggle {
    float: right;
    margin: 0 -18px 0 0
}

.df-rtl .df-outline-toggle.df-outlines-hidden:before {
    content: "\e64a"
}

.df-rtl .df-sidemenu.df-sidemenu-visible {
    left: auto;
    left: initial;
    right: 0
}

.df-rtl .df-ui-sidemenu-close {
    right: auto;
    right: initial;
    left: -32px;
    border-radius: 5px 0 0 5px
}

._df_thumb[thumbtype="img"] ._df_thumb-not-found {
    height: 200px;
    position: relative
}

body.admin-bar .df-lightbox-wrapper {
    top: 32px
}

@media screen and (max-width:782px) {
    body.admin-bar .df-lightbox-wrapper {
        top: 46px
    }
}

.df-raw-loading {
    position: absolute;
    margin-top: 20px;
    margin-left: 5px;
    padding: 6px 20px;
    background-color: rgba(0, 0, 0, 0.3);
    font-style: italic;
    border-radius: 5px;
    font-size: 11px;
    max-height: 30px;
    color: #fff;
    max-width: 200px;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    left: 50%;
    top: 50%;
    opacity: .7
}

.df-raw-loading a {
    color: inherit !important
}

.df-container .df-raw-loading {
    top: 100%
}

[hidden] {
    display: none
}

html.df-lightbox-open,
body.df-lightbox-open {
    overflow: hidden !important
}

.df-custom-fullscreen.df-fullscreen[backgroundcolor='transparent'] {
    background: gray !important
}