:root {
  /* fonts */

  /* font sizes */
  --paragraph-p2-regular1-size: 14px;
  --paragraph-p1-regular-size: 16px;
  --paragraph-p3-regular-size: 13px;
  --heading-h2-medium1-size: 30px;

  /* Colors */
  --primary-50: #fdfaf4;
  --white: #fff;
  --gray-warm-gray-500-base: #78716c;
  --gray-warm-600: #57534e;
  --primary-600: #d6bb87;
  --primary-500-base: #ebcd94;
  --gray-warm-gray-900: #1c1917;
  --gray-warm-700: #44403c;
  --neutrals-6: #e6e8ec;
  --stroke-gray-stroke: #e7e5e4;

  /* Spacing */
  --number-8: 8px;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-xs: 12px;
  --br-81xl: 100px;
  --br-7xs: 6px;
  --border-radus-8: 8px;
  --br-9xs: 4px;

  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-xs1: 0px 3px 4px -5px rgba(24, 24, 28, 0.03),
    0px 1px 2px rgba(24, 24, 28, 0.04);
}

.ph3 {
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 67px 30px rgba(0, 0, 0, 0.09);
  transition: all .3s ease-out;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  zoom: .8;






  .header-map-popup-section {
    display: flex;
    // width: 572px;
    padding: 20px 20px 10px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 8px 0 0;
    border-bottom: 1px solid #E7E5E4;
    background: #FFF;

    i {
      font-size: 25px !important;
    }

    .header-map-popup-section-left {
      position: relative;
      display: flex;
      padding: 0px;
      align-items: flex-start;
      gap: 12px;

      .header-map-popup-section-icon {
        display: flex;
        padding: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #E7E5E4;
        background: #FAFAF9;
      }

      .non-active {
        border: 1px solid #E7E5E4;
        background: #FAFAF9;
        color: #78716C;

      }

      .active {
        border: 1px solid var(--secondary-500-base, #039154);
        color: #039154;
        background: var(--secondary-50, #E6F4EE);
      }

    }

    .header-map-popup-section-right-stop {
      display: flex;
      padding: 4px 12px 4px 10px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;

    }

    .header-map-popup-section-right-start {
      display: flex;
      padding: 4px 12px 4px 10px;
      align-items: center;
      gap: 4px;
      border-radius: var(--number-8-px, 8px);
      border: 1px solid var(--secondary-500-base, #039154);
      background: var(--secondary-50, #E6F4EE);
    }



  }


  .details-map-popup-section {
    display: flex;
    padding: 16px 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 0px;
    background: #FFF;



    .details-map-popup-section-alert {
      display: flex;
      width: 98%;
      padding: 8px 12px;
      align-items: flex-start;
      gap: 12px;
      border-radius: 6px;
      // background: #FFF;

      border-radius: 5px;
      border: 1px solid var(--accent-warning-200, #FBCC8D);
      background: var(--accent-warning-50, #FEF4E6);

      .alert-icon {
        display: flex;
        padding-top: 3px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 0px;
      }

      .alert-text {
        display: flex;
        // width: 480px;
        padding-bottom: 0px;
        align-items: center;
        border-radius: 0px;
        text-align: right;
        color: #AF6606;
        word-wrap: break-word;
        /* Paragraph/p3/Regular */

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 138.462% */
        letter-spacing: -0.13px;

      }
    }

    .details-map-popup-section-plot-camp {
      display: flex;
      padding: 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;



      .details-plot {
        display: flex;
        padding: var(--number-0-px, 0px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--number-16-px, 14px);
        align-self: stretch;

        .details-plot-name {
          display: flex;
          padding: 0px;
          align-items: center;
          gap: 12px;
          text-align: right;

          .plot-name {
            display: flex;
            padding: 0px;
            align-items: center;
            gap: 10px;
          }

          .plot-number {
            display: inline;


            /* Heading/h5/Medium */

            // font-size: 18px;
            // font-style: normal;
            // font-weight: 800;
            // line-height: 26px;
            // letter-spacing: -0.18px;
          }

          .avalaible {
            background: var(--secondary-50, #E6F4EE);
            color: var(--accent-success-500-base, #02B37D);
          }

          .unavalaible {
            background: var(--secondary-50, #FBC5C1);
            color: var(--accent-success-500-base, #F14437);
          }

          .plot-status {
            display: flex;
            height: 24px;
            padding: 10px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;

            border-radius: 100px;
            mix-blend-mode: multiply;


            .plot-status-text {

              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              /* 166.667% */
              letter-spacing: -0.06px;


            }



          }



        }


        .details-plot-information {
          display: flex;
          padding: 0px;
          align-items: center;
          // gap: 25px;
          align-self: stretch;
          padding-right: 5px;
          flex-wrap: wrap;

          .plot-information-col {
            display: flex;
            padding: 2px 10px;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;

            .info-col-up {
              color: #78716C !important;

              // font-size: 16px;
              // font-style: normal;
              // font-weight: 400;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }

            .info-col-down {
              color: #000;

              // font-size: 16px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }


            .d2 {
              border: 1px solid #0E95D7;
              background: #E7F6FD;
              color: #0E95D7;
              cursor: pointer;
            }

            .d3 {
              border: 1px solid var(--secondary-500-base, #039154);
              background: var(--secondary-50, #E6F4EE);
              color: #039154;
              cursor: pointer;
            }

          }
        }
      }


      .details-camp {
        width: 100%;
        height: 51vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          border-radius: 8px;
          border: 1px solid #E7E5E4;
        }

        .info-selected {
          border-radius: 8px;
          border: 1px solid #EBCD94;
        }

        .info-selected-booked {
          border-radius: 8px 8px 0px 0px;
          border: 1px solid var(--accent-blue-50, #0E95D7);
        }

        .info-unselected-unbooked {
          border-radius: 8px 8px 0px 0px;
          border: 1px solid var(--accent-blue-50, #F14437);
        }

        .info-header-selected {
          border-radius: 0;
          border: 1px solid #EBCD94;
        }

        .mat-panel-title-camp {
          display: flex;
          padding: var(--number-0-px, 0px);
          align-items: center;
          gap: var(--number-8-px, 8px);

          .text {
            color: var(--text-body-text-2, #78716C);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.14px;
          }

          .camp {
            // color: var(--text-body-text, #44403C);
            // font-size: 16px;
            // font-style: normal;
            // font-weight: 500;
            // line-height: 20px;
            // letter-spacing: -0.14px;
            display: inline;
          }
        }


        .details-camp-plot-information {
          display: flex;
          flex-wrap: wrap;
          padding: 0px;
          align-items: center;
          // justify-content: space-around;
          // gap: 40px;
          align-self: stretch;
          margin-top: 15px;


          .plot-camp-information-col {
            display: flex;
            padding: 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;
            margin-right: 8%;
            margin-bottom: 2%;

            .info-col-up {
              color: #78716C !important;

              // font-size: 16px;
              // font-style: normal;
              // font-weight: 400;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }

            .info-col-down {
              color: #000;

              // font-size: 16px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }

          }
        }



      }


    }


    .access_path_v2 {
      display: flex;
      padding: var(--number-16-px, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--number-20-px, 20px);
      align-self: stretch;

      .container {
        display: flex;
        padding: var(--number-0-px, 0px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--number-4-px, 4px);
        align-self: stretch;

        .col-access {
          display: flex;
          padding: var(--number-0-px, 0px);
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          .col-access-left {
            display: flex;
            padding: var(--number-0-px, 0px);
            align-items: center;
            gap: var(--number-12-px, 12px);

            .text {
              color: var(--text-body-text-2, #78716C);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.14px;
            }
          }

          .col-access-right {
            color: var(--text-heading-text, #1C1917);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.14px;
          }



        }

        .dot {
          display: flex;
          width: 12px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2px;
        }
      }
    }

  }


  .action-popups-section {
    display: flex;
    padding: 16px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 0 0 8px 0;
    border-top: 1px solid #E7E5E4;
    background: #FFF;

    .section-button {
      display: flex;
      align-items: flex-start;
    }

    .action-popup-section-canacel {
      width: 88px;

      display: flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 100px;
      border: 1px solid #D6D3D1;
      background: #FFF;
      cursor: pointer;

    }

    .action-popup-section-booking {
      width: 160px;
      display: flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      border-radius: 100px;

      text-align: center;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: -0.07px;
      cursor: pointer;

    }

    .error-selected {
      border: 1px solid #D6D3D1;
      background: #FFF;
    }

    .non-selected {
      background: #F9F0DE;
      color: #A79269;
    }

    .selected {
      background: var(--primary-500-base, #EBCD94);
      color: var(--gray-warm-gray-900, #1C1917);
    }

    .selected-booked {
      background: #F9F0DE;
      color: #A79269;
    }

    .action-popup-section-start {
      width: 148px;
      display: flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      border-radius: 100px;
      background: #EBCD94;

      color: #1C1917;
      text-align: center;


      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: -0.07px;
      cursor: pointer;
    }

  }


  .col-details-add {
    width: 33%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
  }


  .successfully-section {
    display: flex;
    height: 470px;
    padding: var(--number-16-px, 16px) var(--number-20-px, 20px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--number-12-px, 12px);
    flex-shrink: 0;
    align-self: stretch;

    .successfully-icon {
      display: flex;
      padding: var(--number-12-px, 12px);
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 24px;
      background: var(--secondary-50, #E6F4EE);

      img {
        width: 61px;
        height: 60px;
      }
    }

    .error-icon {
      display: flex;
      padding: var(--number-12-px, 12px);
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 24px;
      background: var(--secondary-50, #FEECEB);

      img {
        width: 61px;
        height: 60px;
      }
    }

    .successfully-message {
      display: flex;
      padding: var(--number-0-px, 0px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--number-8-px, 8px);

      .header-message {
        color: var(--gray-warm-gray-900, #1C1917);
        leading-trim: both;
        text-edge: cap;

        /* Heading/h5/Medium */

        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        /* 144.444% */
        // letter-spacing: -0.18px;
      }

      .content-message {
        color: var(--gray-warm-600, #57534E);
        text-align: center;
        leading-trim: both;
        text-edge: cap;

        /* Paragraph/p2/Table */

        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 43px;
        /* 142.857% */
        letter-spacing: -0.14px;
      }
    }

  }

}

.color-meaning-ph3 {
  position: fixed;
  top: 70px;
  z-index: 800;
  left: 0;
  transition: all .3s ease-out;
  display: flex;
  gap: 8px;
  height: 30px;
  width: 76%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 20px;

  .color-meaning-right {

    .map-filters {
      background: #fff;
      -webkit-transition: all .2s ease;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.19), 0 3px 6px rgba(0, 0, 0, 0);
      transition: all .2s ease;
      // position: absolute;
      // top: 125px;
      // right: 380px;
      // z-index: 999;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      height: auto;

      .close-filter {
        width: 145px;
        height: 30px;
        font-size: 12px !important;
        display: flex;
        gap: 8px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.19), 0 3px 6px rgba(0, 0, 0, 0);


        .header-flex {
          display: flex;
          padding: 2px 13px;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 11px;

        }

        i {
          font-size: 13px !important;
        }


      }


      .forms-filter {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .col-forms-filter {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 0;
          text-align: right;

          label {
            color: var(--text-body-text-2, #78716C);
            text-align: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: -0.16px;
          }

          .slider-ranger {
            margin-top: 40px;
          }
        }
      }

      .dropdown-menu {
        border-radius: var(--number-8-px, 8px);
        border-top: 1px solid var(--stroke-gray-stroke, #E7E5E4);
        border-right: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #E7E5E4);
        border-bottom: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #E7E5E4);
        border-left: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #E7E5E4);
        opacity: var(--background-map, 1);
        background: var(--White, #FFF);

        /* Map */
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
      }

    }

  }


  .color-meaning-left {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .container-color-meaning {
      display: flex;
      flex-direction: row;
      height: var(--number-32-px, 32px);
      padding: 6px 10px;
      justify-content: flex-end;
      align-items: center;
      gap: var(--number-8-px, 8px);
      border-radius: 6px;
      border: 1px solid var(--gray-true-300, #D4D4D4);
      background: var(--background-background, #FFF);

      /* Map */
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

      .dot {
        display: flex;
        padding: var(--number-0-px, 0px);
        align-items: flex-start;
        gap: 10px;
      }

      .text-color-meaning {
        color: var(--text-body-text, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: -0.14px;
      }
    }

  }

  .color-meaning-left-v2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    left: 0;
    gap: 8px;

    .container-color-meaning {
      display: flex;
      flex-direction: row;
      height: var(--number-32-px, 32px);
      padding: 6px 10px;
      justify-content: flex-end;
      align-items: center;
      gap: var(--number-8-px, 8px);
      border-radius: 6px;
      border: 1px solid var(--gray-true-300, #D4D4D4);
      background: var(--background-background, #FFF);

      /* Map */
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

      .dot {
        display: flex;
        padding: var(--number-0-px, 0px);
        align-items: flex-start;
        gap: 10px;
      }

      .text-color-meaning {
        color: var(--text-body-text, #44403C);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: -0.14px;
      }
    }

  }


  .non-selected {
    border: 3px solid var(--gray-true-300, #D4D4D4);
  }

  .selected {
    border: 3px solid var(--gray-true-300, #EBCD94);
  }

}










@media (min-height: 1000px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 60vh;
  }
}

@media (min-height: 1030px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 62vh;
  }
}

@media (min-height: 1060px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 64vh;
  }
}

@media (min-height: 1090px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 66vh;
  }
}

@media (min-height: 1120px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 68vh;
  }
}

@media (min-height: 1150px) {
  .ph3 .details-map-popup-section .details-map-popup-section-plot-camp .details-camp {
    height: 70vh;
  }
}
