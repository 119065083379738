/* Widget */
.widget {
  border-radius: var(--Number-8-px, 8px);
  opacity: var(--Background-MAP, 1);
  background: var(--bs-body-secondry);

  /* Card */
  box-shadow: 0px 1px 4px -1px rgba(50, 50, 71, 0.05);

  .widget-body {
    padding: 0 40px 30px;
  }

  .widget-heading {
    padding: 20px 40px;
  }

  .widget-title {
    font-size: 19px;
  }

  .page-content-fixed .widget-title {
    font-weight: bold;
    font-size: 23px;
  }

  .page-content-fixed h3.widget-title {
    font-size: 20px;
  }

  .widget-title.dropdown-toggle {
    cursor: pointer;
    display: inline-block;
    padding: 0 15px;
    transition: all 0.2s ease;
    border-radius: 5px;
  }

  .widget-title-dropdown {
    margin-right: -15px;
  }

  .widget-title-dropdown .dropdown-item {
    font-size: 15px;
    padding: 8px 15px;
  }

  .widget-title-dropdown .dropdown-menu {
    width: 220px;
    margin-top: 5px;
  }

  .widget-title.dropdown-toggle:hover,
  .open .widget-title.dropdown-toggle {
    background: var(--light-bg);
  }

  .widget-title:after {
    font-size: 10px;
    margin-right: 10px;
  }

  .widget-tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-x: auto;
  }

  .widget-tabs li {
    padding: 6px 0;
    min-width: 65px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.15s ease;
    width: 95px;
    text-align: center;
    color: var(--gray-lighter);
    font-weight: bold;
  }

  [dir="ltr"] .widget-tabs li {
    padding: 6px 15px;
    width: auto;
  }

  .widget-tabs li:not(.active):hover {
    color: var(--primary);
  }

  .widget-tabs li.active {
    color: var(--gray-base) !important;
    background: var(--light-bg);
  }

  .widget-tabs li:not(.active):hover {
    background: #f6f8fa;
    color: var(--gray-lighter) !important;
  }

  .widget-table-heading {
    padding: 10px 30px 10px;
    border-bottom: 1px solid var(--light-border);
    font-weight: bold;
    color: var(--gray-lighter);
  }

  .widget-table-body {
    padding: 0 0 25px;
  }

  .widget-table-body>.dot-loader {
    margin: 50px auto 30px;
  }

  .widget-table-body .widget-table-item:not(:last-child) {
    border-bottom: 1px solid var(--light-border);
  }

  .widget-table-item {
    padding: 20px 30px;
    font-weight: bold;
    transition: background 0.2s ease;
  }

  .widget-table-item-sm {
    padding: 10px 30px;
  }

  .widget-table-item:hover {
    background: #f5f7fa;
  }

  .widget-table-item-title {
    font-weight: bold;
    color: var(--primary);
    text-decoration: none !important;
    transition: all 0.15s ease;
    cursor: pointer;
  }

  .widget-table-item-sm .widget-table-item-title {
    font-size: 14px !important;
  }

  [dir="rtl"] .widget-table-item-title {
    font-size: 16px;
  }

  .widget-table-item:hover .btn-outline-primary {
    border-color: var(--primary);
    background: var(--primary);
    color: #fff;
  }

  .widget-table-item:hover .btn-outline-primary:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
  }

  .widget-table-item:hover .btn-outline-primary:active {
    color: #fff;
    background-color: #048996;
    border-color: #048996;
  }

  .widget-table-item:hover .progress-circle:before {
    left: 8px;
    right: 8px;
    top: 8px;
    bottom: 8px;
  }

  .widget-datatable {
    height: 75vh;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .table-system {
    height: 54vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .widget-datatable .dt-buttons {
    position: absolute;
    left: 0;
    top: -50px;
  }

  .widget-datatable .widget-table-item-title:hover {
    color: #037480;
  }

  .widget-datatable .widget-table-item-title {
    font-size: 15px;
  }

  .widget-datatable .widget-table-body {
    padding: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    border-radius: 10px;
    background: var(--bs-body-secondry) !important;
    top: 0;
  }

  .widget-table-item .progress {
    transition: all 0.2s ease;
  }

  .widget-table-item .progress:hover {
    background: #e7edf3;
  }
}

/* Scrollbar */
body *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: var(--bs-white);
}

body *::-webkit-scrollbar-thumb {
  background-color: #f2deb7;
}

body *::-webkit-scrollbar-thumb:hover {
  background-color: var(--default-border);
}

body::-webkit-scrollbar {
  width: 10px;
  background: var(--bs-white);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--light-border);
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--default-border);
}

/* Switch */
.ui-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  margin: 2px 0 0;
}

.ui-switch input {
  display: none;
}

.ui-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  border-radius: 5px;
  right: 0;
  bottom: 0;
  background-color: #f5f8f9;
  border: 1px solid #c7cace;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.ui-switch-slider:before {
  position: absolute;
  content: "";
  height: 29px;
  width: 28px;
  border-radius: 4px;
  right: -1px;
  border: 1px solid #c7cace;
  bottom: -1px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-family: "tafweej";
  text-align: center;
  line-height: 29px;
  content: "\e924";
  font-size: 5px;
  box-shadow: 0 2px 5px #00000026;
  color: #fff;
}

input:checked+.ui-switch-slider {
  background-color: var(--primary);
  border-color: var(--primary);
}

input:checked+.ui-switch-slider:before {
  font-size: 14px;
  color: var(--primary);
  border-color: var(--primary);
}

input:focus+.ui-switch-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.ui-switch-slider:before {
  -webkit-transform: translateX(-32px);
  -ms-transform: translateX(-32px);
  transform: translateX(-32px);
}

.modal-body {
  min-height: 320px;
}

.page-container {
  margin-top: 40px;
}

.widget .overflow-hidden {
  overflow: hidden;
}
