.min-w-auto {
  min-width: auto;
}

.min-width-fill-available {
  min-width: fill-available;
}

.min-width-40px {
  min-width: 40px;
}

.min-width-50px {
  min-width: 50px;
}

.min-width-80px {
  min-width: 80px;
}

.min-width-100px {
  min-width: 100px;
}

.min-width-120px {
  min-width: 120px;
}

.min-width-150px {
  min-width: 150px !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-h-50px {
  min-height: 50px;
}

.min-height-80px {
  min-height: 80px;
}

.min-height-300px {
  min-height: 300px;
}

.min-height-100vh {
  min-height: 100vh;
}

.height-10px {
  height: 10px;
}

.w-md-100 {
  @media (min-width: 768px) {
    width: 100% !important;
  }
}

.w-sm-auto {
  @media (min-width: 575px) {
    width: auto !important;
  }
}

.w-90 {
  width: 90%;
}

.w-85 {
  width: 85%;
}

.w-80 {
  width: 80%;
}

.w-75 {
  width: 75%;
}

.w-60 {
  width: 60%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-15px {
  width: 15px;
}

.w-20px {
  width: 20px;
}

.w-22px {
  width: 22px;
}

.w-25px {
  width: 25px;
}

.w-30px {
  width: 30px;
}

.w-35px {
  width: 35px;
}

.w-50px {
  width: 50px;
  min-width: 50px;
}

.w-40px {
  width: 40px;
  min-width: 40px;
}

.w-60px {
  width: 60px;
}

.w-70px {
  width: 70px;
}

.w-75px {
  width: 75px;
}

.w-80px {
  width: 80px;
}

.w-100px {
  width: 100px !important;
}

.w-150px {
  width: 150px !important;
}

.w-md-auto {
  @media (min-width: 768px) {
    width: auto;
  }
}

.max-w-lg-200 {
  @media (min-width: 992px) {
    max-width: 200px;
  }
}

.max-w-initial {
  max-width: initial !important;
}

.padding-empty-state {
  padding: 60px 0;
}

.h-20px {
  height: 20px;
}

.h-25px {
  height: 25px;
}

.h-30px {
  height: 30px;
}

.h-100px {
  height: 100px;
}

.h-max-auto {
  max-height: none !important;
}

.w-max-content {
  width: max-content;
}

.min-w-max-content {
  min-width: max-content;
}
