:root {
  /* fonts */

  /* font sizes */
  --paragraph-p2-regular1-size: 14px;
  --paragraph-p1-regular-size: 16px;
  --paragraph-p3-regular-size: 13px;
  --heading-h2-medium1-size: 30px;

  /* Colors */
  --primary-50: #fdfaf4;
  --white: #fff;
  --gray-warm-gray-500-base: #78716c;
  --gray-warm-600: #57534e;
  --primary-600: #d6bb87;
  --primary-500-base: #ebcd94;
  --gray-warm-gray-900: #1c1917;
  --gray-warm-700: #44403c;
  --neutrals-6: #e6e8ec;
  --stroke-gray-stroke: #e7e5e4;

  /* Spacing */
  --number-8: 8px;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-xs: 12px;
  --br-81xl: 100px;
  --br-7xs: 6px;
  --border-radus-8: 8px;
  --br-9xs: 4px;

  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-xs1: 0px 3px 4px -5px rgba(24, 24, 28, 0.03),
    0px 1px 2px rgba(24, 24, 28, 0.04);
}

.ph1 {
  width: 100%;
  height: 100vh;
  background-color: var(--bs-body-bg);
  box-shadow: 0 67px 30px rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .header-sidebar {
    display: flex;
    align-items: flex-start;
    gap: var(--number-0-px, 0px);
    align-self: stretch;

    .text-header {
      padding: 18px 24px;
      /* Heading/h3/Medium */
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      // line-height: 32px;
      /* 133.333% */
      letter-spacing: -0.24px;
    }
  }

  .line-hr {
    border-bottom: 1px solid #e7e5e4;
  }

  .action-popups-section {
    display: flex;
    padding: 10px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 0 0 8px 0;
    // border-top: 1px solid #E7E5E4;
    background: #fff;

    .section-button {
      display: flex;
      align-items: flex-start;
    }

    .action-popup-section-booking {
      width: 148px;
      display: flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      border-radius: 100px;

      text-align: center;

      font-weight: bold;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: -0.07px;
      cursor: pointer;
      background: var(--primary-500-base, #ebcd94);
      color: var(--gray-warm-gray-900, #1c1917) !important;
    }
  }

  $max-tab-count: 5;
  $tab-wrap-border-radius: 6px;

  .tab-wrap {

    h1,
    h2 {
      margin: 0;
      color: #444;
      text-align: center;
      font-weight: 400;
    }

    h2 {
      font-size: 1em;
      margin-bottom: 30px;
    }

    h3 {
      font-weight: 400;
    }

    p {
      line-height: 1.6;
      margin-bottom: 20px;
    }

    transition: 0.3s box-shadow ease;
    border-radius: $tab-wrap-border-radius;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    list-style: none;
    background-color: #fff;
    padding: 6px 8px;

    // &:hover {
    //     box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
    // }
  }

  .tab_button_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab {
    display: none;

    // @for $i from 1 through $max-tab-count {
    //   &:checked:nth-of-type(#{$i}) ~ .tab__content:nth-of-type(#{$i}) {
    //     opacity: 1;
    //     transition: 0.5s opacity ease-in, 0.8s transform ease;
    //     position: relative;
    //     top: 0;
    //     z-index: 100;
    //     text-shadow: 0 0 0;
    //     height: auto;
    //     // overflow-y: auto;
    //   }
    // }

    &:first-of-type:not(:last-of-type)+label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-of-type):not(:last-of-type)+label {
      border-radius: 0;
    }

    &:last-of-type:not(:first-of-type)+label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    // &:checked + label {
    //   background-color: #ebcd94; // tap color
    //   box-shadow: 0 -1px 0 #fff inset;
    //   cursor: default;
    // }

    +label {
      box-shadow: 0 -1px 0 #eee inset;
      border-radius: $tab-wrap-border-radius;
      cursor: pointer;
      display: block;
      text-decoration: none;
      color: #333;
      flex-grow: 3;
      text-align: center;
      // background-color: #f2f2f2;
      user-select: none;
      text-align: center;
      transition: 0.3s background-color ease, 0.3s box-shadow ease;
      height: 50px;
      box-sizing: border-box;
      padding: 10px;

      &:hover {
        background-color: #ebcd94;
        box-shadow: 0 -1px 0 #f4f4f4 inset;
      }
    }
  }

  .selected {
    background-color: #ebcd94; // tap color
    box-shadow: 0 -1px 0 #fff inset;
    cursor: default;
  }

  .tab__content {
    opacity: 1;
    transition: 0.5s opacity ease-in, 0.8s transform ease;
    position: relative;
    top: 0;
    z-index: 100;
    text-shadow: 0 0 0;
    height: 23vh;
    padding: 10px 2px;
    width: 100%;
    border-radius: $tab-wrap-border-radius;
    overflow-y: auto;
  }

  .details-map-popup-section {
    display: flex;
    padding: 16px 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 0px;
    background: #fff;
    zoom: 0.8;
    padding: 14px;

    .details-map-popup-section-plot-camp {
      display: flex;
      padding: 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      .details-plot {
        display: flex;
        padding: var(--number-0-px, 0px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--number-16-px, 14px);
        align-self: stretch;

        .details-plot-name {
          display: flex;
          padding: 0px;
          align-items: center;
          gap: 12px;
          text-align: right;

          .plot-name {
            display: flex;
            padding: 0px;
            align-items: center;
            gap: 10px;
          }

          .plot-number {
            color: #44403c;

            /* Heading/h5/Medium */

            // font-size: 18px;
            // font-style: normal;
            // font-weight: 800;
            // line-height: 26px;
            // letter-spacing: -0.18px;
          }

          .avalaible {
            background: var(--secondary-50, #e6f4ee);
            color: var(--accent-success-500-base, #02b37d);
          }

          .unavalaible {
            background: var(--secondary-50, #fbc5c1);
            color: var(--accent-success-500-base, #f14437);
          }

          .plot-status {
            display: flex;
            height: 24px;
            padding: 10px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;

            border-radius: 100px;
            mix-blend-mode: multiply;

            .plot-status-text {
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              /* 166.667% */
              letter-spacing: -0.06px;
            }
          }
        }

        .details-plot-information {
          display: flex;
          padding: 0px;
          align-items: center;
          // gap: 25px;
          align-self: stretch;
          padding-right: 5px;
          flex-wrap: wrap;

          .plot-information-col {
            display: flex;
            padding: 2px 10px;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;


            .info-col-up {
              color: #78716c !important;

              // font-size: 14px;
              // font-style: normal;
              // font-weight: 400;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }

            .info-col-down {
              color: #000;

              // font-size: 14px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }

            .d2 {
              border: 1px solid #0e95d7;
              background: #e7f6fd;
              color: #0e95d7;
              cursor: pointer;
            }

            .d3 {
              border: 1px solid var(--secondary-500-base, #039154);
              background: var(--secondary-50, #e6f4ee);
              color: #039154;
              cursor: pointer;
            }
          }
        }
      }

      .details-camp {
        width: 100%;
        height: 80vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          border-radius: 8px;
          border: 1px solid #e7e5e4;
        }

        .info-selected {
          border-radius: 8px;
          border: 1px solid #ebcd94;
        }

        .info-selected-booked {
          border-radius: 8px 8px 0px 0px;
          border: 1px solid var(--accent-blue-50, #0e95d7);
        }

        .info-unselected-unbooked {
          border-radius: 8px 8px 0px 0px;
          border: 1px solid var(--accent-blue-50, #f14437);
        }

        .info-header-selected {
          border-radius: 0;
          border: 1px solid #ebcd94;
        }

        .mat-panel-title-camp {
          display: flex;
          padding: var(--number-0-px, 0px);
          align-items: center;
          gap: var(--number-8-px, 8px);

          .text {
            color: var(--text-body-text-2, #78716c);
            leading-trim: both;
            text-edge: cap;

            /* Paragraph/p2/Table */

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.14px;
          }

          .camp {
            display: inline;

            /* Paragraph/p2/Medium */

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.14px;
          }
        }

        .details-camp-plot-information {
          display: flex;
          flex-wrap: wrap;
          padding: 0px;
          align-items: center;
          // justify-content: space-around;
          // gap: 40px;
          align-self: stretch;
          margin-top: 15px;

          .plot-camp-information-col {
            display: flex;
            padding: 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;
            margin-left: 5%;
            margin-bottom: 2%;

            .info-col-up {
              color: #78716c !important;

              // font-size: 14px;
              // font-style: normal;
              // font-weight: 400;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }

            .info-col-down {
              color: #000;

              // font-size: 14px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }
          }
        }
      }
    }

    .access_path_v2 {
      display: flex;
      padding: var(--number-16-px, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--number-20-px, 20px);
      align-self: stretch;

      .container {
        display: flex;
        padding: var(--number-0-px, 0px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--number-4-px, 4px);
        align-self: stretch;

        .col-access {
          display: flex;
          padding: var(--number-0-px, 0px);
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          .col-access-left {
            display: flex;
            padding: var(--number-0-px, 0px);
            align-items: center;
            gap: var(--number-12-px, 12px);

            .text {
              color: var(--text-body-text-2, #78716c);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.14px;
            }
          }

          .col-access-right {
            color: var(--text-heading-text, #1c1917);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.14px;
          }
        }

        .dot {
          display: flex;
          width: 12px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2px;
        }
      }
    }
  }
}

.color-meaning-ph1 {
  position: relative;
  top: 10px;
  z-index: 800;
  left: 0;
  transition: all 0.3s ease-out;
  display: flex;
  gap: 8px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  .color-meaning-right {
    display: flex;

    .map-filters {
      display: flex;
      width: 200px;
      padding: var(--number-0-px, 0px);
      justify-content: flex-end;
      align-items: flex-start;
      gap: var(--number-0-px, 0px);
      border-radius: var(--number-8-px, 8px);
      opacity: var(--background-map, 1);
      cursor: pointer;
      /* Map */
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

      .close-filter {
        display: flex;
        padding: var(--number-8-px, 8px) var(--number-16-px, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--number-8-px, 8px);
        flex: 1 0 0;
        border-radius: var(--number-8-px, 8px);
        opacity: var(--background-map, 1);
        background: var(--default-white, #fff);
        border: var(--Number-0-px, 1px) solid var(--Gray-Warm-300, #d6d3d1);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
      }

      .close-filter:hover {
        box-shadow: 0px 0px 0px 4px #f9f0de,
          0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-top: 1px solid var(--stroke-primary-stroke, #d6bb87);
        border-right: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
        border-bottom: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
        border-left: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
      }

      .forms-filter {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .col-forms-filter {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 0;
          text-align: right;

          label {
            color: var(--text-body-text-2, #78716c);
            text-align: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: -0.16px;
          }

          .slider-ranger {
            margin-top: 40px;
          }
        }
      }

      .dropdown-menu {
        border-radius: var(--number-8-px, 8px);
        border-top: 1px solid var(--stroke-gray-stroke, #e7e5e4);
        border-right: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
        border-bottom: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
        border-left: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
        opacity: var(--background-map, 1);
        background: var(--White, #fff);

        /* Map */
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
      }
    }
  }

  .color-meaning-left {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-left: 40px;

    .container-color-meaning {
      display: flex;
      flex-direction: row;
      height: var(--number-32-px, 32px);
      padding: 6px 10px;
      justify-content: flex-end;
      align-items: center;
      gap: var(--number-8-px, 8px);
      border-radius: 6px;
      // border: 1px solid var(--gray-true-300, #D4D4D4);
      background: var(--background-background, #fff);

      /* Map */
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

      .dot {
        display: flex;
        padding: var(--number-0-px, 0px);
        align-items: flex-start;
        gap: 10px;
      }

      .text-color-meaning {
        color: var(--text-body-text, #44403c);
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: -0.14px;
      }
    }
  }

  .non-selected {
    border: 3px solid var(--gray-true-300, #d4d4d4);
  }

  .selected {
    border: 3px solid var(--gray-true-300, #ebcd94);
  }
}

.popup-mouse-in {
  position: relative;
  background-color: white;
  top: 30px;
  right: 10px;
  z-index: 600;
  left: 0;
  transition: all 0.3s ease-out;
  display: flex;
  gap: 8px;
  height: auto;
  width: 250px;
  display: flex;
  padding: 15px 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

}

.table-price {
  table-layout: inherit;
  // word-wrap: break-word;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;

  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  zoom: 0.8;

  td {
    height: 56px;
    width: 30px !important;
    padding: var(--number-0-px, 0px) var(--number-16-px, 16px) var(--number-0-px, 0px) 16px;

    color: var(--text-heading-text, #1c1917);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.18px;

    i {
      font-weight: 100;
    }
  }

  .main-td {
    width: 287px !important;
    text-align: right !important;
  }

  .th-col {
    display: flex;
    padding: var(--number-0-px, 0px);
    flex-direction: column;
    align-items: center;
    gap: var(--number-12-px, 12px);
    margin-bottom: 24px;

    .title {
      color: var(--text-heading-text, #1c1917);
      font-size: 18px;
      font-style: normal;
      font-weight: bold;
      line-height: 26px;
      letter-spacing: -0.18px;
    }

    .price {
      width: 146px;
      height: 58px;
      display: flex;
      padding: 15px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: var(--default-white, #fff);

      .price-title {
        display: flex;
        padding: 3.5px var(--number-0-px, 0px);
        justify-content: center;
        align-items: center;
        gap: 10px;

        color: var(--secondary-500-base, #039154);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 150% */
        letter-spacing: -0.16px;
      }
    }
  }

  .category-info {
    display: flex;
    padding: var(--number-0-px, 0px);
    align-items: center;
    gap: var(--number-16-px, 16px);
    justify-content: right;

    .title {
      color: var(--text-heading-text, #1c1917);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      /* 140% */
      letter-spacing: -0.2px;
    }
  }

  .category-info {
    display: flex;
    padding: var(--number-0-px, 0px);
    align-items: center;
    gap: var(--number-16-px, 16px);
    justify-content: right;

    .title {
      color: var(--text-heading-text, #1c1917);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      /* 140% */
      letter-spacing: -0.2px;
    }
  }
}

.dropdown-map-filter {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--number-0-px, 0px);
  width: 590px;

  .dropdown-map-filter-header {
    display: flex;
    padding: 10px var(--number-16-px, 16px);
    justify-content: space-between;
    align-items: center;
    gap: var(--number-8-px, 8px);
    align-self: stretch;
    border-radius: var(--number-8-px, 8px) var(--number-8-px, 8px) var(--number-0-px, 0px) var(--number-0-px, 0px);
    border-right: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
    border-bottom: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
    border-left: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
    opacity: var(--background-map, 1);
    background: var(--default-white, #fff);

    .text {
      color: var(--text-body-text, #44403c);
      text-align: right;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      /* 144.444% */
      letter-spacing: -0.18px;
    }
  }

  .line {
    height: var(--background-map, 1px);
    align-self: stretch;
    border-radius: var(--number-0-px, 0px);
    opacity: var(--background-map, 1);
    background: var(--stroke-gray-stroke, #e7e5e4);
  }

  .labels-fomrs {
    display: flex;
    padding: var(--number-0-px, 0px);
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 5px;

    .text {
      color: var(--text-body-text-2, #78716c);
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
      letter-spacing: -0.16px;
    }

    .reset {
      color: var(--text-body-text-4, #d6d3d1);
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
      letter-spacing: -0.16px;
      cursor: pointer;
    }
  }
}

.bindTooltip {
  font-size: 11px !important;
}

.leaflet-popup-content {
  width: 300px !important;
}

.leaflet-popup-pane {
  z-index: 1450 !important;
}


.side-color {
  position: absolute;
  background-color: white;
  top: 77%;
  right: 10px;
  z-index: 700;
  left: 0;
  display: flex;
  gap: 8px;
  height: 180px;
  width: 94%;
  display: flex;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
}













@media (max-width: 1340px) {
  .color-meaning-ph1 {
    padding: 0 30px;

    .color-meaning-left {
      display: none;
    }

    .color-meaning-right {
      .map-filters {
        .forms-filter {
          .col-forms-filter {
            label {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  $max-tab-count: 5;
  $tab-wrap-border-radius: 6px;

  .ph1 {
    .header-sidebar {
      .text-header {
        color: #1c1917;
        padding: 8px 24px;
        /* Heading/h3/Medium */
        font-size: 16px;
      }
    }

  }

  .dropdown-map-filter {
    width: 225px;

    .dropdown-map-filter-header {
      .text {
        font-size: 16px;
      }
    }

    .labels-fomrs {
      .text {
        font-size: 12px;
      }

      .reset {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 860px) {
  .ph1 .action-popups-section {
    display: flex;
    padding: 16px 0;
    justify-content: center;
    align-items: center;

    .action-popup-section-booking {
      width: 125px;
      display: flex;
      padding: 11px 7px;
      line-height: 24px;
      letter-spacing: -0.07px;
      font-size: 13px;
    }
  }
}

@media (max-width: 600px) {
  .color-meaning-ph1 {
    width: 40px;
    padding: 0 10px;

    .color-meaning-right .map-filters {
      width: 100px;

      .close-filter {
        span {
          display: none;
        }

        img {
          display: none;
        }
      }
    }
  }

  .ph1 {
    .action-popups-section {
      padding: 10px 20px;
      justify-content: flex-start;
    }

    .tab-wrap {
      height: 45vh;
    }
  }
}



@media (min-height: 1000px) {
  .ph1 {
    .tab__content {
      height: 31vh;
    }
  }
}

@media (min-height: 1030px) {
  .ph1 {
    .tab__content {
      height: 32vh;
    }
  }
}

@media (min-height: 1060px) {
  .ph1 {
    .tab__content {
      height: 33vh;
    }
  }
}

@media (min-height: 1090px) {
  .ph1 {
    .tab__content {
      height: 34vh;
    }
  }
}

@media (min-height: 1120px) {
  .ph1 {
    .tab__content {
      height: 38vh;
    }
  }
}

@media (min-height: 1150px) {
  .ph1 {
    .tab__content {
      height: 41vh;
    }
  }
}



@media (max-height: 830px) {
  .ph1 {
    height: 93vh;
  }
}

@media (max-height: 650px) {
  .ph1 {
    height: 93vh;

    .action-popups-section {
      padding: 16px 20px 30px 20px;
    }

  }
}
