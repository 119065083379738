// --------------------------------- button -----------------------------------
.btn:hover,
.btn:focus {
  text-decoration: none !important;
  background-color: none;
}


.btn-custom {
  width: 120px;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 100px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.07px;
  cursor: pointer;
  border: 1px solid var(--gray-warm-300, #D6D3D1);
  color: var(--text-typography) !important;

}


button {
  border-radius: 100px;
  box-shadow: none !important;
  text-decoration: none !important;
}


.btn {
  box-shadow: none !important;
  outline: 0 !important;
  color: var(--text-typography) !important;
  border-radius: 100px !important;
}

.btn-primary,
.btn-info {
  color: var(--text-typography) !important;
  background-color: #ebcd94 !important;
  border-color: #ebcd94 !important;
}

.btn-primary:hover,
.btn-info:hover,
.a-access:hover {
  color: var(--text-typography) !important;
  background-color: #d6bb87 !important;
  border-color: #d6bb87 !important;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-info.disabled,
.btn-info:disabled {
  opacity: 0.6 !important;
  background-color: #ebcd94 !important;
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.btn-primary.btn-loading,
.btn-info.btn-loading,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #d6bb87 !important;
  border-color: #d6bb87 !important;
}



.btn-secondary {
  border-radius: 100px;
  border: 1px solid #D6BB87;
  background: #FDFAF4;
  padding: 8px 16px;
  color: #63563E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 150% */
  letter-spacing: -0.16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}

.btn-secondary:hover {
  color: #63563E;
  border: 1px solid #A79269;
  background: #F9F0DE;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  border: 1px solid #EFD7A9;
  background: #FDFAF4;
  color: #EFD7A9;
}

.btn-success {
  color: var(--text-typography) !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-success:hover {
  color: var(--text-typography) !important;
  background-color: #218838 !important;
  border-color: #1e7e34 !important;
}

.btn-success.disabled,
.btn-success:disabled {
  color: var(--text-typography) !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #1e7e34 !important;
  border-color: #1c7430 !important;
}

.btn-sub {
  color: var(--text-typography) !important;
  background-color: #F5F5F4 !important;
  border-color: #F5F5F4 !important;
}

.btn-sub:hover {
  color: var(--text-typography) !important;
  background-color: #f1f1f1 !important;
  border-color: #f1f1f1 !important;
}

.btn-sub.disabled,
.btn-sub:disabled {
  color: var(--text-typography) !important;
  background-color: #bfbfbf !important;
  border-color: #bfbfbf !important;
}

.btn-sub:not(:disabled):not(.disabled):active,
.btn-sub:not(:disabled):not(.disabled).active,
.show>.btn-sub.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #F5F5F4 !important;
  border-color: #F5F5F4 !important;
}


.btn-warning {
  color: var(--text-typography) !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.btn-warning:hover {
  color: var(--text-typography) !important;
  background-color: #e0a800 !important;
  border-color: #d39e00 !important;
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: var(--text-typography) !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #d39e00 !important;
  border-color: #c69500 !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-danger:hover {
  color: var(--text-typography) !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: var(--text-typography) !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: var(--text-typography) !important;
  background-color: #bd2130 !important;
  border-color: #b21f2d !important;
}

.btn-light-dark {
  background: var(--light-bg) !important;
}

.btn-light {
  background-color: transparent !important;
}

.btn-light,
.btn-light-dark {
  color: var(--gray) !important;
  border-color: transparent !important;
}

.btn-light:not(:disabled):hover,
.btn-light-dark:not(:disabled):hover,
.btn-light:not(.disabled):hover,
.btn-light-dark:not(.disabled):hover {
  color: var(--text-typography) !important;
  background-color: #e5ecf3 !important;
  border-color: #e5ecf3 !important;
}

.btn-light:active,
.btn-light.active,
.btn-light-dark:active,
.btn-light-dark.active,
.dropdown.open .btn-light-dark {
  color: var(--text-typography) !important;
  background-color: #dae2ea !important;
  border-color: #dae2ea !important;
}

.btn-light.disabled,
.btn-light:disabled {
  color: var(--text-typography) !important;
  background-color: #d5dbde !important;
  border-color: #d5dbde !important;
}


.btn-custom-project {
  width: 148px;
  height: 40px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  // gap: 8px;
  border-radius: 100px;
  text-align: center;
  // font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.07px;
  font-size: 15px;
  cursor: pointer;
}


@media only screen and (max-width: 860px) {
  .btn-custom-project {
    width: 125px;
    padding: 6px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 600px) {
  .btn-custom-project {
    width: 90px;
    font-size: 10px;
    padding: 0px 6px;

  }
}

.btn-link {
  color: #D6BB87;
}

.btn-link:hover {
  color: #D6BB87;
  text-decoration: underline;
}

.btn .mat-icon {
  font-size: 20px;
  height: 20px;
  width: 20px;
}
