$primary: #ebcd94;
$secondary: #e7e5e4;
$success: #039154;
$info: #0fa4ec;
$warning: #0fa4ec;
$danger: #f14437;
$light: #d4d4d4;
$dark: #303030;
$gray-600: #696969;
$text-body-text: #d6d3d1;

.content-nav {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: center;
  gap: var(--number-16-px, 16px);

  .navbar-menu {
    position: relative;


    padding: 0 calc(#{1.5rem} * 0.5);

    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    bottom: auto;

    @media (max-width: 575.98px) {
      .container-fluid {
        padding: 0;
      }
    }

    .navbar-nav {
      flex-direction: row;

      .nav-sm {
        padding-left: 0;

        .nav-link {
          &:before {
            opacity: 0 !important;
          }

          &:hover,
          &.active {
            color: var($primary);
          }
        }
      }

      .nav-link {
        color: #fff;
        padding: 0.75rem 1.5rem;

        i {
          line-height: 1;
        }

        &.active {
          color: var($primary);

          &:after {
            color: var($primary);
          }

          .icon-dual {
            color: var($primary);
            fill: rgba($primary, 0.1);
          }
        }

        &[data-bs-toggle="collapse"][aria-expanded="true"] {
          color: var($primary);

          .icon-dual {
            color: var($primary);
            fill: rgba($primary, 0.1);
          }

          &:after {
            color: var($primary);
          }
        }

        &:hover {
          .icon-dual {
            color: var($primary);
            fill: rgba($primary, 0.1);
          }
        }
      }

      >.nav-item {
        >.nav-link[data-bs-toggle="collapse"]:after {
          left: 0px;
          transform: rotate(90deg) !important;
        }
      }

      >li:nth-of-type(2) {
        >.nav-link.menu-link {
          // padding-left: 0;
        }
      }
    }

    .navbar-brand-box {
      display: none;
    }
  }

  .main-content {
    margin-left: 0;
  }

  @media (min-width: 1250px) {

    .layout-width,
    .container-fluid {
      max-width: 90%;
      margin: 0 auto;
    }

    .topnav-hamburger {
      visibility: hidden;
    }
  }

  .horizontal-logo {
    padding-left: calc(#{1.5rem} * 0.5);

    @media (max-width: 1024.98px) {
      padding-left: 1.5rem;
    }
  }

  .navbar-menu {
    width: 100%;
    z-index: 1000;
    // background: #1f1f1f;
    // border-right: 1px solid #e9ebec;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    padding: 0 calc(#{1.5rem} * 0.5);
    transition: all 0.1s ease-out;

    .navbar-nav {
      .nav-link {
        display: flex;
        align-items: center;
        padding: 0.75rem 1.5rem;
        color: $text-body-text;
        font-size: 0.9375rem;

        &.active {
          color: $text-body-text;
        }

        &:hover {
          color: $text-body-text;
        }

        i {
          display: inline-block;
          min-width: 1.75rem;
          font-size: 18px !important;
          line-height: inherit;
        }

        svg {
          width: 18px;
          margin-right: 0.665rem;
          color: var($primary);
        }

        .badge {
          margin-left: auto;
          margin-right: -2px;
          z-index: 1;
        }

        &:hover {
          color: $primary;

          .icon-dual {
            color: $primary;
            fill: rgba($primary, 0.16);
          }
        }

        &[data-bs-toggle="collapse"] {
          &:after {
            display: block;
            content: "\f0142";
            font-family: "Material Design Icons";
            margin-left: auto;
            transition: transform 0.2s;
            font-size: 1.05rem;
            position: relative;
            left: 18px;
            right: 5px;
            color: inherit;
          }

          &[aria-expanded="true"] {
            color: var($primary);

            .icon-dual {
              color: var($primary);
              fill: rgba($primary, 0.16);
            }

            &:after {
              transform: rotate(90deg);
              color: var($primary);
            }

            &:before {
              opacity: 1;
              background-color: var($primary);
            }
          }
        }

        &.collapsed {
          &.active {
            &::after {
              transform: rotate(90deg);
            }
          }
        }
      }

      .nav-sm {
        padding-left: 0;

        .nav-link {
          padding: 0.55rem 1.5rem !important;
          color: var($primary);
          white-space: none;
          position: relative;
          font-size: 0.875rem;

          &:before {
            content: "";
            width: 6px;
            height: 1.5px;
            background-color: var($gray-600);
            position: absolute;
            left: 2px;
            top: 16.5px;
            transition: all 0.4s ease-in-out;
            opacity: 0.5;
          }

          &:hover {
            color: var($primary);

            &:before {
              background-color: var($primary) !important;
              opacity: 1;
            }
          }

          &.active {
            color: var($primary);

            &:before {
              background-color: var($primary) !important;
            }
          }
        }

        .nav-sm {
          padding-left: 15px;

          .nav-link {
            &:before {
              height: 5px;
              width: 5px;
              left: 5px;
              border-radius: 50%;
              background-color: transparent;
              border: 1px solid;
              top: 16px;
            }
          }
        }
      }
    }

    .btn-vertical-sm-hover {
      color: var($secondary);
      display: none;
    }
  }

  .navbar-nav {
    .nav-item {
      position: relative;

      .nav-link[data-bs-toggle="collapse"]:after {
        left: 10px;
        transform: rotate(0deg) !important;
      }

      >.nav-link {
        >.badge {
          display: none;
        }
      }

      &:hover {
        >.nav-link {
          color: var($primary);

          .icon-dual {
            color: var($primary);
            fill: rgba($primary, 0.1);
          }

          &:after {
            color: var($primary);
          }
        }

        @media (min-width: 1250px) {
          >.menu-dropdown {
            display: block;
            height: auto !important;
          }
        }
      }

      &.active {
        color: var($primary);
      }
    }
  }

  .menu-dropdown {
    position: absolute;
    min-width: 15rem;
    padding: 0.5rem 0;
    box-shadow: 0 0px 5px rgba(15, 34, 58, 0.15);
    animation-name: DropDownSlide;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    margin: 0;
    z-index: 1000;
    background-color: #1f1f1f;
    background-clip: padding-box;
    border: 0 solid #e9ebec;
    border-radius: 0.3rem;
    display: none;

    .menu-dropdown {
      top: 0;
      left: 100%;
    }
  }

  .mega-dropdown-menu {
    width: 40rem;
  }

  .menu-title {
    display: none;
  }

  .dropdown-custom-right {
    left: -100% !important;
    right: 100%;
  }

  @media (max-width: 1250px) {
    .navbar-menu {
      display: none;
    }
  }


  // @media (max-width: 1024px) {
  //   .navbar-menu {
  //     display: block;
  //     max-height: 360px;
  //     overflow-y: auto;
  //     padding-left: 0;

  //     .navbar-nav {
  //       flex-direction: column;

  //       >li:nth-of-type(2)>.nav-link.menu-link {
  //         padding-left: 1.5rem;
  //       }

  //       .nav-sm .nav-link:before {
  //         opacity: 1 !important;
  //       }
  //     }
  //   }

  //   .menu-dropdown {
  //     position: relative;
  //     min-width: 100%;
  //     box-shadow: none;
  //     padding-left: 28px;
  //     left: 0;
  //     animation: none;
  //     padding-top: 0;

  //     &.show {
  //       display: block;
  //     }
  //   }

  //   .dropdown-custom-right {
  //     left: 0 !important;
  //   }

  //   //
  //   .nav-item .nav-link[data-bs-toggle="collapse"]:after {
  //     left: 0px;
  //   }

  //   .mega-dropdown-menu {
  //     width: 100%;
  //   }
  // }


  // # rtl

  .navbar-nav {
    .nav-item {
      .nav-link[data-bs-toggle="collapse"] {
        &:after {
          transform: rotate(-180deg) !important;
        }
      }
    }
  }
}


@media (max-width: 1250px) {
  .content-nav {
    display: none;
  }
}
