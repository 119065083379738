::ng-deep {
  font-family: "IBM Plex Sans Arabic", sans-serif !important;
}

.interactive-map {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  position: relative;
  display: flex;
  direction: rtl;

  .section-sidebar {
    position: relative;
    height: 100%;
    z-index: 800;
    background-color: #0f0f0f;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    transition: 0.5s;

  }

  .section-map {
    position: relative;
    width: 100%;
    height: 0vh;
    z-index: 800;

    .filter {
      position: relative;
      top: 10px;
      left: 0;
      transition: all 0.3s ease-out;
      display: flex;
      gap: 8px;
      height: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      z-index: 800;

      .color-meaning-right {
        display: flex;

        .map-filters {
          display: flex;
          width: 200px;
          padding: var(--number-0-px, 0px);
          //  justify-content: flex-end;
          align-items: flex-start;
          gap: var(--number-0-px, 0px);
          border-radius: var(--number-8-px, 8px);
          opacity: var(--background-map, 1);
          cursor: pointer;
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

          .close-filter {
            display: flex;
            padding: var(--number-8-px, 8px) var(--number-16-px, 16px);
            justify-content: center;
            align-items: center;
            gap: var(--number-8-px, 8px);
            flex: 1 0 0;
            border-radius: var(--number-8-px, 100px);
            opacity: var(--background-map, 1);
            background-color: var(--bs-body-bg-interactive-map);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

          }

          .icon-active {
            transform: rotate(180deg);
            transition: transform 0.3s linear;
          }

          .icon-dis-active {
            transform: rotate(0deg);
            transition: transform 0.3s linear;
          }

          // .close-filter:hover {
          //   box-shadow: 0px 0px 0px 1px #f9f0de,
          //     0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          //   border-top: 1px solid var(--stroke-primary-stroke, #d6bb87);
          //   border-right: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
          //   border-bottom: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
          //   border-left: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
          // }

          .forms-filter {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .col-forms-filter {
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 0 0;
              text-align: right;
              margin: 0;


              .slider-ranger {
                margin-top: 40px;
              }
            }
          }

          // .dropdown-menu {
          //   border-radius: var(--number-8-px, 8px);
          //   border-top: 1px solid var(--stroke-gray-stroke, #e7e5e4);
          //   border-right: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
          //   border-bottom: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
          //   border-left: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
          //   opacity: var(--background-map, 1);
          //   background: var(--White, #fff);

          //   /* Map */
          //   box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
          // }
        }
      }

      .color-meaning-left {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-left: 40px;

        .container-color-meaning {
          display: flex;
          flex-direction: row;
          height: var(--number-32-px, 32px);
          padding: 6px 10px;
          justify-content: flex-end;
          align-items: center;
          gap: var(--number-8-px, 8px);
          border-radius: 6px;
          // border: 1px solid var(--gray-true-300, #D4D4D4);
          background: var(--background-background, #fff);

          /* Map */
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

          .dot {
            display: flex;
            padding: var(--number-0-px, 0px);
            align-items: flex-start;
            gap: 10px;
          }

          .text-color-meaning {
            color: var(--text-body-text, #44403c);
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.14px;
          }
        }
      }

      .non-selected {
        border: 3px solid var(--gray-true-300, #d4d4d4);
      }

      .selected {
        border: 3px solid var(--gray-true-300, #B49164);
      }
    }


  }

  .map {
    z-index: 700;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    height: 100%;
    font-family: "IBM Plex Sans Arabic", sans-serif;
  }
}

.dropdown-menu-right-interactive {
  right: 0 !important;
  left: auto !important;
  transform: translate(0, 49px) !important;
  background-color: var(--bs-body-bg-interactive-map);
}

.dropdown-map-filter-interactive-map {
  display: flex;
  padding: var(--number-0-px, 16px);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--number-0-px, 0px);
  width: 270px;
  height: 70vh;
  overflow-y: auto;

  .dropdown-map-filter-header {
    display: flex;
    padding: 10px var(--number-16-px, 16px);
    justify-content: space-between;
    align-items: center;
    gap: var(--number-8-px, 8px);
    align-self: stretch;
    border-radius: var(--number-8-px, 8px) var(--number-8-px, 8px) var(--number-0-px, 0px) var(--number-0-px, 0px);
    border-right: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
    border-bottom: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
    border-left: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
    opacity: var(--background-map, 1);


  }

  .line {
    height: var(--background-map, 1px);
    align-self: stretch;
    border-radius: var(--number-0-px, 0px);
    background: var(--stroke-gray-stroke, #e7e5e4);
  }

  .labels-fomrs {
    display: flex;
    padding: var(--number-0-px, 0px);
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 5px;


    .reset {
      color: var(--text-body-text-4, #d6d3d1);
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
      letter-spacing: -0.16px;
      cursor: pointer;
    }
  }
}

.dropdown-toggle::after {
  display: none;
}


.details-map-popup-section {
  display: flex;
  padding: 3px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px;

  .access_path_v2 {
    display: flex;
    padding: 16p 0;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--number-20-px, 20px);
    align-self: stretch;

    .container {
      display: flex;
      padding: var(--number-0-px, 0px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--number-4-px, 4px);
      align-self: stretch;

      .col-access {
        display: flex;
        padding: var(--number-0-px, 0px);
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .col-access-left {
          display: flex;
          padding: var(--number-0-px, 0px);
          align-items: center;
          gap: var(--number-12-px, 12px);

          .text {
            color: var(--text-typography) !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.14px;
          }
        }

        .col-access-right {
          color: var(--text-typography) !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 142.857% */
          letter-spacing: -0.14px;
        }
      }

      .dot {
        display: flex;
        width: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
      }
    }
  }
}
