table {
  .mat-sort-header-arrow[style] {
    transform: none !important;
    opacity: 1 !important;

    // Hide default arrow stem
    .mat-sort-header-stem {
      display: none !important;
    }

    .mat-sort-header-indicator {
      opacity: 1 !important;
      color: var(--text-typography) !important;
      font-weight: bold !important;
      font-size: 10px !important;
      transform: none !important;

      // Hide default arrow as its composed of left, right and middle
      .mat-sort-header-pointer-left,
      .mat-sort-header-pointer-right,
      .mat-sort-header-pointer-middle {
        display: none !important;
      }

      &::before {
        transform: none !important;
        font: normal normal normal 0.8rem/1 FontAwesome !important;
        content: "\f0dc" !important;
        position: absolute !important;
        top: 0px !important;
      }
    }
  }

  [aria-sort="ascending"] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        transform: none !important;

        &::before {
          font: normal normal normal 0.8rem/1 FontAwesome !important;
          content: "\f0d7" !important;
          position: absolute !important;
          top: 0 !important;
        }
      }
    }
  }

  [aria-sort="descending"] {
    .mat-sort-header-arrow {
      .sort-icon {
        display: none !important;
      }

      .mat-sort-header-indicator {
        &::before {
          font: normal normal normal 0.8rem/1 FontAwesome !important;
          content: "\f0d8" !important;
          position: absolute !important;
          top: 0 !important;
        }
      }
    }
  }
}


.mat-paginator {
  background: var(--bs-body-secondry) !important;
  color: var(--text-typography) !important;
  position: sticky;
  bottom: 0px;
  right: 0;
  left: 0;
  font-weight: bold !important;
  margin-top: 5px !important;
}
