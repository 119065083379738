.contriner-header-sidebar-information-sub {
  display: flex;
  padding: var(--Number-16-px, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;


  .icon-continer {
    display: flex;
    padding: 0px;
    align-items: flex-start;
    gap: 0px;
  }

  .title-continer {
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    align-self: stretch;

    //  .title-counter-number {
    //    color: #101828;


    //    /* Heading/h2/Medium */

    //    font-size: 24px;
    //    font-style: normal;
    //    font-weight: 900;
    //    line-height: 27px;
    //    /* 126.667% */
    //    letter-spacing: -0.3px;
    //    margin: 0;
    //  }
  }



}

.background {
  background: #F5F5F4
}




@media only screen and (max-width: 1250px) {
  .contriner-header-sidebar-information-sub {
    align-items: center;
    padding: 10px;
  }

  .contriner-header-sidebar-information-sub {
    margin-top: 4px;
    flex-direction: row;

  }
}
