// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@import "~@angular/material/theming";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-leafet-primary: mat.define-palette(mat.$indigo-palette);
$angular-leafet-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-leafet-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-leafet-theme: mat.define-light-theme((color: (primary: $angular-leafet-primary,
        accent: $angular-leafet-accent,
        warn: $angular-leafet-warn,
      ),
    ));

$custom-typography: mat-typography-config($font-family: "IBM Plex Sans Arabic, sans-serif;",
  );
@include mat.core($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-leafet-theme);

// .swal2-height-auto {
//   display: none !important;
// }

$custom-accent: (
  50: #f2deb7,
  100: #f2deb7,
  200: #f2deb7,
  300: #f2deb7,
  400: #f2deb7,
  500: #f2deb7,
  600: #f2deb7,
  700: #f2deb7,
  800: #f2deb7,
  900: #f2deb7,
  A100: #f2deb7,
  A200: #f2deb7,
  A400: #f2deb7,
  A700: #f2deb7,
  contrast: (50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: $black-87-opacity,
    900: $black-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  ),
);

$app-primary: mat-palette($custom-accent);
$app-accent: mat-palette($custom-accent);
$app-warn: mat-palette($mat-red);
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Define the dark theme
$app-dark-primary: mat-palette($custom-accent);
$app-dark-accent: mat-palette($custom-accent);
$app-dark-theme: mat-dark-theme($app-dark-primary, $app-dark-accent);

@include angular-material-theme($app-theme);

// @font-face {
//   font-family: "Janna LT";
//   src: url("./assets/fonts/janna/JannaLT-Regular.eot");
//   src: url("./assets/fonts/janna/JannaLT-Regular.eot?#iefix") format("embedded-opentype"),
//     url("./assets/fonts/janna/JannaLT-Regular.woff2") format("woff2"),
//     url("./assets/fonts/janna/JannaLT-Regular.woff") format("woff"),
//     url("./assets/fonts/janna/JannaLT-Regular.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Janna LT";
//   src: url("./assets/fonts/janna/JannaLT-Bold.eot");
//   src: url("./assets/fonts/janna/JannaLT-Bold.eot?#iefix") format("embedded-opentype"),
//     url("./assets/fonts/janna/JannaLT-Bold.woff2") format("woff2"),
//     url("./assets/fonts/janna/JannaLT-Bold.woff") format("woff"),
//     url("./assets/fonts/janna/JannaLT-Bold.ttf") format("truetype");
//   font-weight: bold;
//   font-style: normal;
// }

/* You can add global styles to this file, and also import other style files */
@import "~leaflet/dist/leaflet.css";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap";
@import "../src/assets/css/icons/remixicon";
@import "../src/assets/css/icons/line-awesome";
@import "../src/assets/css/icons/materialdesignicons";
@import "../src/assets/css/icons/boxicons";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../src/assets/css/apps/utilities/utilities.scss";

@import "~ngx-spinner/animations/ball-spin-clockwise.css";

$primary: #ebcd94;
$secondary: #e7e5e4;
$success: #039154;
$info: #0fa4ec;
$warning: #0fa4ec;
$danger: #f14437;
$light: #d4d4d4;
$dark: #303030;
$background: #f5f5f4;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: Janna LT,Roboto,Arial!important;
}

// .ion-page {
//   overflow: auto !important;
// }

// svg.leaflet-zoom-animated>g>path {
//   transition-property: fill, fill-opacity, stroke, stroke-opacity, stroke-width;
//   transition-duration: 0.4s;
// }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: var(--font-family) !important;

  line-height: normal;
  --ion-background-color: var(--bs-body-bg);
}

// @media print {
//   html,
//   body {
//     --ion-background-color: #fff;
//   }
// }

.mat-expansion-panel-header,
.mat-expansion-panel-content,
.mat-table {
  font-family: var(--font-family) !important;
  table-layout: fixed;
}

::ng-deep {

  .mat-body,
  .mat-body-1,
  .mat-typography .mat-body,
  .mat-typography .mat-body-1,
  .mat-typography,
  .mat-typography h1,
  .mat-typography h2,
  .mat-typography h3,
  .mat-typography h4,
  .mat-typography h5 {
    font-family: var(--font-family) !important;
    font: 400 14px / 20px var(--font-family) !important;
  }
}

mat-paginator {
  direction: ltr;
}

:root,
[data-bs-theme="light"] {
  /* fonts */
  --font-family: "IBM Plex Sans Arabic", sans-serif;

  /* Backgrounds */
  --bs-body-bg: #f5f5f4 !important;
  --bs-body-secondry: #fff !important;
  --select-input: #f5f5f4 !important;
  --expansion-panel: #f7f7f6 !important;
  // text p h1 h2 h3 h4 h5
  --text-typography: #1c1917;
  --text-typography-reverse: #fff;

  --primary: #ebcd94;
  --dark: #c7c7c7;
  --border: #303030 !important;
  --icon-filtter: invert(47%) sepia(5%) saturate(550%) hue-rotate(342deg) brightness(92%) contrast(85%) !important;

  /* font sizes */
  --paragraph-p2-regular1-size: 14px;
  --paragraph-p1-regular-size: 16px;
  --paragraph-p3-regular-size: 13px;
  --heading-h2-medium1-size: 30px;

  /* Backgrounds */

  /* Colors */
  --bg-body-color: #44403c;
  --primary-50: #fdfaf4;
  --white: #fff;
  --gray-warm-gray-500-base: #78716c;
  --gray-warm-600: #57534e;
  --primary-100: #f9f0de;
  --primary-200: #f6e8ce;
  --primary-600: #d6bb87;
  --primary-500-base: #ebcd94;
  --gray-warm-gray-900: #1c1917;
  --gray-warm-700: #44403c;
  --neutrals-6: #e6e8ec;
  --stroke-gray-stroke: #e7e5e4;
  --bs-heading-color: #1c1917;

  /* Spacing */
  --number-8: 8px;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-xs: 12px;
  --br-81xl: 100px;
  --br-7xs: 6px;
  --border-radus-8: 8px;
  --br-9xs: 4px;

  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-xs1: 0px 3px 4px -5px rgba(24, 24, 28, 0.03),
    0px 1px 2px rgba(24, 24, 28, 0.04);

  --stroke-gray-stroke: #e7e5e4;
  --stroke-gray-stroke2: #e7e5e4;
}

[data-bs-theme="dark"] {
  /* Backgrounds */
  // --bs-body-bg: #22201E !important;
  --bs-body-bg-interactive-map: #1f1d1d;
  --bs-body-bg: #252527;
  --bs-body-secondry: #404040;
  --select-input: #404040;
  --expansion-panel: #404040 !important;

  // text p h1 h2 h3 h4 h5
  --text-typography: #ffffff;
  --text-typography-reverse: #000;

  --primary: #d6bb87;
  --dark: #78716c;
  --border: #78716c !important;
  --icon-filtter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(2deg) brightness(102%) contrast(100%) !important;

  --widget: #424242;
  --stroke-gray-stroke: #404040;
  --stroke-gray-stroke2: #2f2f31;
  --bs-heading-color: #fff;
  --bs-body-color: #d6d3d1;
  --bs-white: #2f2f31;

  @include angular-material-theme($app-dark-theme);
}

* {
  transition: background-color 0.1s linear, border-color 0.1s linear,
    box-shadow 0.1s linear;
}

body {
  --bs-body-bg: #f5f5f4;

  .table,
  .card,
  .modal-content {
    background-color: var(--bs-white);
  }
}

.table thead,
[mat-header-row] {
  background-color: var(--bs-body-bg);
}

.mat-header-cell {
  color: inherit;
}

.card {
  border-radius: 8px;
  border: unset;
  box-shadow: 0px 1px 4px -1px rgba(50, 50, 71, 0.05);

  .card-body {
    padding: 20px;
  }
}

.p-20 {
  padding: 20px;
}

.custom-bold-16 {
  font-size: 16px;
  color: #1c1917;
  font-weight: 600;
}

.text-16 {
  font-size: 16px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.grey-text {
  color: #78716c;
}

.w-120 {
  width: 120px;
}

.w-90 {
  width: 90px;
}

.orange-text {
  color: #ebcd94;
}

// Calendar

.mat-calendar-body-selected {
  background-color: #ebcd94;
  color: #1c1917;
  box-shadow: 0 0 10px #ebcd94;
  border: unset !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: unset;
  color: #d6bb87;
  border: unset !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: transparent !important;
  border: unset !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):focus>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #d6bb87 !important;
  color: #1c1917;
  border: unset !important;
}

.mat-calendar-table-header-divider::after {
  content: unset !important;
}

.cdk-overlay-container {
  z-index: 1100 !important;
}

// Accordion

.accordion-button::after {
  margin-left: unset;
  margin-right: auto;
}

.accordion-item:first-child {
  border-top: unset;
}

.accordion-item:last-child {
  border-bottom: unset;
}

.accordion-button:not(.collapsed) {
  color: inherit;
  background-color: #fff;
  box-shadow: unset;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23D6BB87'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

// Checkbox

.form-check-input.custom-radio {
  width: 25px;
  height: 25px;
  border-radius: 50% !important;
  background-color: #fff;
  border: 2px solid #e7e5e4;
  box-shadow: unset;
  position: static !important;
  cursor: pointer;

  &:checked[type="checkbox"] {
    background-color: #02b37d;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    border-color: #02b37d;
  }
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  min-height: calc(100vh - 60px);
}

// Dropzone custom styles

ngx-dropzone {
  height: unset !important;
  border-radius: 8px !important;
  border: 1px dashed #f5f5f4 !important;
  background: #f5f5f4 !important;
  padding: 26px 14px;
  color: #78716c !important;

  mat-icon {
    font-size: 40px !important;
    height: 40px !important;
    width: 40px !important;
    margin-bottom: 12px;
    color: #78716c !important;
  }

  p {
    max-width: 240px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 24px;
    margin-bottom: 0;
    color: #78716c !important;

    .green-text {
      color: #039154;
    }
  }

  ngx-dropzone-label {
    overflow: hidden;
  }
}

.container-icons {
  display: flex;
  gap: 3px;
}

.modal-xxxl {
  max-width: 1500px !important;
  width: 97% !important;
}

// .sort-table {
//   .mat-sort-header-arrow[style] {
//     transform: none !important;
//     opacity: 1 !important;

//     // Hide default arrow stem
//     .mat-sort-header-stem {
//       display: none;
//     }

//     .mat-sort-header-indicator {
//       opacity: 1;
//       color: black;
//       font-weight: bold;
//       font-size: 10px;
//       transform: none !important;

//       // Hide default arrow as its composed of left, right and middle
//       .mat-sort-header-pointer-left,
//       .mat-sort-header-pointer-right,
//       .mat-sort-header-pointer-middle {
//         display: none;
//       }

//       &::before {
//         transform: none !important;
//         font: normal normal normal 0.8rem/1 FontAwesome;
//         content: "\f0dc";
//         position: absolute;
//         top: 0px;
//       }
//     }
//   }

//   [aria-sort="ascending"] {
//     .mat-sort-header-arrow {
//       .mat-sort-header-indicator {
//         transform: none !important;

//         &::before {
//           font: normal normal normal 0.8rem/1 FontAwesome;
//           content: "\f0d7";
//           position: absolute;
//           top: 0;
//         }
//       }
//     }
//   }

//   [aria-sort="descending"] {
//     .mat-sort-header-arrow {
//       .sort-icon {
//         display: none;
//       }

//       .mat-sort-header-indicator {
//         &::before {
//           font: normal normal normal 0.8rem/1 FontAwesome;
//           content: "\f0d8";
//           position: absolute;
//           top: 0;
//         }
//       }
//     }
//   }
// }

.border-selected {
  border-radius: 8rem;
  background-color: rgb(0, 181, 0);
  width: 10px;
  height: 10px;
}

.border-unselected {
  border-radius: 8rem;
  background-color: gray;
  width: 10px;
  height: 10px;
}

// timepicker
ngx-material-timepicker-container {
  --button-color: #d6bb87 !important;
  --dial-background-color: #d6bb87 !important;
  --dial-editable-active-color: #d6bb87 !important;
  --clock-hand-color: #d6bb87 !important;
  direction: ltr;

  .timepicker-overlay {
    direction: ltr;
    z-index: 1105 !important;
  }

  .timepicker-backdrop-overlay {
    z-index: 1104 !important;
  }
}

// Tabler icons

i-tabler {
  vertical-align: middle;
}

i-tabler svg {
  display: block;
}

.heading-color {
  color: var(--bs-heading-color);
}

// Material Dark Theme
.mat-form-field-label {
  color: var(--bs-heading-color) !important;
}

.mat-form-field-underline {
  background-color: var(--bs-body-color) !important;
}

.mat-hint {
  color: var(--bs-gray) !important;
}

.cdk-global-scrollblock {
  overflow: auto !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-drawer-container,
[data-bs-theme="dark"] .mat-drawer-container {
  background-color: var(--bs-body-bg);
}

.custom-modal-width {
  .modal-dialog {
    max-width: 600px;
  }
}

.custom-popup-modal-width {
  .modal-dialog {
    max-width: 880px !important;
  }
}

.interactive-map {

  .ng-select.ng-select-single .ng-select-container .ng-value-container,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    overflow: visible !important;
  }

  .ng-select .ng-select-container {
    background-color: var(--bs-body-bg-interactive-map) !important;
    color: var(--text-typography) !important;
  }

  .ng-select .ng-select-opened .ng-select-bottom {
    background-color: var(--bs-body-bg-interactive-map) !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: var(--bs-body-bg-interactive-map) !important;
    color: var(--text-typography) !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
    background-color: var(--primary) !important;
    color: var(--text-typography) !important;
  }

  .ng-select.ng-select-single .ng-select-container {
    padding: 0 5px !important;
  }

  .ngx-select-custom {
    width: 100%;
    border-radius: 8px;
    // background: #fff !important;
    background-color: var(--bs-body-bg-interactive-map) !important;
  }

  .ng-select .ng-select-container {
    border: none !important;
    border-bottom: 2px solid #3f3c39 !important;
  }
}

.leaflet-container a.leaflet-popup-close-button {
  display: none !important;
  // right: 90% !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  width: 320px !important;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip,
.leaflet-draw-draw-polyline,
.leaflet-draw-draw-circle,
.leaflet-draw-draw-marker,
.leaflet-draw-edit-remove,
.leaflet-touch .leaflet-draw-actions a,
.leaflet-bar a {
  color: var(--text-typography) !important;
  background-color: var(--bs-body-bg) !important;
}

.leaflet-control-zoom-in,
.leaflet-draw-draw-polyline,
.leaflet-draw-edit-edit {
  border-bottom: 1px solid #3f3c39 !important;
}

.leaflet-control-zoom-in,
.leaflet-draw-draw-polyline,
.leaflet-draw-edit-edit {
  border-radius: 25px 25px 0 0 !important;
  margin: 5px 5px 0 5px;
}

.leaflet-control-zoom-out,
.leaflet-draw-draw-rectangle,
.leaflet-draw-edit-remove {
  border-radius: 0 0 25px 25px !important;
  margin: 0 5px 5px 5px;
}

.leaflet-touch .leaflet-bar,
.leaflet-control-zoom {
  border: none !important;
}

.leaflet-draw-toolbar a {
  background-image: url("../src/assets/leaflet-icon-toolbar/toolbar.svg") !important;
  // background-image: url('../src/assets/leaflet-icon-toolbar/toolbar.svg');
  background-repeat: no-repeat;
  color: transparent !important;
}

// mat-expansion-panel
.interactive-map {

  .mat-expansion-panel,
  .mat-expansion-panel-header-title,
  .mat-expansion-indicator::after {
    background-color: var(--bs-body-bg-interactive-map) !important;
    color: var(--text-typography) !important;
  }

  .mat-expansion-panel-header-description {
    flex-grow: 0 !important;
  }

  .mat-expansion-panel-header-title {
    margin-left: 0 !important;
    padding-left: 6px !important;
  }

  .mat-expansion-panel-header {
    /* height: 48px; */
    height: auto !important;
  }

  .w-30 {
    width: 30%;
  }

  .example-action-buttons {
    padding-bottom: 20px;
  }

  .example-headers-align .mat-expansion-panel-header-title,
  .example-headers-align .mat-expansion-panel-header-description {
    flex-basis: 0;
  }

  .example-headers-align .mat-expansion-panel-header-description {
    justify-content: space-between;
    align-items: center;
  }

  .example-headers-align .mat-form-field+.mat-form-field {
    margin-left: 8px;
  }

  .my-panel {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;

    img {
      margin-right: auto;
      margin-left: 20px;
    }
  }
}

@media print {

  body,
  html {
    background-color: white !important;
    --ion-background-color: white;
  }
}

// .css-icon {}

// .gps_ring {
//   border: 3px solid #0e95d7;
//   border-radius: 30px;
//   height: 18px;
//   width: 18px;
//   margin-right: 3px;
// }

@keyframes shake {
  50% {
    transform: translateY(-5px);
    transform: scale(1.4);
    opacity: 0.8;
  }
}

.popup-google-map {
  width: 380px !important;

  .leaflet-popup-content {
    width: 380px !important;
  }
}

.leaflet-container {
  background: #fff !important;
}

.w-33 {
  width: 33% !important;
}

.remove-mat-expansion-panel-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 !important;
}


// mapbox
.mapboxgl-ctrl-bottom-left {
  display: none !important;
}

.mapboxgl-marker {
  font-family: var(--font-family) !important;
}
