.otp-input {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  border: solid 1px #c5c5c5;
  text-align: center;
  font-size: 32px;
}

.ng-otp-input-wrapper {
  .otp-input:last-child {
    margin-right: 8px !important;
  }
}

@media screen and (max-width:767px) {
  .otp-input {
    width: 40px;
    font-size: 24px;
    height: 40px;
  }
}


@media screen and (max-width:420px) {
  .otp-input {
    width: 30px;
    font-size: 18px;
    height: 30px;
  }
}
