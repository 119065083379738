$primary: #ebcd94;
$secondary: #e7e5e4;
$success: #039154;
$info: #0fa4ec;
$warning: #0fa4ec;
$danger: #f14437;
$light: #d4d4d4;
$dark: #303030;
$gray-600: #696969;
$text-body-text: #d6d3d1;

.header-office-hajj {
  position: relative;
  z-index: 900;
  display: flex;
  width: 100%;
  height: 7vh;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  align-items: center;
  gap: var(--number-0-px, 0px);
  border-radius: var(--number-0-px, 0px);
  background: var(--background-background, #1f1f1f);
  position: relative;
  // border-bottom: 1px solid #a1a1a1;
  padding: 5px 0;

  .name {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #74787d !important;
  }

  .header-container {
    display: flex;
    height: 100%;
    padding: var(--number-0-px, 0px) 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .logo {
      display: flex;
      padding: var(--number-0-px, 0px);
      align-items: center;
      gap: var(--number-16-px, 16px);
    }

    .content {
      display: flex;
      padding: var(--number-0-px, 0px);
      align-items: center;
      gap: var(--number-16-px, 16px);


      .name {
        color: $secondary !important;
        /* Heading/h3/Medium */
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        /* 133.333% */
        letter-spacing: -0.24px;
        margin: 0;
      }

      .image-content {
        display: flex;
        padding: var(--number-0-px, 0px);
        align-items: center;
        gap: 6px;
        width: 36px;
        height: 36px;
        border-radius: 200px;
        background: lightgray 50% / cover no-repeat;
      }

      .icon {
        display: none;
      }
    }


  }
}



@media (max-width: 1250px) {
  .header-office-hajj {
    .header-container {
      .content {
        .name {
          display: none;
        }

        .image-content {
          display: none;
        }

        .icon {
          display: initial;
          color: $secondary !important;
          /* Heading/h3/Medium */
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          /* 133.333% */
          letter-spacing: -0.24px;
          margin: 0;
        }

      }
    }
  }
}

@media (min-width: 1251px) {
  app-mobile-nav {
    display: none;
  }
}
