 .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
   background-color: #ebcd94 !important;
 }

 .mat-radio-button.mat-accent .mat-radio-inner-circle {
   background-color: #ebcd94 !important;
   /*inner circle color change*/
 }

 .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
   border-color: #ebcd94 !important;
   /*outer ring color change*/
 }

 .mat-radio-label-content {
   margin-right: 8px !important;
   font-family: "Janna LT" !important;
 }
