// @font-face {
//   font-family: "Janna LT";
//   src: url("../../../../../assets/fonts/janna/JannaLT-Regular.eot");
//   src: url("../../../../../assets/fonts/janna/JannaLT-Regular.eot?#iefix") format("embedded-opentype"),
//     url("../../../../../assets/fonts/janna/JannaLT-Regular.woff2") format("woff2"),
//     url("../../../../../assets/fonts/janna/JannaLT-Regular.woff") format("woff"),
//     url("../../../../../assets/fonts/janna/JannaLT-Regular.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Janna LT";
//   src: url("../../../../../assets/fonts/janna/JannaLT-Bold.eot");
//   src: url("../../../../../assets/fonts/janna/JannaLT-Bold.eot?#iefix") format("embedded-opentype"),
//     url("../../../../../assets/fonts/janna/JannaLT-Bold.woff2") format("woff2"),
//     url("../../../../../assets/fonts/janna/JannaLT-Bold.woff") format("woff"),
//     url("../../../../../assets/fonts/janna/JannaLT-Bold.ttf") format("truetype");
//   font-weight: bold;
//   font-style: normal;
// }


.text-info {
  color: #B49164 !important;
}

a {
  font-family: var(--font-family) !important;
  text-decoration: none !important;
}

label {
  font-family: var(--font-family) !important;
  color: var(--text-typography);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

p {
  // color: var(--Text-Body-Text, #44403c);
  text-align: right;
  font-family: var(--font-family) !important;

  /* Paragraph/p1/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  /* 150% */
  margin: 0 !important;
  color: var(--text-typography);
}

h4 {
  // color: var(--Text-Heading-Text, #1c1917);
  font-family: var(--font-family) !important;

  /* Heading/h4/Medium */
  font-size: 20px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 28px;
  /* 140% */
  margin: 0 !important;
  color: var(--text-typography);

}

h3 {
  font-family: var(--font-family) !important;
  text-align: right;

  /* Heading/h3/Medium */
  font-size: 15px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  /* 133.333% */
  margin: 0 !important;
  color: var(--text-typography);

}

h2 {
  /* Heading/h2/Medium */
  font-family: var(--font-family) !important;

  font-size: 24px;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 27px;
  /* 126.667% */
  margin: 0 !important;
  color: var(--text-typography);

}

h1 {
  font-family: var(--font-family) !important;
  text-align: right;
  /* Heading/h1/Medium */
  font-size: 36px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 46px;
  /* 127.778% */


  margin: 0 !important;
  color: var(--text-typography);


}

h5 {
  // color: var(--Text-Heading-Text, #1c1917);
  text-align: right;
  /* Heading/h1/Medium */
  font-size: 18px;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 26px;
  font-family: var(--font-family) !important;

  margin: 0 !important;
  color: var(--text-typography);

}

span {
  color: var(--text-typography);
  font-family: var(--font-family) !important;

}

@media only screen and (max-width: 1250px) {
  p {
    font-size: 13px !important;
  }

  h2 {
    font-size: 24px;
  }
}
