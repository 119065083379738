:root {
  /* fonts */

  /* font sizes */
  --paragraph-p2-regular1-size: 14px;
  --paragraph-p1-regular-size: 16px;
  --paragraph-p3-regular-size: 13px;
  --heading-h2-medium1-size: 30px;

  /* Colors */
  --primary-50: #fdfaf4;
  --white: #fff;
  --gray-warm-gray-500-base: #78716c;
  --gray-warm-600: #57534e;
  --primary-600: #d6bb87;
  --primary-500-base: #ebcd94;
  --gray-warm-gray-900: #1c1917;
  --gray-warm-700: #44403c;
  --neutrals-6: #e6e8ec;
  --stroke-gray-stroke: #e7e5e4;

  /* Spacing */
  --number-8: 8px;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-xs: 12px;
  --br-81xl: 100px;
  --br-7xs: 6px;
  --border-radus-8: 8px;
  --br-9xs: 4px;

  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-xs1: 0px 3px 4px -5px rgba(24, 24, 28, 0.03),
    0px 1px 2px rgba(24, 24, 28, 0.04);
}

.ph4 {
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 67px 30px rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  zoom: 0.8;

  .header-map-popup-section {
    display: flex;
    // width: 572px;
    padding: 20px 20px 10px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 8px 0 0;
    border-bottom: 1px solid #e7e5e4;
    background: #fff;

    i {
      font-size: 25px !important;
    }

    .header-map-popup-section-left {
      position: relative;
      display: flex;
      padding: 0px;
      align-items: flex-start;
      gap: 12px;

      .header-map-popup-section-icon {
        display: flex;
        padding: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #e7e5e4;
        background: #fafaf9;
      }

      .non-active {
        border: 1px solid #e7e5e4;
        background: #fafaf9;
        color: #78716c;
      }

      .active {
        border: 1px solid var(--secondary-500-base, #039154);
        color: #039154;
        background: var(--secondary-50, #e6f4ee);
      }
    }

    .header-map-popup-section-right-stop {
      display: flex;
      padding: 4px 12px 4px 10px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
    }

    .header-map-popup-section-right-start {
      display: flex;
      padding: 4px 12px 4px 10px;
      align-items: center;
      gap: 4px;
      border-radius: var(--number-8-px, 8px);
      border: 1px solid var(--secondary-500-base, #039154);
      background: var(--secondary-50, #e6f4ee);
    }
  }

  .details-map-popup-section {
    display: flex;
    padding: 16px 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 0px;
    background: #fff;

    .details-map-popup-section-plot-camp {
      display: flex;
      padding: 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      .details-plot {
        display: flex;
        padding: var(--number-0-px, 0px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--number-16-px, 14px);
        align-self: stretch;

        .details-plot-name {
          display: flex;
          padding: 0px;
          align-items: center;
          gap: 12px;
          text-align: right;

          .plot-name {
            display: flex;
            padding: 0px;
            align-items: center;
            gap: 10px;
          }

          .plot-number {
            color: #44403c;

            /* Heading/h5/Medium */

            // font-size: 18px;
            // font-style: normal;
            // font-weight: 800;
            // line-height: 26px;
            // letter-spacing: -0.18px;
          }

          .avalaible {
            background: var(--secondary-50, #e6f4ee);
            color: var(--accent-success-500-base, #02b37d);
          }

          .unavalaible {
            background: var(--secondary-50, #fbc5c1);
            color: var(--accent-success-500-base, #f14437);
          }

          .plot-status {
            display: flex;
            height: 24px;
            padding: 10px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;

            border-radius: 100px;
            mix-blend-mode: multiply;

            .plot-status-text {
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              /* 166.667% */
              letter-spacing: -0.06px;
            }
          }
        }

        .details-plot-information {
          display: flex;
          padding: 0px;
          align-items: center;
          // gap: 25px;
          align-self: stretch;
          padding-right: 5px;
          flex-wrap: wrap;

          .plot-information-col {
            display: flex;
            padding: 2px 10px;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;


            .info-col-up {
              color: #78716c !important;

              // font-size: 14px;
              // font-style: normal;
              // font-weight: 400;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }

            .info-col-down {
              color: #000;

              // font-size: 14px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }

            .d2 {
              border: 1px solid #0e95d7;
              background: #e7f6fd;
              color: #0e95d7;
              cursor: pointer;
            }

            .d3 {
              border: 1px solid var(--secondary-500-base, #039154);
              background: var(--secondary-50, #e6f4ee);
              color: #039154;
              cursor: pointer;
            }
          }
        }
      }

      .details-camp {
        width: 100%;
        height: 80vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          border-radius: 8px;
          border: 1px solid #e7e5e4;
        }

        .info-selected {
          border-radius: 8px;
          border: 1px solid #ebcd94;
        }

        .info-selected-booked {
          border-radius: 8px 8px 0px 0px;
          border: 1px solid var(--accent-blue-50, #0e95d7);
        }

        .info-unselected-unbooked {
          border-radius: 8px 8px 0px 0px;
          border: 1px solid var(--accent-blue-50, #f14437);
        }

        .info-header-selected {
          border-radius: 0;
          border: 1px solid #ebcd94;
        }

        .mat-panel-title-camp {
          display: flex;
          padding: var(--number-0-px, 0px);
          align-items: center;
          gap: var(--number-8-px, 8px);

          .text {
            color: var(--text-body-text-2, #78716c);
            leading-trim: both;
            text-edge: cap;

            /* Paragraph/p2/Table */

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.14px;
          }

          .camp {
            display: inline;

            /* Paragraph/p2/Medium */

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.14px;
          }
        }

        .details-camp-plot-information {
          display: flex;
          flex-wrap: wrap;
          padding: 0px;
          align-items: center;
          // justify-content: space-around;
          // gap: 40px;
          align-self: stretch;
          margin-top: 15px;

          .plot-camp-information-col {
            display: flex;
            padding: 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;
            margin-right: 8%;
            margin-bottom: 2%;

            .info-col-up {
              color: #78716c !important;

              // font-size: 14px;
              // font-style: normal;
              // font-weight: 400;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }

            .info-col-down {
              color: #000;

              // font-size: 14px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: 20px;
              // /* 142.857% */
              // letter-spacing: -0.14px;
            }
          }
        }
      }
    }

    .access_path_v2 {
      display: flex;
      padding: var(--number-16-px, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--number-20-px, 20px);
      align-self: stretch;

      .container {
        display: flex;
        padding: var(--number-0-px, 0px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--number-4-px, 4px);
        align-self: stretch;

        .col-access {
          display: flex;
          padding: var(--number-0-px, 0px);
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          .col-access-left {
            display: flex;
            padding: var(--number-0-px, 0px);
            align-items: center;
            gap: var(--number-12-px, 12px);

            .text {
              color: var(--text-body-text-2, #78716c);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.14px;
            }
          }

          .col-access-right {
            color: var(--text-heading-text, #1c1917);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.14px;
          }
        }

        .dot {
          display: flex;
          width: 12px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2px;
        }
      }
    }
  }
}
