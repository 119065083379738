.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  overflow: visible !important;
}

.ng-select .ng-select-container {
  color: var(--text-typography) !important;
  background-color: var(--bs-body-bg) !important;
}

.ng-select .ng-select-opened .ng-select-bottom {
  background: var(--bs-body-bg) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background: var(--bs-body-bg) !important;
  color: var(--bs-heading-color) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background-color: var(--primary) !important;
  color: var(--bs-heading-color) !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--text-typography) !important;
  margin-right: 10px !important;
}

// .ng-value-label {
//   margin-right: 10px !important;
// }
