/*=== FADE IN LEFT ===*/


// fade out
@keyframes fade-in-out {
  from {
    transform: scale(2)
  }

  to {
    transform: scale(1)
  }
}

.fade-in-out {
  -webkit-animation-name: fade-in-out;
  animation-name: fade-in-out;
}

// fade left
@keyframes fade-in-left {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fade-in-left {
  -webkit-animation-name: fade-in-left;
  animation-name: fade-in-left;
}

// fade right
@keyframes fade-in-right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fade-in-right {
  -webkit-animation-name: fade-in-right;
  animation-name: fade-in-right;
}

// fade up
@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1
  }
}

.fade-in-up {
  animation-name: fade-in-up
}

// fade bottom
@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1
  }
}

.fade-in-bottom {
  animation-name: fade-in-bottom
}


// fade timer

.animate-0-05 {
  -webkit-animation-duration: 0.05s;
  animation-duration: 0.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-1-5 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-2-5 {
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-3 {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-3-5 {
  -webkit-animation-duration: 3.5s;
  animation-duration: 3.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-4 {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-4-5 {
  -webkit-animation-duration: 4.5s;
  animation-duration: 4.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-5 {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-5-5 {
  -webkit-animation-duration: 5.5s;
  animation-duration: 5.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-6 {
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-7 {
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-8 {
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}