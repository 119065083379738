:root {
  /* fonts */

  /* font sizes */
  --paragraph-p2-regular1-size: 14px;
  --paragraph-p1-regular-size: 16px;
  --paragraph-p3-regular-size: 13px;
  --heading-h2-medium1-size: 30px;

  /* Colors */
  --primary-50: #fdfaf4;
  --white: #fff;
  --gray-warm-gray-500-base: #78716c;
  --gray-warm-600: #57534e;
  --primary-600: #d6bb87;
  --primary-500-base: #ebcd94;
  --gray-warm-gray-900: #1c1917;
  --gray-warm-700: #44403c;
  --neutrals-6: #e6e8ec;
  --stroke-gray-stroke: #e7e5e4;

  /* Spacing */
  --number-8: 8px;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-xs: 12px;
  --br-81xl: 100px;
  --br-7xs: 6px;
  --border-radus-8: 8px;
  --br-9xs: 4px;

  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-xs1: 0px 3px 4px -5px rgba(24, 24, 28, 0.03),
    0px 1px 2px rgba(24, 24, 28, 0.04);
}

.ph2 {
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 67px 30px rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  zoom: 0.8;

  .header-map-section {
    display: flex;
    // width: 572px;
    padding: 20px 20px 10px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 8px 0 0;
    border-bottom: 1px solid #e7e5e4;
    background: #fff;

    .header-map-section-left {
      position: relative;
      display: flex;
      padding: 0px;
      align-items: flex-start;
      gap: 12px;

      .header-map-section-icon {
        display: flex;
        padding: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #e7e5e4;
        background: #fafaf9;
        width: 40px;
        height: 40px;
      }

      .non-active {
        border: 1px solid #f14437;
        background: #fafaf9;
        color: #ab3027;
      }

      .active {
        border: 1px solid var(--secondary-500-base, #039154);
        color: #039154;
        background: var(--secondary-50, #e6f4ee);
      }
    }

    .header-map-section-right-stop {
      display: flex;
      padding: 4px 12px 4px 10px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
    }

    .header-map-section-right-start {
      display: flex;
      padding: 4px 12px 4px 10px;
      align-items: center;
      gap: 4px;
      border-radius: var(--number-8-px, 8px);
      border: 1px solid var(--secondary-500-base, #039154);
      background: var(--secondary-50, #e6f4ee);
    }
  }

  .header-sidbar {
    display: flex;
    align-items: flex-start;
    gap: var(--number-0-px, 0px);
    align-self: stretch;

    .text-header {
      color: #1c1917;
      padding: 11px 24px;
      /* Heading/h3/Medium */
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      /* 133.333% */
      letter-spacing: -0.24px;
    }

    .text-no-length {
      color: #a8a29e;
      text-align: center;
      margin-top: 40px;
      /* Paragraph/p1/Regular */

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
      letter-spacing: -0.16px;
    }
  }

  .table-booked {
    width: 100%;
    height: 70vh;
    overflow: auto;

    padding: 0 25px;

    .mat-header-cell {
      border-top: 1px solid var(--stroke-gray-stroke, #e7e5e4);
      border-right: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
      border-bottom: var(--background-map, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
      border-left: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
      background: var(--gray-true-50, #fafafa);
    }

    td,
    th {
      text-align: center !important;
    }
  }

  .action-popups-section {
    display: flex;
    padding: 16px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 0 0 8px 0;
    // border-top: 1px solid #E7E5E4;
    background: #fff;

    .section-button {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }

    .action-popup-section-booking {
      width: 160px;
      display: flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      border-radius: 100px;
      font-size: 16px;
      text-align: center;
      font-weight: bold;
      line-height: 24px;
      letter-spacing: -0.07px;
      cursor: pointer;
      background: var(--primary-500-base, #ebcd94);
      color: var(--gray-warm-gray-900, #1c1917) !important;
    }
  }

  .camps-booked {
    border-radius: 8px;

    .camp-container {
      margin-top: 10px;
      border: 1px solid var(--Stroke-Primary-Stroke, #d6bb87);
      border-radius: 8px;

      .header-booked {
        background: var(--Background-Gray, #f5f5f4);
      }

      .title-booked {
        display: flex;
        padding: var(--number-0-px, 0px);
        align-items: center;
        gap: var(--number-8-px, 8px);

        .text {
          color: var(--text-body-text-2, #78716c);

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 142.857% */
          letter-spacing: -0.14px;
        }

        .plot {
          color: var(--text-body-text, #44403c);
          font-size: 16px;
          font-style: normal;
          font-weight: bold;
          line-height: 24px;
          letter-spacing: -0.16px;
        }
      }

      .camps-list {
        display: flex;
        height: 40px;
        // padding: var(--Number-0-px, 0px) var(--Number-16-px, 16px);
        // justify-content: flex-end;
        align-items: center;
        gap: var(--Number-0-px, 35px);
        align-self: stretch;
        border-radius: var(--Number-0-px, 0px);
        border-top: var(--Number-0-px, 0px) solid var(--Stroke-Gray-Stroke, #e7e5e4);
        border-right: var(--Number-0-px, 0px) solid var(--Stroke-Gray-Stroke, #e7e5e4);
        border-bottom: var(--Background-MAP, 1px) solid var(--Stroke-Gray-Stroke, #e7e5e4);
        border-left: var(--Number-0-px, 0px) solid var(--Stroke-Gray-Stroke, #e7e5e4);
        opacity: var(--Background-MAP, 1);
        background: var(--Background-Background, #fff);

        .details {
          width: 50%;
          display: flex;
          padding: var(--Number-0-px, 0px);
          align-items: center;
          gap: 6px;
          justify-content: center;

          .title {
            color: var(--Text-Body-Text-2, #78716c);
            text-align: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
            letter-spacing: -0.16px;
          }

          .value {
            opacity: var(--Background-MAP, 1);
            color: var(--Text-Body-Text, #44403c);
            font-size: 16px;
            font-style: normal;
            font-weight: bold;
            line-height: 24px;
            letter-spacing: -0.16px;
          }
        }
      }
    }
  }
}

.color-meaning-ph2 {
  position: relative;
  top: 10px;
  z-index: 800;
  left: 0;
  transition: all 0.3s ease-out;
  display: flex;
  gap: 8px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  .color-meaning-right {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .map-filters {
      display: flex;
      width: 255px;
      padding: var(--number-0-px, 0px);
      justify-content: flex-end;
      align-items: flex-start;
      gap: var(--number-0-px, 0px);
      border-radius: var(--number-8-px, 8px);
      opacity: var(--background-map, 1);
      cursor: pointer;
      /* Map */
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

      .close-filter {
        display: flex;
        padding: var(--number-8-px, 8px) var(--number-16-px, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--number-8-px, 8px);
        flex: 1 0 0;
        border-radius: var(--number-8-px, 8px);
        opacity: var(--background-map, 1);
        background: var(--default-white, #fff);
        border: var(--Number-0-px, 1px) solid var(--Gray-Warm-300, #d6d3d1);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
      }

      .close-filter:hover {
        box-shadow: 0px 0px 0px 4px #f9f0de,
          0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-top: 1px solid var(--stroke-primary-stroke, #d6bb87);
        border-right: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
        border-bottom: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
        border-left: var(--background-map, 1px) solid var(--stroke-primary-stroke, #d6bb87);
      }

      .forms-filter {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .col-forms-filter {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 0;
          text-align: right;

          label {
            color: var(--text-body-text-2, #78716c);
            text-align: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: -0.16px;
          }

          .slider-ranger {
            margin-top: 40px;
          }
        }
      }

      .dropdown-menu {
        border-radius: var(--number-8-px, 8px);
        border-top: 1px solid var(--stroke-gray-stroke, #e7e5e4);
        border-right: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
        border-bottom: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
        border-left: var(--number-0-px, 1px) solid var(--stroke-gray-stroke, #e7e5e4);
        opacity: var(--background-map, 1);
        background: var(--White, #fff);

        /* Map */
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
      }
    }
  }

  .color-meaning-left {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .container-color-meaning {
      display: flex;
      flex-direction: row;
      height: var(--number-32-px, 32px);
      padding: 6px 10px;
      justify-content: flex-end;
      align-items: center;
      gap: var(--number-8-px, 8px);
      border-radius: 6px;
      // border: 1px solid var(--gray-true-300, #D4D4D4);
      background: var(--background-background, #fff);

      /* Map */
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);

      .dot {
        display: flex;
        padding: var(--number-0-px, 0px);
        align-items: flex-start;
        gap: 10px;
      }

      .text-color-meaning {
        color: var(--text-body-text, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: -0.14px;
      }
    }
  }

  .non-selected {
    border: 3px solid var(--gray-true-300, #d4d4d4);
  }

  .selected {
    border: 3px solid var(--gray-true-300, #ebcd94);
  }
}


.dropdown-map-filterph2 {
  display: flex;
  padding: var(--number-0-px, 0px);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--number-0-px, 0px);
  width: 370px;

  .dropdown-map-filter-header {
    display: flex;
    padding: 10px var(--number-16-px, 16px);
    justify-content: space-between;
    align-items: center;
    gap: var(--number-8-px, 8px);
    align-self: stretch;
    border-radius: var(--number-8-px, 8px) var(--number-8-px, 8px) var(--number-0-px, 0px) var(--number-0-px, 0px);
    border-right: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
    border-bottom: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
    border-left: var(--number-0-px, 0px) solid var(--stroke-gray-stroke, #e7e5e4);
    opacity: var(--background-map, 1);
    background: var(--default-white, #fff);

    .text {
      color: var(--text-body-text, #44403c);
      text-align: right;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      /* 144.444% */
      letter-spacing: -0.18px;
    }
  }

  .line {
    height: var(--background-map, 1px);
    align-self: stretch;
    border-radius: var(--number-0-px, 0px);
    opacity: var(--background-map, 1);
    background: var(--stroke-gray-stroke, #e7e5e4);
  }

  .labels-fomrs {
    display: flex;
    padding: var(--number-0-px, 0px);
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 5px;

    .text {
      color: var(--text-body-text-2, #78716c);
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
      letter-spacing: -0.16px;
    }

    .reset {
      color: var(--text-body-text-4, #d6d3d1);
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
      letter-spacing: -0.16px;
      cursor: pointer;
    }
  }
}



.mat-elevation-z8-ph2 {
  box-shadow: none !important;

  th {
    padding: 0 !important;
  }

  td {
    padding: 0 !important;
  }
}

@media (max-width: 860px) {
  .ph2 .action-popups-section {
    display: flex;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 860px) {
  .color-meaning-ph2 {
    width: 55%;
  }
}
