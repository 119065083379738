.all-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggled {
  transform: rotate(180deg);
}


.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

@media (min-width: 992px) {
  .overflow-x-lg-visible {
    overflow: visible;
  }

  .overflow-y-lg-visible {
    overflow: visible;
  }
}

@media (min-width: 1200px) {
  .overflow-x-xl-visible {
    overflow: visible;
  }

  .overflow-y-xl-visible {
    overflow: visible;
  }
}

@media (min-width: 992px) {
  .position-lg-initial {
    position: initial !important;
  }
}

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-absolute {
    position: absolute !important;
  }
}
