.icon-table {
  display: flex;
  padding: var(--number-0-px, 0px);
  align-items: flex-start;
  gap: 6px;
  border-radius: var(--number-0-px, 0px);
  opacity: var(--background-map, 1);
  cursor: pointer;

  .button-table {
    display: flex;
    width: var(--number-24-px, 24px);
    height: var(--number-24-px, 24px);
    // padding: var(--number-4-px, 4px) var(--number-12-px, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border-top: 1px solid var(--stroke-gray-stroke);
    border-right: var(--number-0-px, 1px) solid var(--stroke-gray-stroke);
    border-bottom: var(--number-0-px, 1px) solid var(--stroke-gray-stroke);
    border-left: var(--number-0-px, 1px) solid var(--stroke-gray-stroke);
    opacity: var(--background-map, 1);
    background: var(--bs-body-secondry) !important;
    color: var(--text-typography) !important;

    img {
      color: var(--text-typography) !important;
      fill: var(--text-typography) !important;
      filter: var(--icon-filtter)

    }
  }
}

.widget-datatable {
  .mat-header-cell {
    //  border-top: 1px solid var( --text-typography, #E7E5E4) !important;
    border-right: 0px solid var(--stroke-gray-stroke) !important;
    border-bottom: 1px solid var(--stroke-gray-stroke) !important;
    border-left: 0px solid var(--stroke-gray-stroke) !important;
    background: var(--bs-body-secondry) !important;
    color: var(--text-typography) !important;
  }
}

.search-box-main-data {
  position: relative;
  width: 240px;

  .form-control {
    background: var(--bs-body-secondry) !important;
    color: var(--text-typography) !important;
    padding-inline-start: 40px;
    width: 100%;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    font-size: 20px;
    color: #a8a29e;
  }
}
